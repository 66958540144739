import { Breadcrumb, TinyMce, Input, EditModelForm, EditFormSection, Checkbox } from 'components'
import { getSubmissionStatus, udpateSubmissionStatus } from 'api/submissions'
import { notification } from 'utils/notifications'

export const EditSubmissionStatus = ({ attrs: { id } }) => {
	let ready
	let submissionStatus

	if (id) {
		getSubmissionStatus(id).then((data) => {
			submissionStatus = data

			ready = true
		})
	}

	const update = (e) => {
		e.preventDefault()
		if (id) {
			udpateSubmissionStatus(submissionStatus.id, submissionStatus)
				.then(() => {
					notification.success({ title: 'Status updated' })
					m.route.set('/submission-statuses')
				})
				.catch((err) => {
					notification.error({ title: err.response })
				})
		}
	}

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Submission Statuses',
							href: '/submission-statuses',
						},
						{
							title: ready ? `Edit ${submissionStatus.id}` : 'Edit',
						},
					],
				}),

				ready &&
					m(
						EditModelForm,
						{
							onsubmit: update,
						},
						[
							m(
								EditFormSection,
								{
									heading: 'Edit Submission Status',
									description: '',
								},
								[
									ready &&
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-2', [
												m(Input, {
													label: 'Name',
													value: submissionStatus.name,
													type: 'text',
													required: false,
													oninput(value) {
														submissionStatus.name = value
													},
												}),
											]),
											m('.col-span-2', [
												m(Input, {
													label: 'Short Name',
													value: submissionStatus.shortName,
													type: 'text',
													required: true,
													oninput(value) {
														submissionStatus.shortName = value
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'Votable?',
													checked: submissionStatus.isVotable,
													onchange() {
														submissionStatus.isVotable = !submissionStatus.isVotable
													},
												}),
											]),
											m('.col-span-6', [
												m(TinyMce, {
													label: 'Email Template',
													value: submissionStatus.emailTemplate,
													name: 'emailTemplate',
													height: 400,
													onchange: (content) => {
														submissionStatus.emailTemplate = content
													},
												}),
											]),
										]),
								]
							),
						]
					),
			]
		},
	}
}
