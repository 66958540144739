import { Breadcrumb, Select, Input, Checkbox, EditModelForm, EditFormSection } from 'components'
import { getAwardsYear, updateAwardsYear } from 'api/awardsyears'
import { notification } from 'utils/notifications'

export const EditAwardsYear = ({ attrs: { id } }) => {
	let ready
	let model

	if (id) {
		getAwardsYear(id).then((data) => {
			model = data
			ready = true
		})
	} else {
		model = {}
		ready = true
	}

	const createOrUpdate = (e) => {
		e.preventDefault()
		if (id) {
			updateAwardsYear(model.id, model)
				.then(() => {
					notification.success({ title: 'Awards Year updated' })
					m.route.set(`/awardsyears/${model.id}`)
				})
				.catch((err) => {
					notification.error({ title: err.response })
				})
		} else {
			createAwardsYear(model)
				.then((data) => {
					notification.success({ title: 'Awards Year created' })
					m.route.set(`/awardsyears/${data.id}`)
				})
				.catch((err) => {
					notification.error({ title: err.response })
				})
		}
	}
	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Awards Years',
							href: '/awardsyears',
						},
						id
							? {
									title: ready ? model.year : '...',
									href: `/awardsyears/${id}`,
							  }
							: null,
						{
							title: !id ? 'Create' : ready ? `Edit ${model.year}` : 'Edit',
						},
					],
				}),

				ready &&
					m(
						EditModelForm,
						{
							onsubmit: createOrUpdate,
						},
						[
							m(
								EditFormSection,
								{
									heading: id ? 'Edit Awards Year' : 'Create Awards Year',
									description: '',
									extra: null,
								},
								[
									ready &&
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-4', [
												m(Input, {
													label: 'Year',
													value: model.year,
													type: 'text',
													required: true,
													oninput(value) {
														model.year = value
													},
												}),
											]),
											m('.col-span-4', [
												m(Input, {
													label: 'Awards Date',
													value: model.awardsDate,
													type: 'date',
													required: true,
													oninput(value) {
														model.awardsDate = value
													},
												}),
											]),
											m('.col-span-3', [
												m(Checkbox, {
													label: 'Live?',
													checked: model.isLive,
													onchange() {
														model.isLive = !model.isLive
													},
												}),
											]),
											m('.col-span-4', [
												m(Checkbox, {
													label: 'Entries Visible?',
													checked: model.isEntriesVisible,
													onchange() {
														model.isEntriesVisible = !model.isEntriesVisible
													},
												}),
											]),
											m('.col-span-4', [
												m(Checkbox, {
													label: 'Subgroup Reg Visible?',
													checked: model.isSubgroupRegVisible,
													onchange() {
														model.isSubgroupRegVisible = !model.isSubgroupRegVisible
													},
												}),
											]),
											m('.col-span-4', [
												m(Checkbox, {
													label: 'Hide Subgroup Status?',
													checked: model.isHideSubgroupStatus,
													onchange() {
														model.isHideSubgroupStatus = !model.isHideSubgroupStatus
													},
												}),
											]),
											m('.col-span-6', [
												m(Input, {
													label: 'Code of Conduct Link',
													value: model.codeOfConductLink,
													type: 'url',
													oninput(value) {
														model.codeOfConductLink = value
													},
												}),
											]),
											m('.col-span-6', [
												m(Input, {
													label: 'Voter Agreement Link',
													value: model.voterAgreementLink,
													type: 'url',
													oninput(value) {
														model.voterAgreementLink = value
													},
												}),
											]),
										]),
								]
							),
						]
					),
			]
		},
	}
}
