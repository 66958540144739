import { Table, Row, Cell, CellBool, Pagination, LoadingFullScreen } from 'components'
import { getAwardsYearSubgroups, updateAwardsYearSubgroup } from 'api/awardsyears'
import { EditSubgroupDialog } from './EditSubgroupDialog'

export const SubgroupsTab = ({ attrs: { id, awardsYear } }) => {
	const cols = [
		{ label: 'Id', classes: ['w-16'] },
		{ label: 'Name' },
		{ label: 'Subteams?', align: 'center', classes: ['w-32'] },
		{ label: 'Hidden?', align: 'center', classes: ['w-32'] },
	]

	let list
	let meta

	const loadData = () => {
		list = null
		getAwardsYearSubgroups(id).then((res) => {
			list = res
			meta = {
				currentPage: 1,
				itemsPerPage: res.length,
				totalItems: res.length,
				totalPages: 1,
			}
		})
	}

	loadData()

	let selectedItem = null
	let savingItem
	const editItemOpen = flyd.stream(false)

	const onSaveSubgroup = (data) => {
		updateAwardsYearSubgroup(awardsYear.id, data.id, data).then((res) => {
			loadData()
			editItemOpen(false)
		})
	}

	return {
		view({ attrs: { id } }) {
			return m('.h-auto', [
				m(
					Table,
					{
						cols: cols,
					},
					[
						list
							? list.map((row) => {
									return m(
										Row,
										{
											clickable: true,
											onclick: (e) => {
												selectedItem = row
												editItemOpen(true)
											},
											bg: 'bg-white',
										},
										[
											m(Cell, row.id),
											m(Cell, row.name),
											m(CellBool, { val: row.isSubTeams }),
											m(CellBool, { val: row.isHidden }),
										]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
				m(
					'div',
					meta &&
						m(Pagination, {
							meta: meta,
							onrefresh: () => {
								loadData()
							},
							onpage: (newPage) => {
								page = newPage
								loadData()
							},
						})
				),
				editItemOpen() &&
					m(EditSubgroupDialog, {
						open: editItemOpen,
						onsubmit: onSaveSubgroup,
						loading: savingItem,
						subgroup: selectedItem,
						awardsYearId: awardsYear.id,
					}),
			])
		},
	}
}
