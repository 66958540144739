export const recoverPassword = (data) => {
	return m.request({
		method: 'POST',
		url: `https://${process.env.API_DOMAIN}/account/public/recoverpassword`,
		body: {
			usernameOrEmail: data.email,
			domain: process.env.APP_DOMAIN,
		},
	})
}
export const resetPassword = (body) => {
	return m.request({
		method: 'POST',
		url: `https://${process.env.API_DOMAIN}/account/public/resetpassword`,
		body,
	})
}
