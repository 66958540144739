import {
	Table,
	Row,
	Cell,
	CellBool,
	Button,
	Svg,
	LoadingFullScreen,
	Pagination,
	SubmissionCheckStatusBadge,
	SupportingMaterialsStatusBadge,
} from 'components'
import { getSubmissions, exportSubmissions } from 'api/submissions'
import { DateTime } from 'luxon'
import chevronDown from 'assets/icons/outline/chevron-double-down.svg'

export const StatusTab = ({ attrs: { statusId, tab, search, p, currentAwardsYear } }) => {
	const root = '/submissions'
	let searchString = search || ''
	let sortBy = 'date'
	let sortOrder = 'desc'
	let list
	let meta
	let page = p || 1
	let perPage = 25
	let submissionType = ''

	const loadData = () => {
		list = null
		var pageUrl = `${window.location.pathname}?tab=${tab.name}&statusId=${statusId}&p=${page}&search=${searchString}`
		window.history.pushState('', '', pageUrl)
		getSubmissions({
			statusId: statusId,
			search: searchString,
			page,
			pagesize: perPage,
			sortBy,
			sortOrder,
			awardsYearId: currentAwardsYear ? currentAwardsYear : 0,
			type: submissionType,
		}).then((res) => {
			list = res.data
			meta = res.meta
		})
	}
	const toggleSortOrder = (colName) => {
		sortOrder = sortBy === colName ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc'
		sortBy = colName
		page = 1
		loadData()
	}

	let cols = [
		{
			label: 'Id',
			sortBy: 'id',
			onclick(e) {
				toggleSortOrder('id')
			},
		},
		{
			label: 'Title',
			sortBy: 'filmTitle',
			onclick(e) {
				toggleSortOrder('filmTitle')
			},
		},
		{
			label: 'Type',
			filter: true,
			filterEmptyOption: true,
			filterOptions: [
				{ id: 'shorts', name: 'Shorts' },
				{ id: 'features', name: 'Features' },
				{ id: 'features-british', name: 'Features - Brit' },
				{
					id: 'features-international',
					name: 'Features - Intl',
				},
			],
			filterOnChange(val) {
				submissionType = val
				loadData()
			},
			classes: ['w-32'],
		},
		{ label: 'Entrant' },
		{ label: 'Email' },
		{ label: 'Date Added' },
		{ label: 'Film' },
	]

	if (tab.name !== 'In Progress' && tab.name !== 'Submitted') {
		cols.push({ label: 'Brit', align: 'center' })
		cols.push({ label: 'Ind', align: 'center' })
		cols.push({ label: 'Rel', align: 'center' })
		cols.push({ label: 'Cat', align: 'center' })
		cols.push({ label: 'Mat', align: 'center' })
	}
	if (tab.name === 'All') {
		cols.push({ label: 'Status' })
	}
	let exporting
	const onExport = () => {
		exportSubmissions({
			awardsYearId: currentAwardsYear ? currentAwardsYear : 0,
			statusId: statusId,
			search: searchString,
		}).then((res) => {
			exporting = false
		})
	}

	return {
		view() {
			return m('.h-auto', [
				m(
					Table,
					{
						cols: cols,
						oncreate({ dom }) {
							loadData()
						},
						searchRow: true,
						searchString: searchString,
						onsearchinput: (newSearch) => {
							searchString = newSearch
						},
						onsearch: () => {
							page = 1
							loadData()
						},
						sortOrder,
						sortBy,
						filterRow: true,
					},
					[
						list
							? list.map((row) => {
									return m(
										Row,
										{
											clickable: true,
											onclick: (e) => {
												if (e.ctrlKey || e.metaKey) {
													window.open(`${root}/${row.id}`, '_blank')
												} else {
													m.route.set(`${root}/${row.id}`)
												}
											},
											bg: row.createdFilmId ? 'bg-green-100' : 'bg-white',
										},
										[
											m(Cell, row.id),
											m(Cell, row.filmTitle),
											m(
												Cell,
												row.entryForm +
													(row.entryForm === 'Features' ? ` - ${row.isInternational ? 'Intl' : 'Brit'}` : '')
											),
											m(Cell, row.user.fullName),
											m(Cell, row.user.email),
											m(Cell, DateTime.fromISO(row.createdDate).toLocaleString()),
											m(CellBool, { val: row.createdFilmId ? true : false }),
											tab.name !== 'In Progress' &&
												tab.name !== 'Submitted' &&
												m(
													Cell,
													{ align: 'center' },
													row.britishnessCheckStatus
														? m(SubmissionCheckStatusBadge, {
																status: row.britishnessCheckStatus,
																size: 'xs',
														  })
														: ''
												),
											tab.name !== 'In Progress' &&
												tab.name !== 'Submitted' &&
												m(
													Cell,
													{ align: 'center' },
													row.independenceCheckStatus
														? m(SubmissionCheckStatusBadge, {
																status: row.independenceCheckStatus,
																size: 'xs',
														  })
														: ''
												),
											tab.name !== 'In Progress' &&
												tab.name !== 'Submitted' &&
												m(
													Cell,
													{ align: 'center' },
													row.releaseCheckStatus
														? m(SubmissionCheckStatusBadge, {
																status: row.releaseCheckStatus,
																size: 'xs',
														  })
														: ''
												),
											tab.name !== 'In Progress' &&
												tab.name !== 'Submitted' &&
												m(
													Cell,
													{ align: 'center' },
													row.categoriesCheckStatus
														? m(SubmissionCheckStatusBadge, {
																status: row.categoriesCheckStatus,
																size: 'xs',
														  })
														: ''
												),
											,
											tab.name !== 'In Progress' &&
												tab.name !== 'Submitted' &&
												m(
													Cell,
													{ align: 'center' },
													row.supportingMaterialsStatus
														? m(SupportingMaterialsStatusBadge, {
																status: row.supportingMaterialsStatus,
																size: 'xs',
														  })
														: ''
												),
											tab.name == 'All' && m(Cell, row.submissionStatus),
										]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
				m(
					'div',
					meta &&
						m(Pagination, {
							meta: meta,
							onrefresh: () => {
								loadData()
							},
							onpage: (newPage) => {
								page = newPage
								loadData()
							},
							buttons: [
								m(
									Button,
									{
										size: 'sm',
										classes: ['my-2', 'whitespace-nowrap', 'text-white'],
										type: 'button',
										loading: exporting,
										onclick: () => {
											exporting = true
											onExport()
										},
									},
									[
										m(
											Svg,
											{
												classes: ['w-4', 'mx-auto'],
											},
											[chevronDown]
										),
										'Export Submissions',
									]
								),
							],
						})
				),
			])
		},
	}
}
