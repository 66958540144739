import { Breadcrumb, Table, Cell, CellBool, Row, Button, Svg, LoadingFullScreen } from 'components'
import { notification } from 'utils/notifications'
import { getEntryFees, deleteEntryFee } from 'api/entryFees'
import { DateTime } from 'luxon'
import addIcon from 'assets/icons/outline/plus.svg'
import cancelIcon from 'assets/icons/outline/x-circle.svg'

export const EntryFees = () => {
	const root = '/entry-fees'
	let entryFees

	const deleteFee = (templateId) => {
		deleteEntryFee(templateId)
			.then(() => {
				notification.success({ title: 'Entry Fee deleted' })
				getEntryFees().then((data) => {
					entryFees = data.data
				})
			})
			.catch((err) => {
				notification.error({ title: err.response })
			})
	}

	getEntryFees().then((data) => {
		entryFees = data.data
	})

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Entry Fees',
						},
					],
					buttons: [
						m(
							Button,
							{
								size: 'sm',
								classes: ['my-2', 'mx-2', 'whitespace-nowrap', 'text-white'],
								type: 'button',
								onclick: () => {
									m.route.set(`${root}/create`)
								},
							},
							[
								m(
									Svg,
									{
										classes: ['w-4', 'mx-auto'],
									},
									[addIcon]
								),
								'Add Entry Fee',
							]
						),
					],
				}),
				m('.p-4', { style: { height: 'calc(100% - 3rem)' } }, [
					m(
						'.h-full.shadow.overflow-hidden.border-b.border-gray-200.sm:rounded-lg',
						entryFees &&
							m(
								Table,
								{
									cols: [
										{
											label: 'Id',
											classes: ['w-24'],
										},
										{
											label: 'Entry Form',
										},
										{
											label: 'SKU',
										},
										{
											label: 'Amount',
										},
										{
											label: 'Date From',
										},
										{
											label: 'Budget From',
										},
										{
											label: 'Eventive Brit?',
										},
										{
											label: 'Eventive Intl?',
										},
									],
								},
								[
									entryFees
										? entryFees.map((row) => {
												return m(
													Row,
													{
														clickable: true,
														onclick: (e) => {
															if (e.ctrlKey || e.metaKey) {
																window.open(`${root}/${row.id}`, '_blank')
															} else {
																m.route.set(`${root}/${row.id}`)
															}
														},
														bg: 'bg-white',
													},
													[
														m(Cell, row.id),
														m(Cell, row.entryForm),
														m(Cell, row.sku),
														m(Cell, row.amount),
														m(
															Cell,
															{
																align: 'left',
															},
															row.dateFrom ? DateTime.fromISO(row.dateFrom).toLocaleString() : ''
														),
														m(Cell, row.budgetFrom),
														m(CellBool, { val: row.isEventiveBritish }),
														m(CellBool, { val: row.isEventiveInternational }),
													]
												)
										  })
										: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
								]
							)
					),
				]),
			]
		},
	}
}
