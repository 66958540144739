import {
	Breadcrumb,
	Select,
	TextArea,
	TinyMce,
	Input,
	Checkbox,
	EditModelForm,
	EditFormSection,
	LoadingFullScreen,
} from 'components'
import { File } from 'components/File'
import { getFilm, updateFilm, createFilm, uploadFilmAttachment } from 'api/films'
import { notification } from 'utils/notifications'

export const EditFilm = ({ attrs: { id } }) => {
	let ready
	let model

	if (id) {
		getFilm(id).then((data) => {
			model = data
			ready = true
		})
	} else {
		model = {}
		ready = true
	}

	const createOrUpdate = (e) => {
		e.preventDefault()
		if (id) {
			updateFilm(model.id, model)
				.then(() => {
					notification.success({ title: 'Film updated' })
					m.route.set(`/films/${model.id}`)
				})
				.catch((err) => {
					if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
						Sentry.captureException(err)
					}
					notification.error({ title: err.response })
				})
		} else {
			createFilm(model)
				.then((data) => {
					notification.success({ title: 'Film created' })
					m.route.set(`/films/${data.id}`)
				})
				.catch((err) => {
					if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
						Sentry.captureException(err)
					}
					notification.error({ title: err.response })
				})
		}
	}

	let uploadingFilmAttachment
	const onUploadFilmAttachment = (file) => {
		if (file) {
			uploadingFilmAttachment = true
			uploadFilmAttachment(id, file)
				.then((a) => {
					uploadingFilmAttachment = false
					model.filmAttachments.push({
						name: a.substring(a.lastIndexOf('/') + 1),
						type: '',
						path: a,
						id: 0,
					})
				})
				.catch((err) => {
					console.log(err)
					notification.error({ title: 'Error - unable to upload' })
					uploadingFilmAttachment = false
				})
		}
	}

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Films',
							href: '/films',
						},
						id
							? {
									title: model ? model.title : '',
									href: `/films/${id}`,
							  }
							: null,
						{
							title: !id ? 'Create' : ready ? `Edit ${model.title}` : 'Edit',
						},
					],
				}),

				ready &&
					m(
						EditModelForm,
						{
							onsubmit: createOrUpdate,
						},
						[
							m(
								EditFormSection,
								{
									heading: id ? 'Edit Film' : 'Create Film',
									description: '',
									extra: null,
								},
								[
									ready &&
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-6', [
												m(Input, {
													label: 'Title',
													value: model.title,
													type: 'text',
													oninput(value) {
														model.title = value
													},
												}),
											]),
											m('.col-span-2', [
												m(Input, {
													label: 'Year',
													value: model.year,
													type: 'text',
													required: true,
													oninput(value) {
														model.year = value
													},
												}),
											]),
											m('.col-span-2', [
												m(Input, {
													label: 'Budget',
													value: model.budget,
													type: 'text',
													required: false,
													oninput(value) {
														model.budget = value
													},
												}),
											]),
											m('.col-span-2', [
												m(Input, {
													label: 'Running Time',
													value: model.runningTime,
													type: 'number',
													required: false,
													suffix: 'mins',
													oninput(value) {
														model.runningTime = value
													},
												}),
											]),
											m('.col-span-6', [
												m(TinyMce, {
													value: model.description,
													label: 'Description',
													name: 'description',
													height: 200,
													onchange: (content) => {
														model.description = content
													},
												}),
											]),
											m('.col-span-6', [
												m(TextArea, {
													value: model.notes,
													label: 'Notes',
													name: 'notes',
													oninput: (content) => {
														model.notes = content
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													label: 'Website',
													value: model.website,
													oninput(value) {
														model.website = value
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													label: 'Trailer Link',
													value: model.trailer,
													type: 'text',
													oninput(value) {
														model.trailer = value
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													label: 'Storify',
													value: model.storify,
													oninput(value) {
														model.storify = value
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													label: 'Find Any Film Title',
													value: model.findAnyFilmTitle,
													oninput(value) {
														model.findAnyFilmTitle = value
													},
												}),
											]),
										]),
								]
							),
							m(
								EditFormSection,
								{
									heading: 'Screening Info',
									description: '',
									extra: null,
								},
								[
									ready &&
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-6', [
												m(TextArea, {
													value: model.screeningInfo,
													label: 'Screening Info',
													name: 'screeningInfo',
													oninput: (content) => {
														model.screeningInfo = content
													},
												}),
											]),
											m('.col-span-4', [
												m(Input, {
													value: model.screeningLink,
													label: 'Screening Link',
													name: 'screeningLink',
													oninput(value) {
														model.screeningLink = value
													},
												}),
											]),
											m('.col-span-2', [
												m(Input, {
													value: model.screeningLinkPassword,
													label: 'Screening Link Password',
													name: 'screeningLinkPassword',
													oninput(value) {
														model.screeningLinkPassword = value
													},
												}),
											]),
											m('.col-span-6', [
												m(Checkbox, {
													label: 'DVD Screener?',
													checked: model.isDVDScreenerAvailable,
													onchange() {
														model.isDVDScreenerAvailable = !model.isDVDScreenerAvailable
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													value: model.screenerDates,
													label: 'Screening Dates',
													name: 'screenerDates',
													oninput(value) {
														model.screenerDates = value
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													value: model.screenerRequestEmail,
													label: 'Screener Request Email',
													name: 'screenerRequestEmail',
													oninput(value) {
														model.screenerRequestEmail = value
													},
												}),
											]),
											m('.col-span-6', [
												m(Input, {
													value: model.screenerRestrictions,
													label: 'Screener Restrictions',
													name: 'screenerRestrictions',
													oninput(value) {
														model.screenerRestrictions = value
													},
												}),
											]),
										]),
								]
							),
							m(
								EditFormSection,
								{
									heading: 'Contacts',
									description: '',
									extra: null,
								},
								[
									ready &&
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-3', [
												m(Input, {
													value: model.primaryContact,
													label: 'Primary Contact',
													name: 'primaryContact',
													oninput(value) {
														model.primaryContact = value
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													value: model.secondaryContact,
													label: 'Secondary Contact',
													name: 'secondaryContact',
													oninput(value) {
														model.secondaryContact = value
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													value: model.primaryContactRole,
													label: 'Primary Contact Role',
													name: 'primaryContactRole',
													oninput(value) {
														model.primaryContactRole = value
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													value: model.secondaryContactRole,
													label: 'Secondary Contact Role',
													name: 'secondaryContactRole',
													oninput(value) {
														model.secondaryContactRole = value
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													value: model.primaryContactEmail,
													label: 'Primary Contact Email',
													name: 'primaryContactEmail',
													type: 'email',
													oninput(value) {
														model.primaryContactEmail = value
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													value: model.secondaryContactEmail,
													label: 'Secondary Contact Email',
													name: 'secondaryContactEmail',
													type: 'email',
													oninput(value) {
														model.secondaryContactEmail = value
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													value: model.primaryContactPhone,
													label: 'Primary Contact Phone',
													name: 'primaryContactPhone',
													oninput(value) {
														model.primaryContactPhone = value
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													value: model.secondaryContactPhone,
													label: 'Secondary Contact Phone',
													name: 'secondaryContactPhone',
													oninput(value) {
														model.secondaryContactPhone = value
													},
												}),
											]),
										]),
								]
							),
							m(
								EditFormSection,
								{
									heading: 'Film Attachments',
									description: '',
									extra: null,
								},
								[
									ready &&
										m('.grid.grid-cols-3.gap-6', [
											model.filmAttachments.map((ea, eax) => {
												return [
													m(Input, {
														label: 'Name',
														value: ea.name,
														oninput(value) {
															model.filmAttachments[eax].name = value
														},
													}),
													m(Input, {
														label: 'Type',
														value: ea.type,
														oninput(value) {
															model.filmAttachments[eax].type = value
														},
													}),
													m(Input, {
														label: 'Path',
														value: ea.path,
														type: 'readonly',
													}),
												]
											}),
											m('div.col-span-3', [
												m(
													'.bg-gray-100.p-1.text-right',
													uploadingFilmAttachment
														? m(LoadingFullScreen)
														: m(
																File,
																{
																	type: 'file',
																	name: 'file',
																	classes: ['my-2'],
																	label: 'Upload',
																	oninput: (val) => {
																		onUploadFilmAttachment(val)
																	},
																},
																'Add Attachment'
														  )
												),
											]),
										]),
								]
							),
							m(
								EditFormSection,
								{
									heading: 'Sign off',
									description: '',
									extra: null,
								},
								[
									ready &&
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-3', [
												m(Checkbox, {
													label: 'Signed Off?',
													checked: model.isSignedOff,
													onchange() {
														model.isSignedOff = !model.isSignedOff
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													value: model.signedOffDate,
													label: 'Signed Off Date',
													name: 'signedOffDate',
													type: 'readonly',
													oninput(value) {
														model.signedOffDate = value
													},
												}),
											]),
											m('.col-span-3', [
												m(Checkbox, {
													label: 'Longlisted Signed Off?',
													checked: model.isLonglistedSignedOff,
													onchange() {
														model.isLonglistedSignedOff = !model.isLonglistedSignedOff
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													value: model.longlistedSignedOffDate,
													label: 'Longlisted Signed Off Date',
													name: 'longlistedSignedOffDate',
													type: 'readonly',
													oninput(value) {
														model.longlistedSignedOffDate = value
													},
												}),
											]),
										]),
								]
							),
						]
					),
			]
		},
	}
}
