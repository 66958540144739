import { Table, Row, Cell, CellBool, LoadingFullScreen, Pagination } from 'components'

export const AttachmentsTab = ({ attrs: { id } }) => {
	const cols = [{ label: 'Id' }, { label: 'Name' }, { label: 'Type' }, { label: 'Path' }]

	return {
		view({ attrs: { id, attachments } }) {
			return m('.h-auto', [
				m(
					Table,
					{
						cols: cols,
					},
					[
						attachments
							? attachments.map((row) => {
									return m(
										Row,
										{
											clickable: true,
											onclick: (e) => {
												// if (e.ctrlKey || e.metaKey) {
												// 	window.open(`${root}/${row.id}`, '_blank')
												// } else {
												// 	m.route.set(`${root}/${row.id}`)
												// }
											},
											bg: 'bg-white',
										},
										[m(Cell, row.id), m(Cell, row.name), m(Cell, row.type), m(Cell, row.path)]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
			])
		},
	}
}
