import { Svg } from 'components'

export const TableButtons = () => {
	return {
		view({ attrs: { actions } }) {
			return m(
				'.flex.flex-wrap.justify-center',
				actions.map((action) => {
					if (action) {
						return m(
							'button.text-gray-700.flex.items-center.p-1.text-xs.hover:bg-gray-100',
							{
								class: action.color || 'text-gray-700',
								type: 'button',
								onclick(e) {
									e.preventDefault()
									action.action()
								},
								tabindex: '-1',
							},
							[
								action.icon &&
									m(Svg, { classes: ['w-4', 'h-4', 'mr-0', 'text-gray-400', 'flex-shrink-0'] }, action.icon),
								m('span.text-gray-600.leading-none.sr-only', action.label),
							]
						)
					}
				})
			)
		},
	}
}
