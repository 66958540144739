export const Tabs = () => {
	return {
		view({ attrs: { tabs, onTabChange } }) {
			return m('div', [
				m('div.sm:hidden', [
					m("label.sr-only[for='tabs']", 'Select a tab'),
					m(
						"select.block.w-full.pl-3.pr-10.py-2.text-base.border-gray-300.focus:outline-none.focus:ring-indigo-500.focus:border-indigo-500.sm:text-sm.rounded-md[id='tabs'][name='tabs']",
						{
							onchange: (e) => {
								onTabChange(parseInt(e.currentTarget.value))
							},
						},
						[
							tabs.map((t) => {
								return m(
									'option',
									{
										selected: t.active,
										value: t.id,
										disabled: t.disabled,
									},
									t.name
								)
							}),
						]
					),
				]),
				m(
					'div.hidden.sm:block',
					m(
						'div.border-b.border-gray-400',
						m("nav.-mb-px.flex.flex-wrap.space-x-2.lg:space-x-4.xl:space-x-6[aria-label='Tabs']", [
							tabs.map((t) => {
								return m(
									'button.whitespace-nowrap.py-4.px-1.border-b-2.font-medium.text-sm',
									{
										onclick: () => {
											onTabChange(t.id)
										},
										class: t.disabled
											? 'border-transparent pointer-events-none text-gray-300'
											: t.active
											? 'border-indigo-500 text-indigo-600'
											: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
									},
									[
										m('span', t.name),
										t.badge
											? m(
													'span.ml-1.hidden.rounded-full.bg-gray-200.text-xs.font-medium.text-gray-900.md:inline-block',
													{
														class: 'px-3/2',
													},
													t.badge
											  )
											: null,
									]
								)
							}),
						])
					)
				),
			])
		},
	}
}
