import { Breadcrumb, Table, Row, Cell, CellBool, Pagination, Button, Svg, LoadingFullScreen } from 'components'
import { notification } from 'utils/notifications'
import { DateTime } from 'luxon'
import { getPersons } from 'api/persons'
import addIcon from 'assets/icons/outline/plus.svg'
import cancelIcon from 'assets/icons/outline/x-circle.svg'

export const People = ({ attrs: { search, p } }) => {
	const root = '/people'

	let searchString = search || ''
	let sortBy = 'name'
	let sortOrder = 'asc'
	let list
	let meta
	let page = p || 1
	let perPage

	const loadData = () => {
		list = null
		var pageUrl = `${window.location.pathname}?p=${page}&search=${searchString}`
		window.history.pushState('', '', pageUrl)
		getPersons({
			search: searchString,
			page,
			pagesize: perPage,
			sortBy,
			sortOrder,
		}).then((res) => {
			list = res.data
			meta = res.meta
		})
	}

	const toggleSortOrder = (colName) => {
		sortOrder = sortBy === colName ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc'
		sortBy = colName
		page = 1
		loadData()
	}

	const cols = [
		{
			label: 'Id',
			sortBy: 'id',
			onclick(e) {
				toggleSortOrder('id')
			},
		},
		{ label: 'Name' },
		{ label: 'Pronouns' },
		{ label: 'Films' },
	]

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'People',
						},
					],
					buttons: [
						m(
							Button,
							{
								size: 'sm',
								classes: ['my-2', 'mx-2', 'whitespace-nowrap', 'text-white'],
								type: 'button',
								onclick: () => {
									m.route.set(root + '/create')
								},
							},
							[
								m(
									Svg,
									{
										classes: ['w-4', 'mx-auto'],
									},
									[addIcon]
								),
								'Add Person',
							]
						),
					],
				}),
				m(
					Table,
					{
						cols: cols,
						oncreate({ dom }) {
							perPage = Math.floor((dom.clientHeight - 127) / 33)
							loadData()
						},
						searchRow: true,
						searchString: searchString,
						onsearchinput: (newSearch) => {
							searchString = newSearch
						},
						onsearch: () => {
							page = 1
							loadData()
						},
						sortOrder,
						sortBy,
					},
					[
						list
							? list.map((row) => {
									return m(
										Row,
										{
											clickable: true,
											onclick: (e) => {
												if (e.ctrlKey || e.metaKey) {
													window.open(`${root}/${row.id}`, '_blank')
												} else {
													const returnUrl = encodeURIComponent(`${root}?p=${page}&search=${searchString}`)
													m.route.set(`${root}/${row.id}?returnUrl=${returnUrl}`)
												}
											},
											bg: row.isLive ? 'bg-green-50' : 'bg-white',
										},
										[m(Cell, row.id), m(Cell, row.name), m(Cell, row.pronouns), m(Cell, row.films)]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
				m(
					'div',
					meta &&
						m(Pagination, {
							meta: meta,
							onrefresh: () => {
								loadData()
							},
							onpage: (newPage) => {
								page = newPage
								loadData()
							},
						})
				),
			]
		},
	}
}
