import { request } from './request'

export const getForms = (params) => {
	return request({
		url: 'forms',
		params: {
			pagesize: 25,
			...params,
		},
		method: 'GET',
	})
}

export const getForm = (id) => {
	return request({
		url: `forms/${id}`,
		method: 'GET',
	})
}

export const createForm = (model) => {
	return request({
		url: 'forms',
		method: 'POST',
		body: model,
	})
}

export const updateForm = (id, model) => {
	return request({
		url: `forms/${id}`,
		method: 'PUT',
		body: model,
	})
}

export const updateFormQuestions = (id, model) => {
	return request({
		url: `forms/${id}/questions`,
		method: 'PUT',
		body: model,
	})
}

export const updateFormQuestion = (id, model) => {
	return request({
		url: `forms/${id}/questions/${model.id}`,
		method: 'PUT',
		body: model,
	})
}

export const deleteFormQuestion = (id, model) => {
	return request({
		url: `forms/${id}/questions/${model.id}`,
		method: 'DELETE',
	})
}

export const getQuestionTypes = () => {
	return request({
		url: `forms/question-types`,
		method: 'GET',
	})
}
export const getFormTypes = () => {
	return request({
		url: `forms/form-types`,
		method: 'GET',
	})
}

export const duplicateForm = (id) => {
	return request({
		url: `forms/${id}/duplicate`,
		method: 'POST',
	})
}

export const getFormAnswerSets = (id, params) => {
	return request({
		url: `forms/${id}/answer-sets`,
		params: {
			pagesize: 25,
			...params,
		},
		method: 'GET',
	})
}

export const exportAnswers = (id) => {
	return request({
		url: `forms/${id}/export-answers`,
		method: 'GET',
		responseType: 'blob',
		extract(val) {
			return val.response
		},
	}).then((res) => {
		const date = new Date()
		var anchor = document.createElement('a')
		anchor.download = `bifa_answers_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}.xlsx`
		anchor.href = window.URL.createObjectURL(res)
		anchor.click()
	})
}
