import { PageHeading, Breadcrumb, Tabs } from 'components'
import { DateTime } from 'luxon'
import { getForm, getQuestionTypes, duplicateForm } from 'api/forms'
import { InfoTab } from './InfoTab'
import { QuestionsTab } from './QuestionsTab'
import { AnswersTab } from './AnswersTab'
import editIcon from 'assets/icons/solid/pencil-alt.svg'
import urlIcon from 'assets/icons/outline/link.svg'
import copyIcon from 'assets/icons/outline/document-duplicate.svg'

export const ViewForm = ({ attrs: { id, tab } }) => {
	let model

	const metaItems = []

	getForm(id).then((data) => {
		model = data
		metaItems.push({
			icon: urlIcon,
			label: data.slug,
			href:
				data.formTypeId === 2
					? 'https://members.bifa.film/entries/forms/' + data.slug
					: 'https://members.bifa.film/forms/' + data.slug,
		})
		if (data.formTypeName) {
			metaItems.push({
				icon: editIcon,
				label: data.formTypeName,
			})
		}
	})

	let questionTypes
	getQuestionTypes().then((data) => {
		questionTypes = data
	})
	if (!tab) {
		tab = 'Questions'
	}
	const tabs = ['Questions', 'Answers', 'Description & Email'].map((t, ix) => {
		return {
			id: ix + 1,
			name: t,
			active: tab && tab === t,
		}
	})

	return {
		view({ attrs: { id, tab } }) {
			return m('div', [
				model &&
					m(Breadcrumb, {
						links: [
							{
								title: 'Forms',
								href: '/forms',
							},
							{
								title: model.name,
							},
						],
					}),
				model &&
					m('.flex.flex-wrap.relative.h-screen.pb-24.overflow-x-hidden.content-start', [
						m('.p-4.w-full', [
							m('div.shadow.sm:rounded-md.divide-y.divide-gray-200', [
								m('div.px-4.py-5.bg-white.space-y-6.sm:p-6', [
									m(PageHeading, {
										heading: [
											model.name,
											m(
												'span.inline-flex.items-center.px-5/2.py-1/2.rounded-full.text-xs.font-medium',
												{
													class: model.isLive ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800',
												},
												model.isLive ? 'Live' : 'Not Live'
											),
										],
										subtitle: model.shortname,
										metaItems,
										actions: [
											{
												icon: editIcon,
												label: 'Edit Form Details',
												action: () => {
													m.route.set(`/forms/${id}/edit`)
												},
											},
											{
												icon: copyIcon,
												label: 'Duplicate Form',
												action: () => {
													duplicateForm(model.id).then(() => {
														m.route.set('/forms')
													})
												},
											},
										],
									}),
								]),
								m('div.bg-gray-50.px-2.py-1', [
									m(Tabs, {
										tabs,
										onTabChange: (tabId) => {
											tabs.forEach((t) => {
												t.active = false
												if (t.id === tabId) {
													t.active = true
													tab = t
												}
											})
											m.redraw()

											var pageUrl = `/forms/${id}?tab=${tab.name}`
											window.history.pushState('', '', pageUrl)
										},
									}),
									m('.relative', [
										[
											tabs.find((t) => t.active).id === 1
												? m(QuestionsTab, {
														id,
														form: model,
														questionTypes,
												  })
												: tabs.find((t) => t.active).id === 2
												? m(AnswersTab, {
														id,
														form: model,
												  })
												: tabs.find((t) => t.active).id === 3
												? m(InfoTab, {
														id,
														form: model,
												  })
												: null,
										],
									]),
								]),
							]),
						]),
					]),
			])
		},
	}
}
