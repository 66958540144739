import { Breadcrumb, TinyMce, Input, EditModelForm, EditFormSection, Checkbox } from 'components'
import { getAdminEntryStatus, updateAdminEntryStatus } from 'api/statuses'
import { notification } from 'utils/notifications'

export const EditEntryStatus = ({ attrs: { id } }) => {
	let ready
	let entryStatus

	if (id) {
		getAdminEntryStatus(id).then((data) => {
			entryStatus = data

			ready = true
		})
	}

	const update = (e) => {
		e.preventDefault()
		if (id) {
			updateAdminEntryStatus(entryStatus.id, entryStatus)
				.then(() => {
					notification.success({ title: 'Status updated' })
					m.route.set('/entry-statuses')
				})
				.catch((err) => {
					notification.error({ title: err.response })
				})
		}
	}

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Entry Statuses',
							href: '/entry-statuses',
						},
						{
							title: ready ? `Edit ${entryStatus.name}` : 'Edit',
						},
					],
				}),

				ready &&
					m(
						EditModelForm,
						{
							onsubmit: update,
						},
						[
							m(
								EditFormSection,
								{
									heading: 'Edit Entry Status',
									description: '',
								},
								[
									ready &&
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-4', [
												m(Input, {
													label: 'Name',
													value: entryStatus.name,
													type: 'text',
													required: false,
													oninput(value) {
														entryStatus.name = value
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'Visible in Voting?',
													checked: entryStatus.isVisibleInVoting,
													onchange() {
														entryStatus.isVisibleInVoting = !entryStatus.isVisibleInVoting
													},
												}),
											]),
										]),
								]
							),
						]
					),
			]
		},
	}
}
