import './styles/style.css'
import { Routes, DefaultRoute } from './view/routes'
import 'utils/analytics'

if (module.hot) {
	module.hot.accept()
}

if (process.env.NODE_ENV !== 'production') {
	console.log('Looks like we are in development mode!')
}

if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
	Sentry.init({ dsn: 'https://432cf32c26e7ed220578c99d5abd28fc@o345045.ingest.us.sentry.io/4507746422882304' })
}

const $root = document.body.querySelector('#root')
m.route($root, DefaultRoute, Routes)

let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

window.addEventListener('resize', () => {
	vh = window.innerHeight * 0.01
	document.documentElement.style.setProperty('--vh', `${vh}px`)
})

window.addEventListener('orientationchange', () => {
	vh = window.innerHeight * 0.01
	document.documentElement.style.setProperty('--vh', `${vh}px`)
})
