import { Button, Table, Row, Cell, CellBool, Svg, Pagination, LoadingFullScreen } from 'components'
import { File } from 'components/File'
import { ImportResponseDialog } from './ImportResponseDialog'
import { getAwardsYearUsers, exportVoters, importFilmAssignments, exportFilmAssignments } from 'api/awardsyears'
import chevronDown from 'assets/icons/outline/chevron-double-down.svg'
import chevronUp from 'assets/icons/outline/chevron-double-up.svg'
import { notification } from 'utils/notifications'

export const UsersTab = ({ attrs: { id, awardsYear, search, p } }) => {
	const root = `/awardsyears/${id}/voters`
	let searchString = search || ''
	let sortBy = 'name'
	let sortOrder = 'asc'
	let available = false
	let list
	let meta
	let page = p || 1
	let perPage = 25

	const loadData = () => {
		list = null
		var pageUrl = `${window.location.pathname}?tab=Voters&p=${page}&search=${searchString}&available=${
			available ? 'true' : 'false'
		}`
		window.history.pushState('', '', pageUrl)
		getAwardsYearUsers(id, {
			search: searchString,
			page,
			pagesize: perPage,
			sortBy,
			sortOrder,
			available,
		}).then((res) => {
			list = res.data
			meta = res.meta
		})
	}

	let exportingUsers
	const onExportUsers = () => {
		exportVoters(id, searchString, available).then((res) => {
			exportingUsers = false
		})
	}
	let importResponseData
	const importResponseOpen = flyd.stream(false)
	let importingFilmAssignments
	let form = {
		file: null,
	}
	const onImportFilmAssignments = () => {
		if (form.file) {
			importingFilmAssignments = true
			importFilmAssignments(id, form.file)
				.then((msg) => {
					importResponseData = msg
					importingFilmAssignments = false
					form.file = null
					importResponseOpen(true)
				})
				.catch((err) => {
					console.log(err)
					notification.error({ title: 'Error - unable to import' })
					importingFilmAssignments = false
				})
		} else {
			alert('File must be selected')
		}
	}

	let exportingFilmAssignments
	const onExportFilmAssignments = () => {
		exportFilmAssignments(id).then((res) => {
			exportingFilmAssignments = false
		})
	}

	const cols = [
		{ label: 'Id' },
		{ label: 'Name' },
		{ label: 'Email' },
		{
			label: 'Available?',
			align: 'center',
			classes: ['w-32'],
			filter: true,
			onclick() {
				available = !available
				loadData()
			},
		},
		{ label: 'UB Complete?', align: 'center', classes: ['w-32'] },
		{ label: 'Subgroups' },
		{ label: 'Rounds' },
	]

	loadData()

	return {
		view({ attrs: { id } }) {
			return m('.h-auto', [
				m(
					Table,
					{
						cols: cols,
						searchRow: true,
						searchString: searchString,
						onsearchinput: (newSearch) => {
							searchString = newSearch
						},
						onsearch: () => {
							page = 1
							loadData()
						},
						sortOrder,
						sortBy,
						filter: available,
					},
					[
						list
							? list.map((row) => {
									return m(
										Row,
										{
											clickable: true,
											onclick: (e) => {
												if (e.ctrlKey || e.metaKey) {
													window.open(`${root}/${row.id}`, '_blank')
												} else {
													m.route.set(`${root}/${row.id}`)
												}
											},
											bg: row.isLive ? 'bg-green-50' : 'bg-white',
										},
										[
											m(Cell, row.id),
											m(Cell, row.fullName),
											m(Cell, row.email),
											m(CellBool, {
												val: row.isAvailable,
											}),
											m(CellBool, {
												val: row.isUnconsciousBiasTrainingComplete,
											}),
											m(Cell, row.subgroups),
											m(Cell, row.rounds),
										]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
				m(
					'div',
					meta &&
						m(Pagination, {
							meta: meta,
							onrefresh: () => {
								loadData()
							},
							onpage: (newPage) => {
								page = newPage
								loadData()
							},
							buttons: [
								m('.flex.justify-end.gap-x-4', [
									m(
										Button,
										{
											size: 'sm',
											classes: ['my-2', 'whitespace-nowrap', 'text-white'],
											type: 'button',
											loading: exportingUsers,
											onclick: () => {
												exportingUsers = true
												onExportUsers()
											},
										},
										[
											m(
												Svg,
												{
													classes: ['w-4', 'mx-auto'],
												},
												[chevronDown]
											),
											'Export Voters',
										]
									),
									m(
										Button,
										{
											size: 'sm',
											classes: ['my-2', 'whitespace-nowrap', 'text-white'],
											type: 'button',
											loading: exportingFilmAssignments,
											onclick: () => {
												exportingFilmAssignments = true
												onExportFilmAssignments()
											},
										},
										[
											m(
												Svg,
												{
													classes: ['w-4', 'mx-auto'],
												},
												[chevronDown]
											),
											'Export Film Assignments',
										]
									),
									m('div', [
										importingFilmAssignments
											? m(LoadingFullScreen)
											: m(
													File,
													{
														type: 'file',
														name: 'file',
														classes: ['my-2'],
														label: 'Upload',
														value: form.file,
														oninput: (val) => {
															form.file = val
															onImportFilmAssignments()
														},
													},
													'Import Film Assignments'
											  ),
									]),
								]),
							],
						})
				),

				importResponseOpen() &&
					m(ImportResponseDialog, {
						open: importResponseOpen,
						heading: 'Import Film Assignments',
						importResponse: importResponseData,
						loading: importingFilmAssignments,
					}),
			])
		},
	}
}
