import { request } from './request'

export const getSubmissions = (params) => {
	return request({
		url: 'submissions',
		params: {
			pagesize: 25,
			...params,
		},
		method: 'GET',
	})
}

export const getSubmission = (id) => {
	return request({
		url: `submissions/${id}`,
		method: 'GET',
	})
}

export const updateSubmission = (id, model) => {
	return request({
		url: `submissions/${id}`,
		method: 'PUT',
		body: model,
	})
}

export const deleteSubmission = (id) => {
	return request({
		url: `submissions/${id}`,
		method: 'DELETE',
	})
}

export const getSubmissionStatusSummary = (awardsYearId) => {
	return awardsYearId
		? request({
				url: `submissions/status-summary/${awardsYearId}`,
				method: 'GET',
		  })
		: request({
				url: `submissions/status-summary`,
				method: 'GET',
		  })
}

export const getSubmissionStatuses = () => {
	return request({
		url: `submissions/statuses`,
		method: 'GET',
	})
}

export const getSubmissionStatus = (id) => {
	return request({
		url: `submissions/statuses/${id}`,
		method: 'GET',
	})
}

export const udpateSubmissionStatus = (id, body) => {
	return request({
		url: `submissions/statuses/${id}`,
		method: 'PUT',
		body,
	})
}

export const getSupportingMaterialsStatuses = () => {
	return request({
		url: `submissions/supporting-materials-statuses`,
		method: 'GET',
	})
}

export const getSubmissionCheckStatuses = () => {
	return request({
		url: `submissions/submission-check-statuses`,
		method: 'GET',
	})
}

export const exportSubmissions = (params) => {
	return request({
		url: `submissions/${params.awardsYearId}/export`,
		method: 'GET',
		params,
		responseType: 'blob',
		extract(val) {
			return val.response
		},
	}).then((res) => {
		const date = new Date()
		var anchor = document.createElement('a')
		anchor.download = `bifa_submissions_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}.xlsx`
		anchor.href = window.URL.createObjectURL(res)
		anchor.click()
	})
}

export const submissionToEntries = (id, model) => {
	return request({
		url: `submissions/${id}/toentries`,
		method: 'POST',
		body: model,
	})
}

export const getPerson = (name) => {
	return request({
		url: `submissions/person`,
		method: 'GET',
		params: {
			name,
		},
	})
}

export const getExtension = (model) => {
	return request({
		url: `submissions/extension`,
		method: 'POST',
		body: model,
	})
}
