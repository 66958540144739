import { Table, Row, Cell, CellBool, LoadingFullScreen, Pagination, Button, Svg } from 'components'
import {
	getAwardsYearRounds,
	getAwardsYearRound,
	updateRound,
	addRound,
	getCurrentAwardsYearCategories,
} from 'api/awardsyears'
import { EditRoundDialog } from './EditRoundDialog'
import addIcon from 'assets/icons/outline/plus.svg'
import { DateTime } from 'luxon'

export const RoundsTab = ({ attrs: { id, awardsYear } }) => {
	const root = `/awardsyears/${id}/rounds`
	const cols = [
		{ label: 'Id' },
		{ label: 'Number' },
		{ label: 'Name' },
		{ label: 'Start' },
		{ label: 'End' },
		{ label: 'Viewing?', align: 'center' },
		{ label: 'Nominations?', align: 'center' },
		{ label: 'Jury?', align: 'center' },
		{ label: 'Hidden?', align: 'center' },
	]
	let list
	let meta

	const loadData = () => {
		list = null
		getAwardsYearRounds(id).then((res) => {
			list = res
			meta = {
				currentPage: 1,
				itemsPerPage: res.length,
				totalItems: res.length,
				totalPages: 1,
			}
		})
	}

	loadData()

	let selectedItem = null
	let savingItem
	const editItemOpen = flyd.stream(false)

	const onSaveRound = (data) => {
		if (data.id) {
			updateRound(awardsYear.id, data.id, data).then((res) => {
				loadData()
				editItemOpen(false)
			})
		} else {
			addRound(awardsYear.id, data).then((res) => {
				loadData()
				editItemOpen(false)
			})
		}
	}

	return {
		view({ attrs: { id, awardsYear } }) {
			return m('.h-auto', [
				m(
					Table,
					{
						cols: cols,
					},
					[
						list
							? list.map((row) => {
									return m(
										Row,
										{
											clickable: true,
											onclick: (e) => {
												if (e.ctrlKey || e.metaKey) {
													window.open(`${root}/${row.id}`, '_blank')
												} else {
													m.route.set(`${root}/${row.id}`)
												}
											},
											bg: 'bg-white',
										},
										[
											m(Cell, row.id),
											m(Cell, row.number),
											m(Cell, row.name),
											m(Cell, row.startDate ? DateTime.fromISO(row.startDate).toLocaleString() : ''),
											m(Cell, row.endDate ? DateTime.fromISO(row.endDate).toLocaleString() : ''),
											m(CellBool, {
												val: row.isViewingRound,
											}),
											m(CellBool, {
												val: row.isNominationsRound,
											}),
											m(CellBool, {
												val: row.isJuryRound,
											}),
											m(CellBool, {
												val: row.isHidden,
											}),
										]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
				m(
					'div',
					meta &&
						m(Pagination, {
							meta: meta,
							onrefresh: () => {
								loadData()
							},
							onpage: (newPage) => {
								page = newPage
								loadData()
							},
							buttons: [
								m('.flex.justify-end.gap-x-4', [
									m(
										Button,
										{
											size: 'sm',
											classes: ['my-2', 'whitespace-nowrap', 'text-white'],
											type: 'button',
											onclick: () => {
												selectedItem = {}
												editItemOpen(true)
											},
										},
										[
											m(
												Svg,
												{
													classes: ['w-4', 'mx-auto'],
												},
												[addIcon]
											),
											'Add round',
										]
									),
								]),
							],
						})
				),
				editItemOpen() &&
					m(EditRoundDialog, {
						open: editItemOpen,
						onsubmit: onSaveRound,
						loading: savingItem,
						round: selectedItem,
						awardsYearId: awardsYear.id,
						awardsYear,
					}),
			])
		},
	}
}
