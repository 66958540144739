import { Breadcrumb, Button, Select, Input, Checkbox, EditModelForm, EditFormSection } from 'components'
import { getAwardsYearUser, updateAwardsYearUser, getAwardsYearSubgroups, getAwardsYearRounds } from 'api/awardsyears'
import { notification } from 'utils/notifications'

export const EditAwardsYearUser = ({ attrs: { id, awardsYearUserId } }) => {
	let ready
	let model

	let subgroups
	let rounds

	getAwardsYearSubgroups(id).then((res) => {
		subgroups = res
	})

	getAwardsYearRounds(id).then((res) => {
		rounds = res
	})

	if (id) {
		getAwardsYearUser(id, awardsYearUserId).then((data) => {
			model = data
			ready = true
		})
	} else {
		model = {}
		ready = true
	}

	const createOrUpdate = (e) => {
		e.preventDefault()
		if (awardsYearUserId) {
			updateAwardsYearUser(id, awardsYearUserId, model)
				.then(() => {
					notification.success({ title: 'Voter updated' })
					m.route.set(`/awardsyears/${id}?tab=Voters`)
				})
				.catch((err) => {
					notification.error({ title: err.response })
				})
		} else {
			// createAwardsYearUser(model)
			// 	.then((data) => {
			// 		notification.success({ title: 'Awards Year created' })
			// 		m.route.set(`/awardsyears/${data.id}`)
			// 	})
			// 	.catch((err) => {
			// 		notification.error({ title: err.response })
			// 	})
		}
	}
	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Awards Years',
							href: '/awardsyears',
						},
						{
							title: ready ? model.awardsYearYear : '...',
							href: `/awardsyears/${id}`,
						},
						{
							title: 'Voters',
							href: `/awardsyears/${id}?tab=Voters`,
						},
						{
							title: !id ? 'Create' : ready ? `Edit ${model.fullName}` : 'Edit',
						},
					],
				}),

				ready &&
					m(
						EditModelForm,
						{
							onsubmit: createOrUpdate,
						},
						[
							m(
								EditFormSection,
								{
									heading: id ? 'Edit Voter' : 'Create Voter',
									description: '',
									extra: null,
								},
								[
									ready &&
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-3', [
												m(Input, {
													label: 'User',
													value: model.fullName,
													type: 'readonly',
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													label: 'Email',
													value: model.email,
													type: 'readonly',
												}),
											]),
											m('.col-span-6', [
												m(Input, {
													label: 'Current Employers',
													value: model.currentEmployers,
													type: 'text',
													oninput(value) {
														model.currentEmployers = value
													},
												}),
											]),
											m('.col-span-6', [
												m(Input, {
													label: 'Productions Last 2 Years',
													value: model.productionsLast2Years,
													type: 'text',
													oninput(value) {
														model.productionsLast2Years = value
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'Available?',
													checked: model.isAvailable,
													onchange() {
														model.isAvailable = !model.isAvailable
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'UB Complete?',
													checked: model.isUnconsciousBiasTrainingComplete,
													onchange() {
														model.isUnconsciousBiasTrainingComplete = !model.isUnconsciousBiasTrainingComplete
													},
												}),
												model.unconsciousBiasStatus && m('span.text-sm.text-gray-600', model.unconsciousBiasStatus),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'Winners Voting?',
													checked: model.isWinnersVoting,
													onchange() {
														model.isWinnersVoting = !model.isWinnersVoting
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'Agree code of conduct?',
													checked: model.isAgreeCodeOfConduct,
													onchange() {
														model.isAgreeCodeOfConduct = !model.isAgreeCodeOfConduct
													},
												}),
												model.isAgreeCodeOfConductDateTime &&
													m('span.text-sm.text-gray-600', model.isAgreeCodeOfConductDateTime),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'Agree terms?',
													checked: model.isAgreeTerms,
													onchange() {
														model.isAgreeTerms = !model.isAgreeTerms
													},
												}),
												model.isAgreeTermsDateTime && m('span.text-sm.text-gray-600', model.isAgreeTermsDateTime),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'Agree Eventive?',
													checked: model.isAgreeEventive,
													onchange() {
														model.isAgreeEventive = !model.isAgreeEventive
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'BIFA Contact Consent?',
													checked: model.isBIFAContactConsent,
													onchange() {
														model.isBIFAContactConsent = !model.isBIFAContactConsent
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'BIFA Newsletter Consent?',
													checked: model.isBIFANewsletterConsent,
													onchange() {
														model.isBIFANewsletterConsent = !model.isBIFANewsletterConsent
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'Distributor Contact Consent?',
													checked: model.isDistributorContactConsent,
													onchange() {
														model.isDistributorContactConsent = !model.isDistributorContactConsent
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'Distributor non-UK Contact Consent?',
													checked: model.isDistributorContactOutsideUKConsent,
													onchange() {
														model.isDistributorContactOutsideUKConsent = !model.isDistributorContactOutsideUKConsent
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'BIFA Research?',
													checked: model.isBIFAResearch,
													onchange() {
														model.isBIFAResearch = !model.isBIFAResearch
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'More EDI Courses Info?',
													checked: model.isMoreEDICoursesInfo,
													onchange() {
														model.isMoreEDICoursesInfo = !model.isMoreEDICoursesInfo
													},
												}),
											]),
											m('.col-span-6', [
												m(Input, {
													label: 'Assigned Films',
													value: model.films
														? model.films
																.map((x) => {
																	return x.filmTitle
																})
																.join(', ')
														: '',
													type: 'readonly',
												}),
											]),
											m('.col-span-6', [
												m(Input, {
													label: 'Assign Film (type in FilmID)',
													value: model.newFilmId,
													type: 'number',
													oninput(value) {
														model.newFilmId = value
													},
												}),
											]),
										]),
								]
							),
							m(
								EditFormSection,
								{
									heading: 'Subgroups',
									description: '',
									extra: null,
								},
								[
									ready &&
										subgroups &&
										m('table.table.w-full', [
											m('tr', [m('th', 'Name'), m('th', 'SubTeam'), m('th', 'Chair?'), m('th', 'SubTeam Chair?')]),
											model.subgroups.map((ea, eix) => {
												return m('tr', [
													m(
														'td',
														m(Select, {
															label: '',
															value: ea.subgroupId,
															required: true,
															choices: subgroups.map((type) => {
																return { label: type.name, value: type.id }
															}),
															onchange(val) {
																model.subgroups[eix].subgroupId = parseInt(val)
															},
														})
													),
													m(
														'td.w-32',
														m(Input, {
															label: '',
															value: ea.subTeam,
															type: 'text',
															oninput(value) {
																model.subgroups[eix].subTeam = value
															},
														})
													),
													m(
														'td',
														m(Checkbox, {
															label: '',
															table: true,
															checked: ea.isChair,
															onchange() {
																model.subgroups[eix].isChair = !model.subgroups[eix].isChair
															},
														})
													),
													m(
														'td.text-center',
														m(Checkbox, {
															label: '',
															table: true,
															checked: ea.isSubTeamChair,
															onchange() {
																model.subgroups[eix].isSubTeamChair = !model.subgroups[eix].isSubTeamChair
															},
														})
													),
												])
											}),

											m('tr', [
												m(
													'td.bg-gray-100.p-1.text-right',
													{ colspan: 4 },
													m(
														Button,
														{
															size: 'xs',
															type: 'button',
															onclick() {
																model.subgroups.push({
																	subgroupName: '',
																	isChair: false,
																	isSubTeamChair: false,
																	subgroupId: 0,
																	subTeam: '',
																	id: 0,
																})
															},
														},
														'Add Subgroup'
													)
												),
											]),
										]),
								]
							),
							m(
								EditFormSection,
								{
									heading: 'Rounds',
									description: '',
									extra: null,
								},
								[
									ready &&
										rounds &&
										m('table.table-fixed.w-full', [
											m('tr', [
												m('th.w-1/2', 'Name'),
												m('th', 'Abstaining?'),
												m('th', 'Complete?'),
												m('th', 'Completed Date'),
											]),
											model.rounds.map((ea, eix) => {
												return m('tr', [
													m(
														'td',
														m(Select, {
															label: '',
															value: ea.roundId,
															required: true,
															choices: rounds.map((type) => {
																return { label: `${type.number}: ${type.name}`, value: type.id }
															}),
															onchange(val) {
																model.rounds[eix].roundId = parseInt(val)
															},
														})
													),
													m(
														'td',
														m(Checkbox, {
															label: '',
															table: true,
															checked: ea.isAbstaining,
															onchange() {
																model.rounds[eix].isAbstaining = !model.rounds[eix].isAbstaining
															},
														})
													),
													m(
														'td',
														m(Checkbox, {
															label: '',
															table: true,
															checked: ea.isComplete,
															onchange() {
																model.rounds[eix].isComplete = !model.rounds[eix].isComplete
															},
														})
													),
													m('td', ea.completedDate),
												])
											}),

											m('tr', [
												m(
													'td.bg-gray-100.p-1.text-right',
													{ colspan: 4 },
													m(
														Button,
														{
															size: 'xs',
															type: 'button',
															onclick() {
																model.rounds.push({
																	roundId: '',
																	isComplete: false,
																	isAbstaining: false,
																	completedDate: null,
																})
															},
														},
														'Add Round'
													)
												),
											]),
										]),
								]
							),
						]
					),
			]
		},
	}
}
