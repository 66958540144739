import { request } from './request'

export const getEntryFees = (params) => {
	return request({
		url: 'entryfees',
		method: 'GET',
		params: {
			pagesize: 1000,
			...params,
		},
	})
}
export const getEntryFee = (id) => {
	return request({
		url: `entryfees/${id}`,
		method: 'GET',
	})
}

export const createEntryFee = (rate) => {
	return request({
		url: 'entryfees',
		method: 'POST',
		body: rate,
	})
}

export const updateEntryFee = (id, rate) => {
	return request({
		url: `entryfees/${id}`,
		method: 'PUT',
		body: rate,
	})
}

export const deleteEntryFee = (id) => {
	return request({
		url: `entryfees/${id}`,
		method: 'DELETE',
	})
}

export const getEntryForms = () => {
	return request({
		url: `entryfees/entryforms`,
		method: 'GET',
	})
}
