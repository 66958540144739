import { PageHeading, Link } from 'components'
import { Breadcrumb } from 'components/Breadcrumb'
import { Tabs } from 'components/Tabs'
import { DateTime } from 'luxon'
import { getAwardsYear, downloadViewings } from 'api/awardsyears'
import { VotingCategoriesTab } from './VotingCategoriesTab'
import { UsersTab } from './UsersTab'
import { RoundsTab } from './RoundsTab'
import { FilmsTab } from './FilmsTab'
import { EntriesTab } from './EntriesTab'
import { SubgroupsTab } from './SubgroupsTab'
import { ScreenerRequestsTab } from './ScreenerRequestsTab'

import editIcon from 'assets/icons/solid/pencil-alt.svg'
import downloadIcon from 'assets/icons/outline/download.svg'
import { LoadingFullScreen } from '../../../components'

export const ViewAwardsYear = ({ attrs: { id, tab, categoryId, search, p } }) => {
	let model

	const metaItems = []

	getAwardsYear(id).then((data) => {
		model = data
	})
	if (!tab) {
		tab = 'Voting Categories'
	}
	const tabs = ['Voting Categories', 'Subgroups', 'Voters', 'Films', 'Entries', 'Rounds', 'Screener Requests'].map(
		(t, ix) => {
			return {
				id: ix + 1,
				name: t,
				active: tab && tab === t,
			}
		}
	)
	let exporting = false
	const onDownloadViewings = () => {
		exporting = true
		downloadViewings(id).then((res) => {
			exporting = false
		})
	}
	return {
		view({ attrs: { id, tab } }) {
			return m('div', [
				model &&
					m(Breadcrumb, {
						links: [
							{
								title: 'Awards Years',
								href: '/awardsyears',
							},
							{
								title: model.year,
							},
						],
					}),
				model &&
					m('.flex.flex-wrap.relative.h-screen.pb-24.overflow-x-hidden.content-start', [
						m('.p-4.w-full', [
							m('div.shadow.sm:rounded-md.divide-y.divide-gray-200', [
								m('div.px-4.py-5.bg-white.space-y-6.sm:p-6', [
									m(PageHeading, {
										heading: [
											model.year,
											m(
												'span.inline-flex.items-center.px-5/2.py-1/2.rounded-full.text-xs.font-medium',
												{
													class: model.isLive ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800',
												},
												model.isLive ? 'Live' : 'Not Live'
											),
										],
										subtitle: DateTime.fromISO(model.awardsDate).toLocaleString(),
										metaItems: null,
										actions: [
											{
												icon: editIcon,
												label: 'Edit Awards Year',
												action: () => {
													m.route.set(`/awardsyears/${id}/edit`)
												},
											},
											{
												icon: downloadIcon,
												label: 'Download Viewing Feedback',
												action: onDownloadViewings,
											},
										],
									}),
									exporting ? m(LoadingFullScreen) : null,
								]),
								m('div.bg-gray-50.px-4.py-5.sm:p-6', [
									m(Tabs, {
										tabs,
										onTabChange: (tabId) => {
											tabs.forEach((t) => {
												t.active = false
												if (t.id === tabId) {
													t.active = true
													tab = t
												}
											})
											m.redraw()

											var pageUrl = `/awardsyears/${id}?tab=${tab.name}`
											window.history.pushState('', '', pageUrl)
										},
									}),
									m('.sm:grid.sm:grid-cols-4.sm:gap-4', [
										m('.sm:col-span-4', [
											tabs.find((t) => t.active).id === 1
												? m(VotingCategoriesTab, {
														id,
														awardsYear: model,
												  })
												: tabs.find((t) => t.active).id === 2
												? m(SubgroupsTab, {
														id,
														awardsYear: model,
												  })
												: tabs.find((t) => t.active).id === 3
												? m(UsersTab, {
														id,
														awardsYear: model,
												  })
												: tabs.find((t) => t.active).id === 4
												? m(FilmsTab, {
														id,
														awardsYear: model,
												  })
												: tabs.find((t) => t.active).id === 5
												? m(EntriesTab, {
														id,
														awardsYear: model,
														categoryId,
														p,
														search,
												  })
												: tabs.find((t) => t.active).id === 6
												? m(RoundsTab, {
														id,
														awardsYear: model,
												  })
												: tabs.find((t) => t.active).id === 7
												? m(ScreenerRequestsTab, {
														id,
														awardsYear: model,
												  })
												: null,
										]),
									]),
								]),
							]),
						]),
					]),
			])
		},
	}
}
