import { Svg } from 'components'
import chevronLeftIcon from 'assets/icons/outline/chevron-left.svg'
import chevronRightIcon from 'assets/icons/outline/chevron-right.svg'
import loadingIcon from 'assets/loading.svg'

export const Pagination = () => {
	return {
		view({ attrs: { meta, onpage, onrefresh, buttons } }) {
			return m('div.bg-gray-200.px-3.py-2.flex.items-center.justify-between.border-t.border-gray-300', [
				m('div.flex-1.flex.justify-between.sm:hidden', [
					m(
						"a.relative.inline-flex.items-center.px-4.py-2.border.border-gray-300.text-sm.font-medium.rounded-md.text-gray-700.bg-white.hover:text-gray-500[href='#']",
						{
							onclick: (e) => {
								e.preventDefault()
								onpage(meta.currentPage - 1)
							},
						},
						' Previous '
					),
					m(
						"a.ml-3.relative.inline-flex.items-center.px-4.py-2.border.border-gray-300.text-sm.font-medium.rounded-md.text-gray-700.bg-white.hover:text-gray-500[href='#']",
						{
							onclick: (e) => {
								e.preventDefault()
								onpage(meta.currentPage - 1)
							},
						},
						' Next '
					),
				]),
				m('div.hidden.sm:flex-1.sm:flex.sm:items-center.sm:justify-between', [
					m(
						'div',
						m(
							'p.text-sm.text-gray-900',
							meta.totalItems === -1 && meta.itemsPerPage
								? [
										' Showing ',
										m('span.font-medium', meta.itemsPerPage * (meta.currentPage - 1) + 1),
										' to ',
										m('span.font-medium', meta.itemsPerPage * meta.currentPage),
										'',
								  ]
								: meta.totalItems > 0
								? [
										' Showing ',
										m('span.font-medium', meta.itemsPerPage * (meta.currentPage - 1) + 1),
										' to ',
										m(
											'span.font-medium',
											meta.totalItems
												? meta.itemsPerPage * meta.currentPage < meta.totalItems
													? meta.itemsPerPage * meta.currentPage
													: meta.totalItems
												: 1
										),
										' of ',
										m('span.font-medium', meta.totalItems),
										' results ',
								  ]
								: 'Showing results'
						)
					),
					buttons,
					m(
						'.flex.justify-end.w-32',
						onrefresh &&
							m(
								"a.relative.inline-flex.items-center.mr-3.px-2.py-2.rounded-l-md.border.border-gray-300.bg-white.text-sm.font-medium.text-gray-500 hover:bg-gray-50[href='#']",
								{
									onclick: (e) => {
										e.preventDefault()
										onrefresh()
									},
								},
								[m('span.sr-only', 'Refresh'), m(Svg, { classes: ['w-4', 'h-4'] }, loadingIcon)]
							),
						meta.totalPages !== 0 &&
							m("nav.relative.z-0.inline-flex.shadow-sm.-space-x-px[aria-label='Pagination']", [
								m(
									"a.relative.inline-flex.items-center.px-2.py-2.rounded-l-md.border.border-gray-300.bg-white.text-sm.font-medium[href='#']",
									{
										class:
											meta.currentPage === 1
												? 'text-gray-200 pointer-events-none '
												: 'text-gray-500 hover:bg-gray-50',
										onclick: (e) => {
											e.preventDefault()
											onpage(meta.currentPage - 1)
										},
									},
									[
										m('span.sr-only', 'Previous'),
										m(Svg, { classes: ['w-4', 'h-4'] }, chevronLeftIcon),
									]
								),
								m(
									"a.relative.inline-flex.items-center.px-2.py-2.rounded-r-md.border.border-gray-300.bg-white.text-sm.font-medium[href='#']",
									{
										class:
											meta.currentPage === meta.totalPages
												? 'text-gray-200 pointer-events-none '
												: 'text-gray-500 hover:bg-gray-50',
										onclick: (e) => {
											e.preventDefault()
											onpage(meta.currentPage + 1)
										},
									},
									[m('span.sr-only', 'Next'), m(Svg, { classes: ['w-4', 'h-4'] }, chevronRightIcon)]
								),
							])
					),
				]),
			])
		},
	}
}
