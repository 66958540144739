import { LoadingFullScreen } from 'components'

export const InfoTab = () => {
	return {
		view({ attrs: { id, form } }) {
			return m('.p-3', [
				m('dl.divide-y.divide-gray-200', [
					m('div.px-4.py-6.sm:grid.sm:grid-cols-3.sm:gap-4.sm:px-0', [
						m('dt.text-sm.font-bold.leading-6.text-gray-900', 'Form Description'),
						m('dd.mt-1.text-sm.leading-6.text-gray-700.sm:col-span-2.sm:mt-0', m.trust(form.description)),
					]),
					m('div.px-4.py-6.sm:grid.sm:grid-cols-3.sm:gap-4.sm:px-0', [
						m('dt.text-sm.font-bold.leading-6.text-gray-900', 'Notification Name'),
						m('dd.mt-1.text-sm.leading-6.text-gray-700.sm:col-span-2.sm:mt-0', form.notificationName),
					]),
					m('div.px-4.py-6.sm:grid.sm:grid-cols-3.sm:gap-4.sm:px-0', [
						m('dt.text-sm.font-bold.leading-6.text-gray-900', 'Notification Email'),
						m('dd.mt-1.text-sm.leading-6.text-gray-700.sm:col-span-2.sm:mt-0', form.notificationEmail),
					]),
					m('div.px-4.py-6.sm:grid.sm:grid-cols-3.sm:gap-4.sm:px-0', [
						m('dt.text-sm.font-bold.leading-6.text-gray-900', 'Email From'),
						m('dd.mt-1.text-sm.leading-6.text-gray-700.sm:col-span-2.sm:mt-0', form.emailFrom),
					]),
					m('div.px-4.py-6.sm:grid.sm:grid-cols-3.sm:gap-4.sm:px-0', [
						m('dt.text-sm.font-bold.leading-6.text-gray-900', 'Email Confirmation Subject'),
						m(
							'dd.mt-1.text-sm.leading-6.text-gray-700.sm:col-span-2.sm:mt-0',
							form.emailConfirmationSubject
						),
					]),
					m('div.px-4.py-6.sm:grid.sm:grid-cols-3.sm:gap-4.sm:px-0', [
						m('dt.text-sm.font-bold.leading-6.text-gray-900', 'Email Confirmation'),
						m(
							'dd.mt-1.text-sm.leading-6.text-gray-700.sm:col-span-2.sm:mt-0',
							m.trust(form.emailConfirmationBody)
						),
					]),
				]),
			])
		},
	}
}
