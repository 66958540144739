import { Table, Row, Cell, Button, Svg, LoadingFullScreen, Pagination } from 'components'
import { getFormAnswerSets, exportAnswers } from 'api/forms'
import { DateTime } from 'luxon'
import chevronDown from 'assets/icons/outline/chevron-double-down.svg'

export const AnswersTab = ({ attrs: { id, search, p } }) => {
	let searchString = search || ''
	let sortBy = 'date'
	let sortOrder = 'desc'
	let list
	let meta
	let page = p || 1
	let perPage = 25

	const loadData = () => {
		list = null
		var pageUrl = `${window.location.pathname}?tab=Answers&p=${page}&search=${searchString}`
		window.history.pushState('', '', pageUrl)
		getFormAnswerSets(id, {
			search: searchString,
			page,
			pagesize: perPage,
			sortBy,
			sortOrder,
		}).then((res) => {
			list = res.data
			meta = res.meta
		})
	}

	const cols = [{ label: 'Id' }, { label: 'Created' }, { label: 'Name' }, { label: 'User' }]

	let exportingAnswers
	const onExportAnswers = () => {
		exportAnswers(id).then((res) => {
			exportingAnswers = false
		})
	}

	return {
		view() {
			return m('.h-auto', [
				m(
					Table,
					{
						cols: cols,
						oncreate({ dom }) {
							loadData()
						},
						searchRow: false,
						searchString: searchString,
						onsearchinput: (newSearch) => {
							searchString = newSearch
						},
						onsearch: () => {
							page = 1
							loadData()
						},
						sortOrder,
						sortBy,
					},
					[
						list
							? list.map((row) => {
									return m(
										Row,
										{
											clickable: true,
											onclick: (e) => {
												// if (e.ctrlKey || e.metaKey) {
												// 	window.open(`${root}/${row.id}`, '_blank')
												// } else {
												// 	m.route.set(`${root}/${row.id}`)
												// }
											},
											bg: 'bg-white',
										},
										[
											m(Cell, row.id),
											m(Cell, DateTime.fromISO(row.createdDate).toLocaleString()),
											m(
												Cell,
												row.answers &&
													row.answers.find((x) => {
														return x.questionName === 'Your name:' || x.questionName == 'Your name'
													})
													? row.answers.find((x) => {
															return x.questionName === 'Your name:' || x.questionName === 'Your name'
													  }).value
													: ''
											),
											m(Cell, row.user),
										]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
				m(
					'div',
					meta &&
						m(Pagination, {
							meta: meta,
							onrefresh: () => {
								loadData()
							},
							onpage: (newPage) => {
								page = newPage
								loadData()
							},
							buttons: [
								m(
									Button,
									{
										size: 'sm',
										classes: ['my-2', 'whitespace-nowrap', 'text-white'],
										type: 'button',
										loading: exportingAnswers,
										onclick: () => {
											exportingAnswers = true
											onExportAnswers()
										},
									},
									[
										m(
											Svg,
											{
												classes: ['w-4', 'mx-auto'],
											},
											[chevronDown]
										),
										'Export Answers',
									]
								),
							],
						})
				),
			])
		},
	}
}
