import { Breadcrumb, Select, Input, EditModelForm, EditFormSection, Checkbox } from 'components'
import { getEntryFee, updateEntryFee, createEntryFee, getEntryForms } from 'api/entryFees'
import { notification } from 'utils/notifications'

export const EditEntryFee = ({ attrs: { id } }) => {
	let ready
	let entryFee
	let entryForms

	if (id) {
		getEntryFee(id).then((data) => {
			entryFee = data

			ready = true
		})
	} else {
		entryFee = {
			delay: 0,
		}
		ready = true
	}

	getEntryForms().then((data) => {
		entryForms = data.map((x) => {
			return {
				label: x.name,
				value: x.id,
			}
		})
	})

	const createOrUpdate = (e) => {
		e.preventDefault()
		if (id) {
			updateEntryFee(entryFee.id, entryFee)
				.then(() => {
					notification.success({ title: 'Entry fee updated' })
					m.route.set('/entry-fees')
				})
				.catch((err) => {
					if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
						Sentry.captureException(err)
					}
					notification.error({ title: err.response })
				})
		} else {
			createEntryFee(entryFee)
				.then(() => {
					notification.success({ title: 'Entry fee created' })
					m.route.set('/entry-fees')
				})
				.catch((err) => {
					if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
						Sentry.captureException(err)
					}
					notification.error({ title: err.response })
				})
		}
	}

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Entry Fees',
							href: '/entry-fees',
						},
						{
							title: !id ? 'Create' : ready ? `Edit ${entryFee.sku ? entryFee.sku : 'No SKU'}` : 'Edit',
						},
					],
				}),

				ready &&
					m(
						EditModelForm,
						{
							onsubmit: createOrUpdate,
						},
						[
							m(
								EditFormSection,
								{
									heading: id ? 'Edit Entry Fee' : 'Create Entry Fee',
									description: '',
								},
								[
									ready &&
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-2', [
												m(Input, {
													label: 'SKU',
													value: entryFee.sku,
													type: 'text',
													required: false,
													oninput(value) {
														entryFee.sku = value
													},
												}),
											]),
											m('.col-span-4', [
												entryForms &&
													m(Select, {
														label: 'Entry Form',
														value: entryFee.formId,
														required: true,
														choices: entryForms,
														onchange(val) {
															entryFee.formId = parseInt(val)
														},
													}),
											]),
											m('.col-span-2', [
												m(Input, {
													label: 'Amount',
													value: entryFee.amount,
													type: 'number',
													step: '0.01',
													required: true,
													oninput(value) {
														entryFee.amount = value
													},
													prefix: '£',
												}),
											]),
											m('.col-span-2', [
												m(Input, {
													label: 'Budget From',
													value: entryFee.budgetFrom,
													type: 'number',
													required: true,
													oninput(value) {
														entryFee.budgetFrom = value
													},
													prefix: '£',
												}),
											]),
											m('.col-span-2', [
												m(Input, {
													label: 'Date From',
													value: entryFee.dateFrom ? entryFee.dateFrom.replace('T00:00:00Z', '') : '',
													type: 'date',
													required: true,
													oninput(value) {
														entryFee.dateFrom = value + 'T00:00:00Z'
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'Eventive British?',
													checked: entryFee.isEventiveBritish,
													onchange() {
														entryFee.isEventiveBritish = !entryFee.isEventiveBritish
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'Eventive International?',
													checked: entryFee.isEventiveInternational,
													onchange() {
														entryFee.isEventiveInternational = !entryFee.isEventiveInternational
													},
												}),
											]),
										]),
								]
							),
						]
					),
			]
		},
	}
}
