import { request } from './request'

export const getViewerStatuses = () => {
	return request({
		url: 'statuses/viewer',
		method: 'GET',
	})
}
export const getEntryStatuses = () => {
	return request({
		url: 'statuses/entry',
		method: 'GET',
	})
}

export const getAdminEntryStatuses = () => {
	return request({
		url: 'statuses/entry/admin',
		method: 'GET',
	})
}

export const getAdminEntryStatus = (id) => {
	return request({
		url: `statuses/entry/${id}/admin`,
		method: 'GET',
	})
}

export const updateAdminEntryStatus = (id, body) => {
	return request({
		url: `statuses/entry/${id}/admin`,
		method: 'PUT',
		body,
	})
}
