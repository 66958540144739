import { Table, Cell, Row, Button, Pagination, LoadingFullScreen, Breadcrumb, Svg } from 'components'

import { getAwardsYears } from 'api/awardsyears'
import { DateTime } from 'luxon'
import { debounce } from 'utils/debounce'
import tickIcon from 'assets/icons/outline/check.svg'
import crossIcon from 'assets/icons/outline/x.svg'
import addIcon from 'assets/icons/outline/plus.svg'

export const AwardsYears = ({ attrs: { search, p } }) => {
	const root = '/awardsyears'

	let searchString = search || ''
	let sortBy = 'name'
	let sortOrder = 'desc'
	let list
	let meta
	let page = p || 1
	let perPage

	const debouncedSearch = debounce((val) => {
		searchString = val.length > 2 ? val : null
		loadData()
	}, 300)

	const loadData = () => {
		list = null
		var pageUrl = `${window.location.pathname}?p=${page}&search=${searchString}`
		window.history.pushState('', '', pageUrl)
		getAwardsYears({
			search: searchString,
			page,
			pagesize: perPage,
			sortBy,
			sortOrder,
		}).then((res) => {
			list = res.data
			meta = res.meta
		})
	}

	const toggleSortOrder = (colName) => {
		sortOrder = sortBy === colName ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc'
		sortBy = colName
		page = 1
		loadData()
	}

	const cols = [
		{
			label: 'Id',
			sortBy: 'id',
			onclick(e) {
				toggleSortOrder('id')
			},
		},
		{ label: 'Year', align: 'center' },
		{ label: 'Date', align: 'center' },
		{ label: 'Entries Visible?', align: 'center' },
		{ label: 'Subgroup Reg Visible?', align: 'center' },
		{ label: 'Live?', align: 'center' },
	]

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Awards Years',
						},
					],
					buttons: [
						m(
							Button,
							{
								size: 'sm',
								classes: ['my-2', 'mx-2', 'whitespace-nowrap', 'text-white'],
								type: 'button',
								onclick: () => {
									m.route.set(root + '/create')
								},
							},
							[
								m(
									Svg,
									{
										classes: ['w-4', 'mx-auto'],
									},
									[addIcon]
								),
								'Add Awards Year',
							]
						),
					],
				}),
				m(
					Table,
					{
						cols: cols,
						oncreate({ dom }) {
							perPage = Math.floor((dom.clientHeight - 127) / 33)
							loadData()
						},
						searchRow: true,
						searchString: searchString,
						onsearchinput: (newSearch) => {
							searchString = newSearch
						},
						onsearch: () => {
							page = 1
							loadData()
						},
						sortOrder,
						sortBy,
					},
					[
						list
							? list.map((row) => {
									return m(
										Row,
										{
											clickable: true,
											onclick: (e) => {
												if (e.ctrlKey || e.metaKey) {
													window.open(`${root}/${row.id}`, '_blank')
												} else {
													m.route.set(`${root}/${row.id}`)
												}
											},
											bg: row.isLive ? 'bg-green-50' : 'bg-white',
										},
										[
											m(Cell, row.id),
											m(
												Cell,
												{
													align: 'center',
												},
												row.year
											),
											m(
												Cell,
												{
													align: 'center',
												},
												DateTime.fromISO(row.awardsDate).toLocaleString()
											),
											m(
												Cell,
												{
													align: 'center',
												},
												m(
													'span',
													m(
														Svg,
														{
															classes: [
																'w-6',
																'sm:mx-auto',
																'inline',
																'sm:block',
																row.isEntriesVisible ? 'text-green-800' : 'text-gray-400',
															],
														},
														row.isEntriesVisible ? tickIcon : crossIcon
													)
												)
											),
											m(
												Cell,
												{
													align: 'center',
												},
												m(
													'span',
													m(
														Svg,
														{
															classes: [
																'w-6',
																'sm:mx-auto',
																'inline',
																'sm:block',
																row.isSubgroupRegVisible ? 'text-green-800' : 'text-gray-400',
															],
														},
														row.isSubgroupRegVisible ? tickIcon : crossIcon
													)
												)
											),
											m(
												Cell,
												{
													align: 'center',
												},
												m(
													'span',
													m(
														Svg,
														{
															classes: [
																'w-6',
																'sm:mx-auto',
																'inline',
																'sm:block',
																row.isLive ? 'text-green-800' : 'text-gray-400',
															],
														},
														row.isLive ? tickIcon : crossIcon
													)
												)
											),
										]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
				m(
					'div',
					meta &&
						m(Pagination, {
							meta: meta,
							onrefresh: () => {
								loadData()
							},
							onpage: (newPage) => {
								page = newPage
								loadData()
							},
						})
				),
			]
		},
	}
}
