import { Breadcrumb, Select, Input, Checkbox, EditModelForm, EditFormSection, TinyMce } from 'components'
import { getForm, updateForm, createForm } from 'api/forms'
import { notification } from 'utils/notifications'
import { getAwardsYearsLookup } from 'api/awardsyears'

export const EditForm = ({ attrs: { id } }) => {
	let ready
	let model
	let awardsYears

	getAwardsYearsLookup().then((data) => {
		awardsYears = data
	})

	if (id) {
		getForm(id).then((data) => {
			model = data
			ready = true
		})
	} else {
		model = {}
		ready = true
	}

	const createOrUpdate = (e) => {
		e.preventDefault()
		if (id) {
			updateForm(model.id, model)
				.then(() => {
					notification.success({ title: 'Form updated' })
					m.route.set(`/forms/${model.id}`)
				})
				.catch((err) => {
					if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
						Sentry.captureException(err)
					}
					notification.error({ title: err.response })
				})
		} else {
			createForm(model)
				.then((data) => {
					notification.success({ title: 'Form created' })
					m.route.set(`/forms/${data.id}`)
				})
				.catch((err) => {
					if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
						Sentry.captureException(err)
					}
					notification.error({ title: err.response })
				})
		}
	}
	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Forms',
							href: '/forms',
						},
						id
							? {
									title: model ? model.name : '',
									href: `/forms/${id}`,
							  }
							: null,
						{
							title: !id ? 'Create' : ready ? `Edit Form` : 'Edit',
						},
					],
				}),

				ready &&
					m(
						EditModelForm,
						{
							onsubmit: createOrUpdate,
						},
						[
							m(
								EditFormSection,
								{
									heading: id ? 'Edit Form' : 'Create Form',
									description: '',
									extra: null,
								},
								[
									ready &&
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-4', [
												m(Input, {
													label: 'Title',
													value: model.name,
													type: 'text',
													required: true,
													oninput(value) {
														model.name = value
													},
												}),
											]),
											m('.col-span-2', [
												m(Select, {
													label: 'Form Type',
													value: model.formTypeId,
													required: true,
													choices: [
														{ label: 'Generic', value: 1 },
														{ label: 'Entry Form', value: 2 },
													],
													onchange(val) {
														model.formTypeId = parseInt(val)
													},
												}),
											]),
											m('.col-span-2', [
												m(Input, {
													label: 'Slug',
													value: model.slug,
													type: 'text',
													required: true,
													oninput(value) {
														model.slug = value
													},
												}),
											]),
											m('.col-span-2', [
												m(Input, {
													label: 'Short Name',
													value: model.shortName,
													type: 'text',
													oninput(value) {
														model.shortName = value
													},
												}),
											]),
											m('.col-span-2', [
												m(Input, {
													label: 'Closing Date',
													value: model.closingDate ? model.closingDate.split('T')[0] : '',
													type: 'date',
													required: false,
													oninput(value) {
														model.closingDate = value + 'T23:59:59'
													},
												}),
											]),
											m('.col-span-6', [
												m(TinyMce, {
													label: 'Description',
													value: model.description,
													name: 'description',
													height: 200,
													onchange: (content) => {
														model.description = content
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'Live?',
													checked: model.isLive,
													onchange() {
														model.isLive = !model.isLive
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'Archived?',
													checked: model.isArchived,
													onchange() {
														model.isArchived = !model.isArchived
													},
												}),
											]),
											m('.col-span-2', [
												awardsYears &&
													model.formTypeId === 2 &&
													m(Select, {
														label: 'Awards Year',
														value: model.awardsYearId,
														required: false,
														choices: awardsYears.map((ay) => {
															return {
																label: ay.name,
																value: ay.id,
															}
														}),
														onchange(val) {
															model.awardsYearId = parseInt(val)
														},
													}),
											]),
											m('.col-span-3', [
												m(Input, {
													label: 'Notification Name',
													value: model.notificationName,
													type: 'text',
													oninput(value) {
														model.notificationName = value
													},
												}),
											]),
											m('.col-span-3', [
												m(Input, {
													label: 'Notification Email',
													value: model.notificationEmail,
													type: 'email',
													oninput(value) {
														model.notificationEmail = value
													},
												}),
											]),
											m('.col-span-2', [
												m(Input, {
													label: 'Email Confirmation From',
													value: model.emailFrom,
													type: 'email',
													oninput(value) {
														model.emailFrom = value
													},
												}),
											]),
											m('.col-span-4', [
												m(Input, {
													label: 'Email Confirmation Subject',
													value: model.emailConfirmationSubject,
													type: 'text',
													oninput(value) {
														model.emailConfirmationSubject = value
													},
												}),
											]),
											m('.col-span-6', [
												m(TinyMce, {
													label: 'Email Confirmation',
													value: model.emailConfirmationBody,
													name: 'emailConfirmationBody',
													height: 600,
													onchange: (content) => {
														model.emailConfirmationBody = content
													},
												}),
											]),
										]),
								]
							),
						]
					),
			]
		},
	}
}
