import { request } from './request'

export const getRules = (params) => {
	return request({
		url: 'rules',
		method: 'GET',
		params: {
			pagesize: 1000,
			...params,
		},
	})
}
export const getRule = (id) => {
	return request({
		url: `rules/${id}`,
		method: 'GET',
	})
}

export const createRule = (rule) => {
	return request({
		url: 'rules',
		method: 'POST',
		body: rule,
	})
}

export const updateRule = (id, rule) => {
	return request({
		url: `rules/${id}`,
		method: 'PUT',
		body: rule,
	})
}

export const deleteRule = (id) => {
	return request({
		url: `rules/${id}`,
		method: 'DELETE',
	})
}

export const getRuleTypes = () => {
	return request({
		url: `rules/rule-types`,
		method: 'GET',
	})
}
