export const Checkbox = () => {
	return {
		view({ attrs: { label, value, checked, required, requiredWarning, onchange, table, classes = [] } }) {
			return m(
				'.checkbox.py-2.cursor-pointer',
				{
					class: [...classes],
					onclick(e) {
						onchange(value)
					},
				},
				[
					m(
						'div.flex',
						{
							class: table ? 'justify-center' : 'justify-between',
						},
						[
							m(
								'div',
								{
									class: table ? 'ml-2' : '',
								},
								[
									m('input.absolute', {
										type: 'checkbox',
										value,
										checked,
										required: required ? 'required' : null,
										onclick(e) {
											e.stopPropagation()
											onchange(value)
										},
									}),
									m(
										'label.cursor-pointer',
										{
											onclick(e) {
												e.stopPropagation()
												onchange(value)
											},
										},
										label
									),
								]
							),
							(required || requiredWarning) && m('span.text-xs.text-gray-500.uppercase', 'Required'),
						]
					),
				]
			)
		},
	}
}
