import { Breadcrumb, Table, Cell, CellBool, Row, LoadingFullScreen } from 'components'
import { getAdminEntryStatuses } from 'api/statuses'

export const EntryStatuses = () => {
	const root = '/entry-statuses'
	let entryStatuses

	getAdminEntryStatuses().then((data) => {
		entryStatuses = data
	})

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Entry Statuses',
						},
					],
				}),
				m('.p-4', { style: { height: 'calc(100% - 3rem)' } }, [
					m(
						'.h-full.shadow.overflow-hidden.border-b.border-gray-200.sm:rounded-lg',
						entryStatuses &&
							m(
								Table,
								{
									cols: [
										{
											label: 'Id',
											classes: ['w-24'],
										},
										{
											label: 'Name',
										},
										{
											label: 'Visible in Voting?',
										},
									],
								},
								[
									entryStatuses
										? entryStatuses.map((row) => {
												return m(
													Row,
													{
														clickable: true,
														onclick: (e) => {
															if (e.ctrlKey || e.metaKey) {
																window.open(`${root}/${row.id}`, '_blank')
															} else {
																m.route.set(`${root}/${row.id}`)
															}
														},
														bg: 'bg-white',
													},
													[m(Cell, row.id), m(Cell, row.name), m(CellBool, { val: row.isVisibleInVoting })]
												)
										  })
										: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
								]
							)
					),
				]),
			]
		},
	}
}
