import { Dialog2, LoadingFullScreen, Input, TextArea, Checkbox } from 'components'
import { notification } from 'utils/notifications'
import { DateTime } from 'luxon'

export const EditRoundDialog = ({ attrs: { loading, awardsYearId, round, awardsYear } }) => {
	loading = false

	const formData = {
		id: round.id,
		awardsYearId: awardsYearId,
		name: round.name,
		number: round.number,
		startDate: round.startDate,
		endDate: round.endDate,
		isViewingRound: round.isViewingRound,
		isNominationsRound: round.isNominationsRound,
		isJuryRound: round.isJuryRound,
		isHidden: round.isHidden,
		isShowFilmsOnDashboard: round.isShowFilmsOnDashboard,
		isVotingForNoneAllowed: round.isVotingForNoneAllowed,
		description: round.description,
		roundVotingCategories: round.roundVotingCategories,
	}
	return {
		view({ attrs: { loading, round, open, onsubmit } }) {
			return m(
				Dialog2,
				{
					open,
					save() {
						loading = true
						onsubmit(formData)
					},
					saving: loading,
					heading: 'Create Round',
					classes: ['md:max-w-2xl'],
					cancelLabel: 'Close',
				},
				[
					round
						? m('.flex.flex-col.gap-3', [
								m(Input, {
									label: 'Name',
									value: formData.name,
									required: true,
									type: 'text',
									oninput: (value) => {
										formData.name = value
									},
								}),
								m('.grid.grid-cols-3.gap-x-3', [
									m(Input, {
										label: 'Number',
										value: formData.number,
										required: true,
										type: 'number',
										oninput: (value) => {
											formData.number = value
										},
									}),
									m(Input, {
										label: 'Start',
										value: formData.startDate ? DateTime.fromISO(formData.startDate).toLocal().toISODate() : '',
										type: 'date',
										required: true,
										oninput: (value) => {
											formData.startDate = DateTime.fromISO(value).toUTC().toISO()
										},
									}),
									m(Input, {
										label: 'End',
										name: 'enddate',
										value: formData.endDate ? DateTime.fromISO(formData.endDate).toLocal().toISODate() : '',
										type: 'date',
										required: true,
										oninput: (value) => {
											formData.endDate = DateTime.fromISO(value).toUTC().toISO()
										},
									}),
								]),
								m('.grid.grid-cols-3.gap-x-3', [
									m('div', [
										m(Checkbox, {
											label: 'Viewing?',
											checked: formData.isViewingRound,
											onchange() {
												formData.isViewingRound = !formData.isViewingRound
											},
										}),
									]),
									m('div', [
										m(Checkbox, {
											label: 'Nominations?',
											checked: formData.isNominationsRound,
											onchange() {
												formData.isNominationsRound = !formData.isNominationsRound
											},
										}),
									]),
									m('div', [
										m(Checkbox, {
											label: 'Jury?',
											checked: formData.isJuryRound,
											onchange() {
												formData.isJuryRound = !formData.isJuryRound
											},
										}),
									]),
									m('div', [
										m(Checkbox, {
											label: 'Show films on dash?',
											checked: formData.isShowFilmsOnDashboard,
											onchange() {
												formData.isShowFilmsOnDashboard = !formData.isShowFilmsOnDashboard
											},
										}),
									]),
									m('div', [
										m(Checkbox, {
											label: 'Allow voting for none?',
											checked: formData.isVotingForNoneAllowed,
											onchange() {
												formData.isVotingForNoneAllowed = !formData.isVotingForNoneAllowed
											},
										}),
									]),
									m('div', [
										m(Checkbox, {
											label: 'Hidden?',
											checked: formData.isHidden,
											onchange() {
												formData.isHidden = !formData.isHidden
											},
										}),
									]),
								]),
								m('.grid.gap-x-3', [
									m(TextArea, {
										value: formData.description,
										label: 'Description',
										name: 'description',
										rows: 3,
										oninput: (content) => {
											formData.description = content
										},
									}),
								]),
						  ])
						: m(LoadingFullScreen),
				]
			)
		},
	}
}
