import {
	PageHeading,
	Button,
	LoadingFullScreen,
	Svg,
	SubmissionCheckStatusBadge,
	SupportingMaterialsStatusBadge,
	Input,
} from 'components'
import { Breadcrumb } from 'components/Breadcrumb'
import { DateTime } from 'luxon'
import { getSubmission, getExtension } from 'api/submissions'
import editIcon from 'assets/icons/solid/pencil-alt.svg'
import calIcon from 'assets/icons/outline/calendar.svg'
import userIcon from 'assets/icons/outline/user.svg'
import filmIcon from 'assets/icons/outline/film.svg'
import { getAnswerStringValue, formatCurrency } from 'utils'

export const ViewSubmission = ({ attrs: { id } }) => {
	let model

	const metaItems = []

	getSubmission(id).then((data) => {
		model = data
		if (data.form && data.form.name) {
			metaItems.push({
				icon: editIcon,
				label: data.form.name,
			})
		}
		metaItems.push({
			icon: calIcon,
			label: DateTime.fromISO(data.createdDate).toLocaleString(),
		})
		metaItems.push({
			icon: userIcon,
			label: `${data.createdByUser.fullName} <${data.createdByUser.email}>`,
		})
	})

	const extension = {}

	return {
		view({ attrs: { id } }) {
			return m('div', [
				m(Breadcrumb, {
					links: [
						{
							title: 'Submissions',
							href: '/submissions',
						},
						{
							title: model ? model.filmTitle : '...',
						},
					],
				}),
				model
					? m('.flex.flex-wrap.relative.h-screen.pb-24.overflow-x-hidden.content-start', [
							m('.p-4.w-full', [
								m('div.shadow.sm:rounded-md.divide-y.divide-gray-200', [
									m('div.px-4.py-5.bg-white.space-y-6.sm:p-6', [
										m(
											PageHeading,
											{
												heading: [
													model.filmTitle,
													m(
														'span.inline-flex.items-center.px-5/2.py-1/2.rounded-full.text-xs.font-medium',
														{
															class:
																model.submissionStatusId === 1
																	? 'bg-orange-200 text-orange-800'
																	: model.submissionStatusId === 2
																	? 'bg-blue-200 text-blue-800'
																	: model.submissionStatusId === 3
																	? 'bg-green-200 text-green-800'
																	: model.submissionStatusId === 8
																	? 'bg-red-200 text-red-800'
																	: 'bg-purple-200 text-purple-800',
														},
														model.submissionStatus
													),
												],
												description: model.description,
												metaItems,
												actions: [
													{
														icon: editIcon,
														label: 'Edit Submission',
														action: () => {
															m.route.set(`/submissions/${id}/edit`)
														},
													},
													{
														icon: filmIcon,
														label: model.createdFilmId ? 'View Film' : 'Submission to Entries',
														action: () => {
															m.route.set(
																model.createdFilmId ? `/films/${model.createdFilmId}` : `/submissions/${id}/entries`
															)
														},
													},
												],
											},
											m('.grid.grid-cols-5.gap-2.text-sm.mt-2', [
												model.britishnessCheckStatus &&
													m('.flex.flex-col.shadow.bg-gray-50.px-2.py-1', [
														m('.font-bold', 'Britishness'),
														m(
															'div',
															m(SubmissionCheckStatusBadge, { status: model.britishnessCheckStatus, size: 'sm' })
														),
														m('.text-xs.whitespace-pre-line.break-words', model.britishnessNotes),
													]),
												model.categoriesCheckStatus &&
													m('.flex.flex-col.shadow.bg-gray-50.px-2.py-1', [
														m('.font-bold', 'Categories Check'),
														m(
															'div',
															m(SubmissionCheckStatusBadge, { status: model.categoriesCheckStatus, size: 'sm' })
														),
														m('.text-xs.whitespace-pre-line.break-words', model.categoriesNotes),
													]),
												model.independenceCheckStatus &&
													m('.flex.flex-col.shadow.bg-gray-50.px-2.py-1', [
														m('.font-bold', 'Independence'),
														m(
															'div',
															m(SubmissionCheckStatusBadge, { status: model.independenceCheckStatus, size: 'sm' })
														),
														m('.text-xs.whitespace-pre-line.break-words', model.independenceNotes),
													]),
												model.releaseCheckStatus &&
													m('.flex.flex-col.shadow.bg-gray-50.px-2.py-1', [
														m('.font-bold', 'Release'),
														m('div', m(SubmissionCheckStatusBadge, { status: model.releaseCheckStatus, size: 'sm' })),
														m('.text-xs.whitespace-pre-line.break-words', model.releaseNotes),
													]),
												model.supportingMaterialsStatus &&
													m('.flex.flex-col.shadow.bg-gray-50.px-2.py-1', [
														m('.font-bold', 'Supporting Materials'),
														m(
															'div',
															m(SupportingMaterialsStatusBadge, { status: model.supportingMaterialsStatus, size: 'sm' })
														),
														m('.text-xs.whitespace-pre-line.break-words', model.supportingMaterialsNotes),
													]),
											]),
											model.payments &&
												m('.flex.flex-col.shadow.bg-gray-50.px-2.py-1.col-span-2', [
													m('.font-bold', 'Payments'),
													model.payments.map((payment) => {
														return [
															m('.text-sm.bg-gray-50.text-gray-800.flex.gap-4', [
																m('span', DateTime.fromISO(payment.createdDate).toLocaleString()),
																m('span', formatCurrency(payment.amount)),
															]),
															m('.text-sm.bg-gray-50.text-gray-800', payment.reference),
														]
													}),
												]),
											model.createdFilmId &&
												m('.flex.flex-col.shadow.bg-gray-50.px-2.py-1.col-span-2', [
													m('.font-bold', 'Film'),
													m(
														Button,
														{ type: 'button', classes: ['w-32'], href: `/films/${model.createdFilmId}` },
														'View Film'
													),
												]),
											model.submissionStatusId === 1 &&
												m('.flex.items-end.gap-3.mt-3', [
													m(Input, {
														type: 'date',
														value: extension.date,
														label: 'Extension Date',
														oninput: (value) => {
															extension.date = value + 'T00:00:00Z'
														},
													}),
													m(
														Button,
														{
															type: 'button',
															size: 'sm',
															onclick() {
																if (extension.date) {
																	getExtension({
																		userId: model.createdByUser.id,
																		extension: extension.date,
																		id: model.id,
																		formId: model.form.id,
																	}).then((url) => {
																		extension.url = url
																	})
																} else {
																	alert('Need a date')
																}
															},
														},
														'Generate Extension Link'
													),
												]),
											extension.url && m('p.text-xs', 'https://members.bifa.film' + extension.url)
										),
									]),
									m('div.bg-gray-50.px-4.py-5.sm:p-6', [
										m('.grid', [
											m('.grid.grid-cols-2.gap-1', [
												model.answers.map((answer) => {
													if (answer.value) {
														return [
															m('.text-sm.px-2.py-1.bg-gray-50.text-gray-800', answer.questionName),
															m('.text-sm.px-2.py-1.bg-white', getAnswerStringValue(answer)),
														]
													} else if (answer.questionTypeId == 8) {
														return [
															m(
																'.text-sm.px-2.py-1.bg-gray-100.font-bold.text-gray-800.col-span-2',
																answer.questionName
															),
														]
													}
												}),
											]),
										]),
									]),
								]),
							]),
					  ])
					: m(LoadingFullScreen),
			])
		},
	}
}
