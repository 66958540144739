import { Button, Svg, ActionButton } from 'components'
import { DateTime } from 'luxon'

const MetaItem = () => {
	return {
		view({ attrs: { label, icon, href } }) {
			return href
				? m('a.mt-2.flex.items-center.text-sm.text-gray-800', { href: href, target: '_blank' }, [
						icon && m(Svg, { classes: ['w-5', 'h-5', 'mr-2', 'text-gray-400', 'flex-shrink-0'] }, icon),
						m('span.text-gray-600', label),
				  ])
				: m('div.mt-2.flex.items-center.text-sm.text-gray-800', [
						icon && m(Svg, { classes: ['w-5', 'h-5', 'mr-2', 'text-gray-400', 'flex-shrink-0'] }, icon),
						m('span.text-gray-600', label),
				  ])
		},
	}
}

const Action = () => {
	return {
		view({ attrs: { label, icon, action } }) {
			return m(
				'span.sm:ml-3',
				m(
					Button,
					{
						size: 'md',
						classes: ['my-2', 'whitespace-nowrap', 'text-white'],
						type: 'button',
						onclick: action,
					},
					[
						icon &&
							m(
								Svg,
								{
									classes: ['-ml-1', 'w-5', 'h-5', 'mr-2'],
								},
								[icon]
							),
						label,
					]
				)
			)
		},
	}
}

export const PageHeading = () => {
	return {
		view({ children, attrs: { heading, subtitle, metaItems, actions, timestamps, description } }) {
			return m('div', [
				m('.lg:flex.lg:justify-between', [
					m('.flex-1.min-w-0', [
						m(
							'h2.text-2xl.font-bold.leading-7.text-gray-900.sm:text-3xl.sm:truncate.flex.items-center.gap-x-4',
							heading
						),
						subtitle &&
							m(
								'h4.flex.gap-x-2.items-center.text-lg.font-normal.leading-5.text-gray-500.sm:text-lg.sm:truncate',
								subtitle
							),
						description && m('p.text-sm.font-normal.text-gray-500', m.trust(description)),
						metaItems &&
							m('.mt-1.flex.flex-col.sm:flex-row.sm:flex-wrap.sm:mt-0.sm:gap-x-6', [
								metaItems.map((mi) => {
									return m(MetaItem, { ...mi })
								}),
							]),
					]),
					m('.mt-5.flex.lg:mt-0.lg:ml-4.flex-col.justify-between.items-end', [
						actions &&
							actions.length === 1 &&
							m('div', [
								actions.map((a) => {
									return m(Action, { ...a })
								}),
							]),
						actions && actions.length > 1 && m('div', m(ActionButton, { actions })),
						timestamps && timestamps.length > 0
							? m('.grid.grid-cols-2.text-xs.text-gray-400', [
									timestamps.map((a) => {
										return [
											m('p', a.title),
											m('p.text-right', DateTime.fromISO(a.value).toLocaleString(DateTime.DATETIME_MED)),
										]
									}),
							  ])
							: null,
					]),
				]),
				[children],
			])
		},
	}
}
