import { Breadcrumb, Button, Table, Svg, Pagination, Row, Cell, CellBool, LoadingFullScreen, Tabs } from 'components'
import { ImportVotersDialog } from './ImportVotersDialog'
import { ImportResponseDialog } from './ImportResponseDialog'
import { DateTime } from 'luxon'

import { getUsers, importVoters, exportUsers } from 'api/users'
import { notification } from 'utils/notifications'

import addIcon from 'assets/icons/outline/plus.svg'
import chevronUp from 'assets/icons/outline/chevron-double-up.svg'
import chevronDown from 'assets/icons/outline/chevron-double-down.svg'

export const Users = ({ attrs: { search, p } }) => {
	const root = '/users'

	let searchString = search || ''
	let sortBy = 'name'
	let sortOrder = 'asc'
	let list
	let meta
	let page = p || 1
	let perPage
	let role

	const loadData = () => {
		list = null
		var pageUrl = `${window.location.pathname}?p=${page}&search=${searchString}`
		window.history.pushState('', '', pageUrl)
		getUsers({
			search: searchString,
			page,
			pagesize: perPage,
			sortBy,
			sortOrder,
			role,
		}).then((res) => {
			list = res.data
			meta = res.meta
		})
	}

	let savingImportVoters
	let importResponseData
	const importVotersOpen = flyd.stream(false)
	const importResponseOpen = flyd.stream(false)

	const onImportVoters = (form) => {
		if (form.file) {
			savingImportVoters = true
			importVoters(form.file)
				.then((msg) => {
					importResponseData = msg
					importVotersOpen(false)
					savingImportVoters = false
					importResponseOpen(true)

					getUsers(filters).then((data) => {
						users = data.data
						meta = data.meta
					})
				})
				.catch((err) => {
					console.log(err)
					notification.error({ title: 'Error - unable to import' })
					savingImportVoters = false
				})
		} else {
			alert('File must be selected')
		}
	}

	let exportingUsers
	const onExportUsers = () => {
		exportUsers(searchString, role).then((res) => {
			exportingUsers = false
		})
	}

	const toggleSortOrder = (colName) => {
		sortOrder = sortBy === colName ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc'
		sortBy = colName
		page = 1
		loadData()
	}

	const cols = [
		{
			label: 'Name',
			sortBy: 'name',
			onclick(e) {
				toggleSortOrder('name')
			},
		},
		{ label: 'Email' },
		{ label: 'Company' },
		{
			label: 'Voter',
			align: 'center',
			classes: ['w-32'],
			filter: 'Voter',
			onclick() {
				if (role === 'Voter') role = null
				else role = 'Voter'
				loadData()
			},
		},
		{
			label: 'Entrant',
			align: 'center',
			classes: ['w-32'],
			filter: 'Entrant',
			onclick() {
				if (role === 'Entrant') role = null
				else role = 'Entrant'
				loadData()
			},
		},
		{
			label: 'Voting Admin',
			align: 'center',
			classes: ['w-32'],
			filter: 'VotingAdministrator',
			onclick() {
				if (role === 'VotingAdministrator') role = null
				else role = 'VotingAdministrator'
				loadData()
			},
		},
		{
			label: 'Admin',
			align: 'center',
			classes: ['w-32'],
			filter: 'Administrator',
			onclick() {
				if (role === 'Administrator') role = null
				else role = 'Administrator'
				loadData()
			},
		},
		{ label: 'Date Added' },
	]

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Users',
						},
					],
					buttons: [
						m(
							Button,
							{
								size: 'sm',
								classes: ['my-2', 'mx-auto', 'whitespace-nowrap', 'text-white'],
								type: 'button',
								onclick: () => {
									m.route.set('/users/create')
								},
							},
							[
								m(
									Svg,
									{
										classes: ['w-4', 'mx-auto'],
									},
									[addIcon]
								),
								'Add Admin User',
							]
						),
					],
				}),
				m(
					Table,
					{
						cols: cols,
						oncreate({ dom }) {
							perPage = Math.floor((dom.clientHeight - 127) / 33)
							loadData()
						},
						searchRow: true,
						searchString: searchString,
						onsearchinput: (newSearch) => {
							searchString = newSearch
						},
						onsearch: () => {
							page = 1
							loadData()
						},
						sortOrder,
						sortBy,
						filter: role,
					},
					[
						list
							? list.map((row) => {
									return m(
										Row,
										{
											clickable: true,
											onclick: (e) => {
												if (e.ctrlKey || e.metaKey) {
													window.open(`${root}/${row.id}`, '_blank')
												} else {
													m.route.set(`${root}/${row.id}`)
												}
											},
											bg: row.isLive ? 'bg-green-50' : 'bg-white',
										},
										[
											m(Cell, `${row.firstName} ${row.lastName}`),
											m(Cell, row.email),
											m(Cell, row.company),
											m(CellBool, { val: row.roles.includes('Voter') }),
											m(CellBool, { val: row.roles.includes('Entrant') }),
											m(CellBool, {
												val: row.roles.includes('VotingAdministrator'),
											}),
											m(CellBool, {
												val: row.roles.includes('Administrator'),
											}),
											m(Cell, DateTime.fromISO(row.createdDate).toLocaleString()),
										]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
				m(
					'div',
					meta &&
						m(Pagination, {
							meta: meta,
							onrefresh: () => {
								loadData()
							},
							onpage: (newPage) => {
								page = newPage
								loadData()
							},

							buttons: [
								m('.flex.justify-end.gap-x-4', [
									m(
										Button,
										{
											size: 'sm',
											classes: ['my-2', 'whitespace-nowrap', 'text-white'],
											type: 'button',
											onclick: () => {
												importVotersOpen(true)
											},
										},
										[
											m(
												Svg,
												{
													classes: ['w-4', 'mx-auto'],
												},
												[chevronUp]
											),
											'Import Voters',
										]
									),
									m(
										Button,
										{
											size: 'sm',
											classes: ['my-2', 'whitespace-nowrap', 'text-white'],
											type: 'button',
											loading: exportingUsers,
											onclick: () => {
												exportingUsers = true
												onExportUsers()
											},
										},
										[
											m(
												Svg,
												{
													classes: ['w-4', 'mx-auto'],
												},
												[chevronDown]
											),
											'Export Users',
										]
									),
								]),
							],
						})
				),
				importVotersOpen() &&
					m(ImportVotersDialog, {
						open: importVotersOpen,
						onsubmit: onImportVoters,
						loading: savingImportVoters,
					}),
				importResponseOpen() &&
					m(ImportResponseDialog, {
						open: importResponseOpen,
						heading: 'Import Voters',
						importResponse: importResponseData,
						loading: savingImportVoters,
					}),
			]
		},
	}
}
