import { Layout } from 'components/Layout'
import { Login } from 'pages/Login'
import { ForgottenPassword } from 'pages/ForgottenPassword'
import { ResetPassword } from 'pages/ResetPassword'
import { SetPassword } from 'pages/SetPassword'
import { Settings } from 'pages/Settings'
import { EmailTemplates } from 'pages/EmailTemplates'
import { EditEmailTemplate } from 'pages/EmailTemplates/EditEmailTemplate'
import { Users } from 'pages/Users'
import { ViewUser } from 'pages/Users/ViewUser'
import { CreateUser } from 'pages/Users/CreateUser'
import { Logout } from 'pages/Logout'
import { Emails } from 'pages/Emails'
import { Dashboard } from 'pages/Dashboard'
import { AwardsYears } from 'pages/AwardsYears'
import { EditAwardsYear } from 'pages/AwardsYears/EditAwardsYear'
import { ViewAwardsYear } from 'pages/AwardsYears/ViewAwardsYear'
import { EditAwardsYearUser } from 'pages/AwardsYears/EditAwardsYearUser'
import { EditAwardsYearRound } from 'pages/AwardsYears/EditAwardsYearRound'
import { Forms } from 'pages/Forms'
import { EditForm } from 'pages/Forms/EditForm'
import { ViewForm } from 'pages/Forms/ViewForm'
import { Films } from 'pages/Films'
import { EditFilm } from 'pages/Films/EditFilm'
import { ViewFilm } from 'pages/Films/ViewFilm'
import { EditEntry } from 'pages/Films/EditEntry'
import { EntryFees } from 'pages/EntryFees'
import { EditEntryFee } from './pages/EntryFees/EditEntryFee'
import { Submissions } from 'pages/Submissions'
import { ViewSubmission } from 'pages/Submissions/View'
import { EditSubmission } from 'pages/Submissions/Edit'
import { SubmissionToEntries } from 'pages/Submissions/SubmissionToEntries'
import { SubmissionStatuses } from 'pages/SubmissionStatuses'
import { EditSubmissionStatus } from 'pages/SubmissionStatuses/EditSubmissionStatus'
import { Rules } from 'pages/Rules'
import { EditRule } from './pages/Rules/EditRule'
import { People } from './pages/People'
import { EditPerson } from './pages/People/EditPerson'
import { EntryStatuses } from './pages/EntryStatuses'
import { EditEntryStatus } from './pages/EntryStatuses/EditEntryStatus'

m.route.prefix = ''

const Routes = {
	'/login': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
					allowDelegate: true,
				},
				[m(Login, vnode.attrs)]
			)
		},
	},
	'/forgotten-password': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
				},
				[m(ForgottenPassword, vnode.attrs)]
			)
		},
	},
	'/reset-password': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
				},
				[m(ResetPassword, vnode.attrs)]
			)
		},
	},
	'/set-password': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
				},
				[m(SetPassword, vnode.attrs)]
			)
		},
	},
	'/': {
		render(vnode) {
			return m(
				Layout,
				m(Dashboard, {
					...vnode.attrs,
				})
			)
		},
	},
	'/emails': {
		render(vnode) {
			return m(
				Layout,
				m(Emails, {
					...vnode.attrs,
				})
			)
		},
	},
	'/entry-fees': {
		render(vnode) {
			return m(
				Layout,
				m(EntryFees, {
					...vnode.attrs,
				})
			)
		},
	},
	'/entry-fees/create': {
		render(vnode) {
			return m(
				Layout,
				m(EditEntryFee, {
					key: 0,
					...vnode.attrs,
				})
			)
		},
	},
	'/entry-fees/:id': {
		render(vnode) {
			return m(
				Layout,
				m(EditEntryFee, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/rules': {
		render(vnode) {
			return m(
				Layout,
				m(Rules, {
					...vnode.attrs,
				})
			)
		},
	},
	'/rules/create': {
		render(vnode) {
			return m(
				Layout,
				m(EditRule, {
					key: 0,
					...vnode.attrs,
				})
			)
		},
	},
	'/rules/:id': {
		render(vnode) {
			return m(
				Layout,
				m(EditRule, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/email-templates': {
		render(vnode) {
			return m(
				Layout,
				m(EmailTemplates, {
					...vnode.attrs,
				})
			)
		},
	},
	'/email-templates/create': {
		render(vnode) {
			return m(
				Layout,
				m(EditEmailTemplate, {
					key: 0,
					...vnode.attrs,
				})
			)
		},
	},
	'/email-templates/:id': {
		render(vnode) {
			return m(
				Layout,
				m(EditEmailTemplate, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/users': {
		render(vnode) {
			return m(
				Layout,
				m(Users, {
					...vnode.attrs,
				})
			)
		},
	},
	'/users/create': {
		render(vnode) {
			return m(
				Layout,
				m(CreateUser, {
					...vnode.attrs,
				})
			)
		},
	},
	'/users/:id': {
		render(vnode) {
			return m(
				Layout,
				m(ViewUser, {
					...vnode.attrs,
				})
			)
		},
	},
	'/awardsyears': {
		render(vnode) {
			return m(
				Layout,
				m(AwardsYears, {
					...vnode.attrs,
				})
			)
		},
	},
	'/awardsyears/create': {
		render(vnode) {
			return m(
				Layout,
				m(EditAwardsYear, {
					key: 0,
					...vnode.attrs,
				})
			)
		},
	},
	'/awardsyears/:id': {
		render(vnode) {
			return m(
				Layout,
				m(ViewAwardsYear, {
					key: vnode.attrs.tab,
					...vnode.attrs,
				})
			)
		},
	},
	'/awardsyears/:id/edit': {
		render(vnode) {
			return m(
				Layout,
				m(EditAwardsYear, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/awardsyears/:id/voters/:awardsYearUserId': {
		render(vnode) {
			return m(
				Layout,
				m(EditAwardsYearUser, {
					key: vnode.attrs.awardsYearUserId,
					...vnode.attrs,
				})
			)
		},
	},
	'/awardsyears/:id/rounds/:roundId': {
		render(vnode) {
			return m(
				Layout,
				m(EditAwardsYearRound, {
					key: vnode.attrs.roundId,
					...vnode.attrs,
				})
			)
		},
	},
	'/forms': {
		render(vnode) {
			return m(
				Layout,
				m(Forms, {
					...vnode.attrs,
				})
			)
		},
	},
	'/forms/create': {
		render(vnode) {
			return m(
				Layout,
				m(EditForm, {
					key: 0,
					...vnode.attrs,
				})
			)
		},
	},
	'/forms/:id': {
		render(vnode) {
			return m(
				Layout,
				m(ViewForm, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/forms/:id/edit': {
		render(vnode) {
			return m(
				Layout,
				m(EditForm, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/films': {
		render(vnode) {
			return m(
				Layout,
				m(Films, {
					...vnode.attrs,
				})
			)
		},
	},
	'/films/:id/edit': {
		render(vnode) {
			return m(
				Layout,
				m(EditFilm, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/films/:id': {
		render(vnode) {
			return m(
				Layout,
				m(ViewFilm, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/films/:filmId/entries/:entryId': {
		render(vnode) {
			return m(
				Layout,
				m(EditEntry, {
					key: vnode.attrs.entryId,
					...vnode.attrs,
				})
			)
		},
	},
	'/submissions': {
		render(vnode) {
			return m(
				Layout,
				m(Submissions, {
					...vnode.attrs,
				})
			)
		},
	},
	'/submissions/:id': {
		render(vnode) {
			return m(
				Layout,
				m(ViewSubmission, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/submissions/:id/edit': {
		render(vnode) {
			return m(
				Layout,
				m(EditSubmission, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/submissions/:id/entries': {
		render(vnode) {
			return m(
				Layout,
				m(SubmissionToEntries, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/submission-statuses': {
		render(vnode) {
			return m(
				Layout,
				m(SubmissionStatuses, {
					...vnode.attrs,
				})
			)
		},
	},
	'/submission-statuses/:id': {
		render(vnode) {
			return m(
				Layout,
				m(EditSubmissionStatus, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/entry-statuses': {
		render(vnode) {
			return m(
				Layout,
				m(EntryStatuses, {
					...vnode.attrs,
				})
			)
		},
	},
	'/entry-statuses/:id': {
		render(vnode) {
			return m(
				Layout,
				m(EditEntryStatus, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/people': {
		render(vnode) {
			return m(
				Layout,
				m(People, {
					...vnode.attrs,
				})
			)
		},
	},
	'/people/:id': {
		render(vnode) {
			return m(
				Layout,
				m(EditPerson, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/settings': {
		render(vnode) {
			return m(
				Layout,
				m(Settings, {
					...vnode.attrs,
				})
			)
		},
	},
	'/logout': Logout,
}

const DefaultRoute = '/'

export { Routes, DefaultRoute }
