import {
	Breadcrumb,
	Select,
	TextArea,
	TinyMce,
	Input,
	Checkbox,
	EditModelForm,
	SplitScreenEditFormSection,
} from 'components'
import {
	getSubmission,
	updateSubmission,
	getSubmissionStatus,
	getSubmissionStatusSummary,
	getSubmissionCheckStatuses,
	getSupportingMaterialsStatuses,
} from 'api/submissions'
import { notification } from 'utils/notifications'
import { getAnswerStringValue, formatCurrency } from 'utils'
import { DateTime } from 'luxon'

export const EditSubmission = ({ attrs: { id } }) => {
	let ready
	let model
	let origStatus

	getSubmission(id).then((data) => {
		model = data
		ready = true
		origStatus = data.submissionStatusId
	})

	let submissionStatuses
	let submissionCheckStatuses
	let supportingMaterialsStatuses

	getSubmissionStatusSummary().then((data) => {
		submissionStatuses = data.map((x) => {
			return { label: x.name, value: x.id }
		})
	})
	getSubmissionCheckStatuses().then((data) => {
		submissionCheckStatuses = data.map((x) => {
			return { label: x.name, value: x.id }
		})
	})
	getSupportingMaterialsStatuses().then((data) => {
		supportingMaterialsStatuses = data.map((x) => {
			return { label: x.name, value: x.id }
		})
	})

	let showEmail = false
	const update = (e) => {
		e.preventDefault()
		if (id) {
			let updateModel = {
				id,
				submissionStatusId: model.submissionStatusId,
				emailMessage: model.emailMessage,
				IsSuppressEmail: model.IsSuppressEmail,
				britishnessCheckStatusId: model.britishnessCheckStatusId,
				britishnessNotes: model.britishnessNotes,
				categoriesCheckStatusId: model.categoriesCheckStatusId,
				categoriesNotes: model.categoriesNotes,
				releaseCheckStatusId: model.releaseCheckStatusId,
				releaseNotes: model.releaseNotes,
				independenceCheckStatusId: model.independenceCheckStatusId,
				independenceNotes: model.independenceNotes,
				supportingMaterialsStatusId: model.supportingMaterialsStatusId,
				supportingMaterialsNotes: model.supportingMaterialsNotes,
			}
			updateSubmission(model.id, updateModel)
				.then(() => {
					notification.success({ title: 'Submission updated' })
					m.route.set(`/submissions/${model.id}`)
				})
				.catch((err) => {
					if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
						Sentry.captureException(err)
					}
					notification.error({ title: err.response })
				})
		}
	}
	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Submissions',
							href: '/submissions',
						},
						{
							title: model ? model.filmTitle : '',
							href: `/submissions/${id}`,
						},
						{
							title: 'Edit Submission',
						},
					],
				}),

				ready &&
					m(
						EditModelForm,
						{
							onsubmit: update,
						},
						[
							m(
								SplitScreenEditFormSection,
								{
									heading: 'Edit Submission',
									description: '',
									extra: m('.grid', [
										m('.grid.grid-cols-2.gap-1', [
											model.answers.map((answer) => {
												if (answer.value) {
													return [
														m('.text-sm.px-2.py-1.bg-gray-50.text-gray-800', answer.questionName),
														m('.text-sm.px-2.py-1.bg-white', getAnswerStringValue(answer)),
													]
												} else if (answer.questionTypeId == 8) {
													return [
														m('.text-sm.px-2.py-1.bg-gray-100.font-bold.text-gray-800.col-span-2', answer.questionName),
													]
												}
											}),
											model.payments &&
												m('.text-sm.px-2.py-1.bg-gray-100.font-bold.text-gray-800.col-span-2', 'Payments'),
											model.payments &&
												model.payments.map((payment) => {
													return [
														m('.text-sm.px-2.py-1.bg-gray-50.text-gray-800.col-span-2.flex.gap-4', [
															m('span', DateTime.fromISO(payment.createdDate).toLocaleString()),
															m('span', payment.reference),

															m('span', formatCurrency(payment.amount)),
														]),
													]
												}),
										]),
									]),
									back: `/submissions/${model.id}`,
								},
								[
									ready &&
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-6', [
												submissionStatuses &&
													m(Select, {
														label: 'Submission Status',
														value: model.submissionStatusId,
														required: true,
														removeItemButton: false,
														choices: submissionStatuses,
														onchange(val) {
															model.submissionStatusId = parseInt(val)
															if (origStatus !== model.submissionStatusId) {
																getSubmissionStatus(model.submissionStatusId).then((x) => {
																	model.emailMessage = x.emailTemplate
																		? x.emailTemplate.replaceAll('##FILM##', model.filmTitle)
																		: ''
																	showEmail = true
																})
															} else {
																model.emailMessage = ''
																showEmail = false
															}
														},
													}),
												showEmail &&
													m('.flex', [
														m('.text-sm.px-2.py-1.bg-gray-50.text-gray-800', 'To:'),
														m('.text-sm.px-2.py-1.bg-white', model.contacts.primaryEmail),
														model.contacts.isSecondary &&
															m('.text-sm.px-2.py-1.bg-white', model.contacts.secondaryEmail),
													]),
												showEmail &&
													m(TinyMce, {
														label: '',
														value: model.emailMessage,
														name: 'emailMessage',
														height: 400,
														onchange: (content) => {
															model.emailMessage = content
														},
													}),
												showEmail &&
													m(Checkbox, {
														label: 'Suppress Email?',
														checked: model.IsSuppressEmail,
														onchange() {
															model.IsSuppressEmail = !model.IsSuppressEmail
														},
													}),
											]),
											m('.col-span-3', [
												submissionCheckStatuses &&
													m(Select, {
														label: 'Britishness Check Status',
														value: model.britishnessCheckStatusId,
														required: false,
														choices: submissionCheckStatuses,
														onchange(val) {
															model.britishnessCheckStatusId = parseInt(val)
														},
													}),
												m(TextArea, {
													label: 'Britishness Notes',
													value: model.britishnessNotes,
													rows: 4,
													oninput(val) {
														model.britishnessNotes = val
													},
												}),
											]),
											m('.col-span-3', [
												submissionCheckStatuses &&
													m(Select, {
														label: 'Categories Check Status',
														value: model.categoriesCheckStatusId,
														required: false,
														choices: submissionCheckStatuses,
														onchange(val) {
															model.categoriesCheckStatusId = parseInt(val)
														},
													}),
												m(TextArea, {
													label: 'Categories Notes',
													value: model.categoriesNotes,
													rows: 4,
													oninput(val) {
														model.categoriesNotes = val
													},
												}),
											]),
											m('.col-span-3', [
												submissionCheckStatuses &&
													m(Select, {
														label: 'Independence Check Status',
														value: model.independenceCheckStatusId,
														required: false,
														choices: submissionCheckStatuses,
														onchange(val) {
															model.independenceCheckStatusId = parseInt(val)
														},
													}),
												m(TextArea, {
													label: 'Independence Notes',
													value: model.independenceNotes,
													rows: 4,
													oninput(val) {
														model.independenceNotes = val
													},
												}),
											]),
											m('.col-span-3', [
												submissionCheckStatuses &&
													m(Select, {
														label: 'Release Check Status',
														value: model.releaseCheckStatusId,
														required: false,
														choices: submissionCheckStatuses,
														onchange(val) {
															model.releaseCheckStatusId = parseInt(val)
														},
													}),
												m(TextArea, {
													label: 'Release Notes',
													value: model.releaseNotes,
													rows: 4,
													oninput(val) {
														model.releaseNotes = val
													},
												}),
											]),
											m('.col-span-6', [
												supportingMaterialsStatuses &&
													m(Select, {
														label: 'Supporting Materials Status',
														value: model.supportingMaterialsStatusId,
														required: false,
														choices: supportingMaterialsStatuses,
														onchange(val) {
															model.supportingMaterialsStatusId = parseInt(val)
														},
													}),
												m(TextArea, {
													label: 'Supporting Materials Notes',
													value: model.supportingMaterialsNotes,
													rows: 3,
													oninput(val) {
														model.supportingMaterialsNotes = val
													},
												}),
											]),
										]),
								]
							),
						]
					),
			]
		},
	}
}
