import { Table, Row, Cell, Button, Svg, LoadingFullScreen, Pagination } from 'components'

import addIcon from 'assets/icons/outline/plus.svg'

export const EntriesTab = ({ attrs: { id } }) => {
	const root = `/films/${id}/entries`
	const cols = [
		{ label: 'Id' },
		{ label: 'Category' },
		{ label: 'People' },
		{ label: 'Entry Status', align: 'center' },
		{ label: 'Viewing Status', align: 'center' },
	]

	return {
		view({ attrs: { id, entries } }) {
			return m('.h-auto', [
				m(
					Table,
					{
						cols: cols,
					},
					[
						entries
							? entries.map((row) => {
									return m(
										Row,
										{
											clickable: true,
											onclick: (e) => {
												if (e.ctrlKey || e.metaKey) {
													window.open(`${root}/${row.id}`, '_blank')
												} else {
													m.route.set(`${root}/${row.id}`)
												}
											},
											bg: 'bg-white',
										},
										[
											m(Cell, row.id),
											m(Cell, row.votingCategoryName),
											m(Cell, row.people),
											m(Cell, { align: 'center' }, row.entryStatusName),
											m(Cell, { align: 'center' }, row.viewerStatusName),
										]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
				m(Pagination, {
					meta: {
						currentPage: 1,
						itemsPerPage: entries.length,
						totalItems: entries.length,
						totalPages: 1,
					},
					onrefresh: () => {
						loadData()
					},
					onpage: (newPage) => {
						page = newPage
						loadData()
					},
					buttons: [
						m('.flex.justify-end.gap-x-4', [
							m(
								Button,
								{
									size: 'sm',
									classes: ['my-2', 'whitespace-nowrap', 'text-white'],
									type: 'button',
									onclick: () => {
										m.route.set(`${root}/0`)
									},
								},
								[
									m(
										Svg,
										{
											classes: ['w-4', 'mx-auto'],
										},
										[addIcon]
									),
									'Add entry',
								]
							),
						]),
					],
				}),
			])
		},
	}
}
