import { Dialog2, LoadingFullScreen, Input, TextArea, Checkbox } from 'components'

export const EditSubgroupDialog = ({ attrs: { loading, awardsYearId, subgroup } }) => {
	loading = false

	const formData = {
		id: subgroup.id,
		awardsYearId: awardsYearId,
		name: subgroup.name,
		isHidden: subgroup.isHidden,
		isSubTeams: subgroup.isSubTeams,
		meetings: subgroup.meetings,
		description: subgroup.description,
	}

	return {
		view({ attrs: { loading, subgroup, open, onsubmit } }) {
			return m(
				Dialog2,
				{
					open,
					save() {
						loading = true
						onsubmit(formData)
					},
					saving: loading,
					heading: 'Edit Subgroup',
					classes: ['md:max-w-2xl'],
					cancelLabel: 'Close',
				},
				[
					subgroup
						? m('.flex.flex-col.gap-3', [
								m(Input, {
									label: 'Name',
									value: formData.name,
									type: 'text',
									required: true,
									oninput(value) {
										formData.name = value
									},
								}),
								m('.grid.grid-cols-2.gap-x-3', [
									m('div', [
										m(Checkbox, {
											label: 'Sub Teams?',
											checked: formData.isSubTeams,
											onchange() {
												formData.isSubTeams = !formData.isSubTeams
											},
										}),
									]),
									m('div', [
										m(Checkbox, {
											label: 'Hidden?',
											checked: formData.isHidden,
											onchange() {
												formData.isHidden = !formData.isHidden
											},
										}),
									]),
								]),
								m('.grid.grid-cols-2.gap-x-3', [
									m(TextArea, {
										value: formData.description,
										label: 'Description',
										name: 'description',
										rows: 6,
										oninput: (content) => {
											formData.description = content
										},
									}),
									m(TextArea, {
										value: formData.meetings,
										label: 'Meetings',
										name: 'meetings',
										rows: 6,
										oninput: (content) => {
											formData.meetings = content
										},
									}),
								]),
						  ])
						: m(LoadingFullScreen),
				]
			)
		},
	}
}
