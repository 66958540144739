import { Breadcrumb, Select, TextArea, Card, Input, Tooltip, EditModelForm, Button } from 'components'
import { getSubmission, submissionToEntries } from 'api/submissions'
import { searchPersons } from 'api/persons'
import { notification } from 'utils/notifications'
import { getAnswerStringValue, formatCurrency } from 'utils'
import { DateTime } from 'luxon'
import tickIcon from 'assets/icons/outline/check.svg'

export const SubmissionToEntries = ({ attrs: { id } }) => {
	let ready
	let model
	let film = {}
	let loading
	let categories

	getSubmission(id).then((data) => {
		model = data

		film.title = data.filmTitle
		film.year = 2024
		film.runningTime = parseInt(
			getAnswerStringValue(
				data.answers.find((x) => {
					return x.questionName === 'Running time'
				})
			)
		)
		film.description = getAnswerStringValue(
			data.answers.find((x) => {
				return x.questionName === 'Synopsis'
			})
		)
		film.budget = getAnswerStringValue(
			data.answers.find((x) => {
				return x.questionTypeId === 15
			})
		)
		film.trailer = getAnswerStringValue(
			data.answers.find((x) => {
				return x.questionName === 'Trailer link'
			})
		)
		film.posterUrl =
			data.answers.find((x) => {
				return x.questionName === 'Poster artwork'
			}) &&
			data.answers.find((x) => {
				return x.questionName === 'Poster artwork'
			}).value
				? JSON.parse(
						data.answers.find((x) => {
							return x.questionName === 'Poster artwork'
						}).value
				  )[0]
				: ''
		film.screeningInfo = getAnswerStringValue(
			data.answers.find((x) => {
				return x.questionName === 'Please tell us how our voters will be able to see the film'
			})
		)
		film.screenerDates = getAnswerStringValue(
			data.answers.find((x) => {
				return x.questionName === 'Please tell us when this screener will be available for voters to view?'
			})
		)
		film.screenerRestrictions = getAnswerStringValue(
			data.answers.find((x) => {
				return x.questionName.indexOf('Please list any screener restrictions voters should be aware of') === 0
			})
		)
		film.screeningLink = getAnswerStringValue(
			data.answers.find((x) => {
				return x.questionName === 'Online screener link:'
			})
		)
		film.screeningLinkPassword = getAnswerStringValue(
			data.answers.find((x) => {
				return x.questionName === 'Online screener password:'
			})
		)
		film.isDVDScreenerAvailable = false
		film.notes = ''
		let fileUploads = data.answers.filter((x) => {
			return x.questionTypeId === 10
		})
		film.filmAttachments = []
		fileUploads.forEach((y) => {
			if (y.value) {
				let arr = JSON.parse(y.value)
				arr.forEach((z) => {
					film.filmAttachments.push({
						type:
							y.visibleQuestionValue &&
							y.visibleQuestionValue !== 'Yes' &&
							y.visibleQuestionValue !== 'British||International' &&
							y.visibleQuestionValue !== 'At a BIFA-qualifying film festival'
								? y.visibleQuestionValue
								: '',
						name: y.questionName,
						path: z,
					})
				})
			}
		})
		let hPeople = data.answers.filter((x) => {
			return x.questionName === 'Please list the Writer/s, Producer/s and Director/s and their nationalities:'
		})
		film.people = []
		hPeople.forEach((y) => {
			if (y.value) {
				let arr = JSON.parse(y.value)
				arr.forEach((z) => {
					let person = {
						role: z[2],
						name: z[0].trim() + ' ' + z[1].trim(),
						personId: '',
					}
					film.people.push(person)
				})
			}
		})

		film.primaryContact = data.contacts.primaryName
		film.primaryContactEmail = data.contacts.primaryEmail
		film.primaryContactRole = data.contacts.primaryRole
		film.primaryContactPhone = data.contacts.primaryPhone
		film.secondaryContact = data.contacts.isSecondary ? data.contacts.secondaryName : ''
		film.secondaryContactEmail = data.contacts.isSecondary ? data.contacts.secondaryEmail : ''
		film.secondaryContactRole = data.contacts.isSecondary ? data.contacts.secondaryRole : ''
		film.secondaryContactPhone = data.contacts.isSecondary ? data.contacts.secondaryPhone : ''

		categories = data.answers.find((x) => {
			return x.questionTypeId === 17
		})

		film.entries = []
		if (data.form.shortName === 'Shorts') {
			film.entries.push({ votingCategoryName: 'Best British Short', subTeam: '', entryPersonFilms: [] })
		} else if (data.isInternational) {
			film.entries.push({ votingCategoryName: 'Best International Independent Film', entryPersonFilms: [] })
		} else {
			JSON.parse(categories.value).forEach((v) => {
				let statement = getAnswerStringValue(
					data.answers.find((x) => {
						return x.questionName.includes('statement') && x.visibleQuestionValue.includes(v)
					})
				)
				let vPeopleAnswer = data.answers.find((x) => {
					return (
						x.questionTypeId == 13 &&
						x.visibleQuestionValue.includes(v) &&
						(!x.visibleQuestionValue.includes('||') || x.questionName.substring(0, 8) === v.substring(0, 8))
					)
				})

				let entryPersonFilms = []
				if (vPeopleAnswer && vPeopleAnswer.value) {
					let vPeople = JSON.parse(vPeopleAnswer.value)

					let labels = data.form.questions.find((q) => {
						return q.id === vPeopleAnswer.questionId
					})

					vPeople.forEach((z) => {
						let p = {
							name: z[0].trim(),
							role: '',
							character: '',
							nationality: '',
							pronouns: '',
							personId: 0,
						}
						if (labels.personDetailsLabel) {
							if (labels.personDetailsLabel.toLowerCase().includes('role')) {
								p.role = z[1].trim()
							} else if (labels.personDetailsLabel.toLowerCase().includes('nationality')) {
								p.nationality = z[1].trim()
							} else if (labels.personDetailsLabel.toLowerCase().includes('character')) {
								p.character = z[1].trim()
							} else if (labels.personDetailsLabel.toLowerCase().includes('pronoun')) {
								p.pronouns = z[1].trim()
							}
						}
						if (labels.personDetailsLabel2) {
							if (labels.personDetailsLabel2.toLowerCase().includes('role')) {
								p.role = z[2].trim()
							} else if (labels.personDetailsLabel2.toLowerCase().includes('nationality')) {
								p.nationality = z[2].trim()
							} else if (labels.personDetailsLabel2.toLowerCase().includes('character')) {
								p.character = z[2].trim()
							} else if (labels.personDetailsLabel2.toLowerCase().includes('pronoun')) {
								p.pronouns = z[2].trim()
							}
						}
						searchPersons(p.name).then((res) => {
							if (res.length === 1) {
								p.personId = res[0].id
							} else if (!res.length) {
								p.personId = 0
							}
							p.persons = [
								{ label: '*Create New Person*', value: 0, films: '', pronouns: '' },
								...res.map((x) => {
									return {
										label: `${x.name} [${x.films}]`,
										value: x.id,
										films: x.films,
										name: x.name,
										pronouns: x.pronouns,
									}
								}),
							]
						})
						entryPersonFilms.push(p)
					})
				}

				if (v === 'Breakthrough Performance') {
					// Breakthru Performers
					let vPeopleBPAnswer = data.answers.find((x) => {
						return x.questionName === 'How many performers are you entering into the Breakthrough Performance category?'
					})
					if (vPeopleBPAnswer && vPeopleBPAnswer.value) {
						let bpCount = JSON.parse(vPeopleBPAnswer.value)

						for (let index = 0; index < bpCount; index++) {
							let bpArray = data.answers.filter((x) => {
								return x.questionName.includes(`Breakthrough Performer # ${index + 1}`)
							})
							let bpn = bpArray.find((z) => {
								return z.questionTypeId === 12
							})
							if (bpn && bpn.value) {
								let bpPerson = JSON.parse(bpn.value)
								let p = {
									name: bpPerson[0].trim(),
									role: '',
									character: bpPerson[2].trim(),
									nationality: bpPerson[1].trim(),
									pronouns: '',
									personId: 0,
								}
								searchPersons(p.name).then((res) => {
									if (res.length === 1) {
										p.personId = res[0].id
									} else if (!res.length) {
										p.personId = 0
									}
									p.persons = [
										{ label: '*Create New Person*', value: 0 },
										...res.map((x) => {
											return {
												label: `${x.name} [${x.films}]`,
												value: x.id,
												films: x.films,
												name: x.name,
												pronouns: x.pronouns,
											}
										}),
									]
								})
								entryPersonFilms.push(p)
							}
						}
					}
				}

				film.entries.push({ votingCategoryName: v, statement, entryPersonFilms })
			})
		}
		ready = true
	})

	const update = (e) => {
		e.preventDefault()
		loading = true
		if (id) {
			submissionToEntries(model.id, film)
				.then(() => {
					loading = false
					notification.success({ title: 'Film & Entries created' })
					m.route.set(`/submissions/${model.id}`)
				})
				.catch((err) => {
					if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
						Sentry.captureException(err)
					}
					notification.error({ title: err.response })
					loading = false
				})
		}
	}

	const onSearch = (epf) => {
		epf.persons = null
		searchPersons(epf.name).then((res) => {
			if (res.length === 1) {
				epf.personId = res[0].id
			} else if (!res.length) {
				epf.personId = 0
			}
			epf.persons = [
				{
					label: '*Create New Person*',
					value: 0,
					films: '',
					pronouns: '',
				},
				...res.map((x) => {
					return {
						label: `${x.name} [${x.films}]`,
						value: x.id,
						films: x.films,
						name: x.name,
						pronouns: x.pronouns,
					}
				}),
			]
		})
	}
	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Submissions',
							href: '/submissions',
						},
						{
							title: model ? model.filmTitle : '',
							href: `/submissions/${id}`,
						},
						{
							title: 'Submission to Entries',
						},
					],
				}),

				ready &&
					m(
						EditModelForm,
						{
							onsubmit: update,
						},
						[
							ready &&
								m('.mt-5.md:mt-0.md:col-span-3.lg:col-span-4', [
									m(
										Card,
										{
											buttons: [
												m(
													Button,
													{
														type: 'button',
														variant: 'warning',
														onclick() {
															m.route.set(`/submissions/${id}`)
														},
													},
													t('app', 'cancel')
												),
												m(
													Button,
													{
														type: 'submit',
														variant: 'positive',
														loading,
													},
													'Create Entries'
												),
											],
											buttonJustify: 'justify-between',
										},
										m('table.w-full', [
											m('tr.border-b-2.border-white', [
												m('th.w-1/5.text-left', 'Film Title'),
												m('td.w-2/5.bg-gray-50.px-2', model.filmTitle),
												m(
													'td.w-2/5',
													m(Input, {
														label: '',
														value: film.title,
														type: 'text',
														required: true,
														oninput(value) {
															film.title = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Running Time'),
												m(
													'td.bg-gray-50.px-2',
													getAnswerStringValue(
														model.answers.find((x) => {
															return x.questionName === 'Running time'
														})
													)
												),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.runningTime,
														type: 'number',
														required: true,
														oninput(value) {
															film.runningTime = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left.align-top', 'Description'),
												m(
													'td.bg-gray-50.px-2.text-xs',
													getAnswerStringValue(
														model.answers.find((x) => {
															return x.questionName === 'Synopsis'
														})
													)
												),
												m(
													'td',
													m(TextArea, {
														label: '',
														value: film.description,
														rows: 4,
														oninput(val) {
															film.description = val
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Budget'),
												m(
													'td.bg-gray-50.px-2',
													getAnswerStringValue(
														model.answers.find((x) => {
															return x.questionTypeId === 15
														})
													)
												),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.budget,
														type: 'text',
														required: false,
														oninput(value) {
															film.budget = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Trailer'),
												m(
													'td.bg-gray-50.px-2',
													getAnswerStringValue(
														model.answers.find((x) => {
															return x.questionName === 'Trailer link'
														})
													)
												),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.trailer,
														type: 'text',
														required: true,
														oninput(value) {
															film.trailer = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left.align-top', 'Poster'),
												m(
													'td.bg-gray-50.px-2',
													getAnswerStringValue(
														model.answers.find((x) => {
															return x.questionName === 'Poster artwork'
														})
													)
												),
												m(
													'td',
													m(TextArea, {
														label: '',
														value: film.posterUrl,
														rows: 3,
														oninput(val) {
															film.posterUrl = val
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Screening info'),
												m(
													'td.bg-gray-50.px-2',
													getAnswerStringValue(
														model.answers.find((x) => {
															return x.questionName === 'Please tell us how our voters will be able to see the film'
														})
													)
												),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.screeningInfo,
														type: 'text',
														required: false,
														oninput(value) {
															film.screeningInfo = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Available from'),
												m(
													'td.bg-gray-50.px-2',
													getAnswerStringValue(
														model.answers.find((x) => {
															return (
																x.questionName ===
																'Please tell us when this screener will be available for voters to view?'
															)
														})
													)
												),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.screenerDates,
														type: 'text',
														required: false,
														oninput(value) {
															film.screenerDates = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Additional screener info'),
												m(
													'td.bg-gray-50.px-2',
													getAnswerStringValue(
														model.answers.find((x) => {
															return (
																x.questionName === 'Please list any screener restrictions voters should be aware of'
															)
														})
													)
												),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.screenerRestrictions,
														type: 'text',
														required: false,
														oninput(value) {
															film.screenerRestrictions = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Screener link'),
												m(
													'td.bg-gray-50.px-2',
													getAnswerStringValue(
														model.answers.find((x) => {
															return x.questionName === 'Online screener link'
														})
													)
												),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.screeningLink,
														type: 'text',
														required: false,
														oninput(value) {
															film.screeningLink = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Screener password'),
												m(
													'td.bg-gray-50.px-2',
													getAnswerStringValue(
														model.answers.find((x) => {
															return x.questionName === 'Online screener password'
														})
													)
												),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.screeningLinkPassword,
														type: 'text',
														required: false,
														oninput(value) {
															film.screeningLinkPassword = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left.align-top', 'Attachments'),
												m(
													'td.bg-gray-50.px-2',
													{
														colspan: 2,
													},
													m('.table.table.w-full', [
														m('tr', [
															m('th.text-left.text-sm.border.p-1', 'Entry'),
															m('th.text-left.text-sm.border.p-1', 'Name'),
															m('th.text-left.text-sm.border.p-1', 'Path'),
														]),
														film.filmAttachments.map((x, ix) => {
															return m('tr', [
																m(
																	'td',
																	m(Input, {
																		label: '',
																		value: x.type,
																		type: 'text',
																		required: false,
																		oninput(value) {
																			film.filmAttachments[ix].type = value
																		},
																	})
																),
																m(
																	'td',
																	m(Input, {
																		label: '',
																		value: x.name,
																		type: 'text',
																		required: false,
																		oninput(value) {
																			film.filmAttachments[ix].name = value
																		},
																	})
																),
																m(
																	'td',
																	m(Input, {
																		label: '',
																		value: x.path,
																		type: 'text',
																		required: false,
																		classes: ['col-span-2'],
																		oninput(value) {
																			film.filmAttachments[ix].path = value
																		},
																	})
																),
															])
														}),
													])
												),
											]),
											// m('tr.border-b-2.border-white', [
											// 	m('th.w-64.text-left.align-top', 'People'),
											// 	m(
											// 		'td.bg-gray-50.px-2',
											// 		{
											// 			colspan: 2,
											// 		},
											// 		m('table.table.w-full.bg-white', [
											// 			m('tr.border-b-2.border-white', [
											// 				m('th.text-sm.border.p-1', 'Role'),
											// 				m('th.text-sm.border.p-1', 'Name'),
											// 				m('th.text-sm.border.p-1', 'Nationality'),
											// 				m('th.text-sm.border.p-1', 'Person'),
											// 			]),
											// 			film.people.map((x, ix) => {
											// 				return m('tr.border-b-2.border-white', [
											// 					m(
											// 						'td',
											// 						m(Input, {
											// 							label: '',
											// 							value: x.role,
											// 							type: 'text',
											// 							required: false,
											// 							oninput(value) {
											// 								film.people[ix].role = value
											// 							},
											// 						})
											// 					),
											// 					m(
											// 						'td',
											// 						m(Input, {
											// 							label: '',
											// 							value: x.name,
											// 							type: 'text',
											// 							required: false,
											// 							oninput(value) {
											// 								film.people[ix].name = value
											// 							},
											// 						})
											// 					),
											// 					m(
											// 						'td',
											// 						m(Input, {
											// 							label: '',
											// 							value: x.nationality,
											// 							type: 'text',
											// 							required: false,
											// 							oninput(value) {
											// 								film.people[ix].nationality = value
											// 							},
											// 						})
											// 					),
											// 					m('td'),
											// 				])
											// 			}),
											// 		])
											// 	),
											// ]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left.align-top', 'Voting Categories'),
												m(
													'td.bg-gray-50.px-2',
													{
														colspan: 2,
													},
													m('table.table.w-full.bg-white', [
														m('tr.border-b-2.border-gray-200', [
															m('th.text-left.text-sm.border.p-1', 'Category'),
															m('th.text-left.text-sm.border.p-1', 'Statement'),
															m('th.text-left.text-sm.border.p-1', 'People'),
														]),
														film.entries.map((entry, eix) => {
															return m('tr.border-b-2.border-gray-200.text-sm', [
																m('td.border.p-1', [
																	entry.votingCategoryName
																		? entry.votingCategoryName
																		: m(
																				'select',
																				{
																					value: entry.votingCategoryName,
																					required: true,
																					onchange(val) {
																						film.entries[eix].votingCategoryName = val.target.value
																					},
																				},
																				[
																					JSON.parse(
																						model.form.questions.find((x) => {
																							return x.id === categories.questionId
																						}).options
																					).map((x) => {
																						return m(
																							'option',
																							{
																								value: x,
																							},
																							x
																						)
																					}),
																					m(
																						'option',
																						{
																							value: 'Best International Independent Film',
																						},
																						'Best International Independent Film'
																					),
																				]
																		  ),
																	m(
																		Button,
																		{
																			type: 'button',
																			size: 'xs',
																			variant: 'delete',
																			onclick() {
																				film.entries.splice(eix, 1)
																			},
																		},
																		'Delete entry'
																	),
																]),
																m(
																	'td.border.p-1',
																	entry.statement
																		? m(
																				Tooltip,
																				{
																					tipContent: entry.statement,
																					icon: tickIcon,
																					colorClass: 'bg-green-600 text-white',
																				},
																				'Yes'
																		  )
																		: entry.votingCategoryName === 'Best British Short'
																		? m(Input, {
																				label: 'SubTeam',
																				value: entry.subTeam,
																				type: 'text',
																				tight: true,
																				classes: 'flex items-center gap-2',
																				oninput(value) {
																					entry.subTeam = value ? value.toUpperCase() : ''
																				},
																		  })
																		: 'No'
																),
																m('td.border.p-1', [
																	entry.entryPersonFilms &&
																		m('div', [
																			entry.entryPersonFilms.map((epf, epfix) => {
																				return m('.flex.border.p-1.my-1.bg-gray-200', [
																					m('.grid.grid-cols-4.gap-1.w-full', [
																						m(
																							'.col-span-3',
																							m(Input, {
																								label: 'Name',
																								value: epf.name,
																								type: 'text',
																								tighter: true,
																								required: true,
																								oninput(value) {
																									film.entries[eix].entryPersonFilms[epfix].name = value
																								},
																								onenter() {
																									onSearch(film.entries[eix].entryPersonFilms[epfix])
																								},
																							})
																						),
																						m(
																							'.flex.flex-col.justify-end',
																							m(
																								Button,
																								{
																									size: 'xs',
																									type: 'button',
																									onclick() {
																										onSearch(film.entries[eix].entryPersonFilms[epfix])
																									},
																								},
																								'Search'
																							)
																						),
																						m(Input, {
																							label: 'Char',
																							value: epf.character,
																							type: 'text',
																							tighter: true,
																							oninput(value) {
																								film.entries[eix].entryPersonFilms[epfix].character = value
																							},
																						}),
																						m(Input, {
																							label: 'Role',
																							value: epf.role,
																							type: 'text',
																							tighter: true,
																							oninput(value) {
																								film.entries[eix].entryPersonFilms[epfix].role = value
																							},
																						}),
																						m(Input, {
																							label: 'Nat',
																							value: epf.nationality,
																							type: 'text',
																							tighter: true,
																							oninput(value) {
																								film.entries[eix].entryPersonFilms[epfix].nationality = value
																							},
																						}),
																						m(Input, {
																							label: 'Pronouns',
																							value: epf.pronouns,
																							type: 'text',
																							tighter: true,
																							oninput(value) {
																								film.entries[eix].entryPersonFilms[epfix].pronouns = value
																							},
																						}),
																						m(
																							'.col-span-3',
																							epf.persons &&
																								m(Select, {
																									label: '',
																									value: film.entries[eix].entryPersonFilms[epfix].personId,
																									required: true,
																									removeItemButton: false,
																									choices: epf.persons,
																									onchange(val) {
																										film.entries[eix].entryPersonFilms[epfix].personId = parseInt(val)
																									},
																								})
																						),
																						m(
																							'.text-right.mt-4',
																							m(
																								Button,
																								{
																									type: 'button',
																									size: 'xs',
																									variant: 'delete',
																									onclick() {
																										film.entries[eix].entryPersonFilms.splice(epfix, 1)
																									},
																								},
																								'Delete person'
																							)
																						),
																					]),
																				])
																			}),
																		]),
																	m(
																		Button,
																		{
																			size: 'xs',
																			type: 'button',
																			onclick() {
																				entry.entryPersonFilms.push({
																					name: '',
																					character: '',
																					role: '',
																					nationality: '',
																					pronouns: '',
																				})
																			},
																		},
																		'Add Person'
																	),
																]),
															])
														}),
													]),
													m(
														Button,
														{
															type: 'button',
															onclick() {
																film.entries.push({ votingCategoryName: '', statement: '', entryPersonFilms: [] })
															},
														},
														'Add entry'
													)
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Primary Contact'),
												m('td.bg-gray-50.px-2'),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.primaryContact,
														type: 'text',
														required: true,
														oninput(value) {
															film.primaryContact = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Primary Contact Role'),
												m('td.bg-gray-50.px-2'),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.primaryContactRole,
														type: 'text',
														required: true,
														oninput(value) {
															film.primaryContactRole = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Primary Contact Email'),
												m('td.bg-gray-50.px-2'),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.primaryContactEmail,
														type: 'text',
														required: true,
														oninput(value) {
															film.primaryContactEmail = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Primary Contact Phone'),
												m('td.bg-gray-50.px-2'),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.primaryContactPhone,
														type: 'text',
														required: true,
														oninput(value) {
															film.primaryContactPhone = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Secondary Contact'),
												m('td.bg-gray-50.px-2'),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.secondaryContact,
														type: 'text',
														required: false,
														oninput(value) {
															film.secondaryContact = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Secondary Contact Role'),
												m('td.bg-gray-50.px-2'),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.secondaryContactRole,
														type: 'text',
														required: false,
														oninput(value) {
															film.secondaryContactRole = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Secondary Contact Email'),
												m('td.bg-gray-50.px-2'),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.secondaryContactEmail,
														type: 'text',
														required: false,
														oninput(value) {
															film.secondaryContactEmail = value
														},
													})
												),
											]),
											m('tr.border-b-2.border-white', [
												m('th.w-64.text-left', 'Secondary Contact Phone'),
												m('td.bg-gray-50.px-2'),
												m(
													'td',
													m(Input, {
														label: '',
														value: film.secondaryContactPhone,
														type: 'text',
														required: false,
														oninput(value) {
															film.secondaryContactPhone = value
														},
													})
												),
											]),
										])
									),
								]),
						]
					),
			]
		},
	}
}
