import { Table, Row, Cell, Button, LoadingFullScreen, Pagination, Svg } from 'components'
import { getAwardsYearScreenerRequests, exportScreenerRequests } from 'api/awardsyears'
import chevronDown from 'assets/icons/outline/chevron-double-down.svg'

export const ScreenerRequestsTab = ({ attrs: { id, awardsYear } }) => {
	const cols = [{ label: 'Id' }, { label: 'Name' }, { label: 'Film' }, { label: 'Date' }]
	let list
	let meta

	const loadData = () => {
		list = null
		getAwardsYearScreenerRequests(id).then((res) => {
			list = res
			meta = {
				currentPage: 1,
				itemsPerPage: res.length,
				totalItems: res.length,
				totalPages: 1,
			}
		})
	}

	loadData()

	let exporting
	const onExportScreenerRequests = () => {
		exportScreenerRequests(id).then((res) => {
			exporting = false
		})
	}
	return {
		view({ attrs: { id, awardsYear } }) {
			return m('.h-auto', [
				m(
					Table,
					{
						cols: cols,
					},
					[
						list
							? list.map((row) => {
									return m(
										Row,
										{
											clickable: false,
											bg: 'bg-white',
										},
										[m(Cell, row.id), m(Cell, row.name), m(Cell, row.film), m(Cell, row.screenerRequestedDate)]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
				m(
					'div',
					meta &&
						m(Pagination, {
							meta: meta,
							onrefresh: () => {
								loadData()
							},
							onpage: (newPage) => {
								page = newPage
								loadData()
							},
							buttons: [
								m('.flex.justify-end.gap-x-4', [
									m(
										Button,
										{
											size: 'sm',
											classes: ['my-2', 'whitespace-nowrap', 'text-white'],
											type: 'button',
											loading: exporting,
											onclick: () => {
												exporting = true
												onExportScreenerRequests()
											},
										},
										[
											m(
												Svg,
												{
													classes: ['w-4', 'mx-auto'],
												},
												[chevronDown]
											),
											'Export Requests',
										]
									),
								]),
							],
						})
				),
			])
		},
	}
}
