import {
	Breadcrumb,
	Select,
	TextArea,
	Input,
	Checkbox,
	EditModelForm,
	EditFormSection,
	LoadingFullScreen,
} from 'components'
import { getPerson, updatePerson, createPerson } from 'api/persons'
import { notification } from 'utils/notifications'

export const EditPerson = ({ attrs: { id, returnUrl } }) => {
	let ready
	let model

	if (id) {
		getPerson(id).then((data) => {
			model = data
			ready = true
		})
	} else {
		model = {}
		ready = true
	}

	const createOrUpdate = (e) => {
		e.preventDefault()
		if (id) {
			updatePerson(model.id, model)
				.then(() => {
					notification.success({ title: 'Person updated' })
					m.route.set(returnUrl ? returnUrl : '/people')
				})
				.catch((err) => {
					if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
						Sentry.captureException(err)
					}
					notification.error({ title: err.response })
				})
		} else {
			createPerson(model)
				.then((data) => {
					notification.success({ title: 'Person created' })
					m.route.set(returnUrl ? returnUrl : '/people')
				})
				.catch((err) => {
					if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
						Sentry.captureException(err)
					}
					notification.error({ title: err.response })
				})
		}
	}

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Persons',
							href: '/people',
						},
						{
							title: !id ? 'Create' : ready ? `Edit ${model.name}` : 'Edit',
						},
					],
				}),

				ready &&
					m(
						EditModelForm,
						{
							onsubmit: createOrUpdate,
						},
						[
							m(
								EditFormSection,
								{
									heading: id ? 'Edit Person' : 'Create Person',
									description: '',
									extra: null,
								},
								[
									ready &&
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-6', [
												m(Input, {
													label: 'Name',
													value: model.name,
													type: 'text',
													oninput(value) {
														model.name = value
													},
												}),
											]),
											m('.col-span-2', [
												m(Input, {
													label: 'Pronouns',
													value: model.pronouns,
													type: 'text',
													oninput(value) {
														model.pronouns = value
													},
												}),
											]),
										]),
								]
							),
							m(
								EditFormSection,
								{
									heading: 'Films',
									description: '',
									extra: null,
								},
								[
									ready &&
										m('.grid.grid-cols-4.gap-6', [
											model.personFilms.map((ea, eax) => {
												return [
													m('span.mt-6', `${ea.filmTitle} [${ea.filmYear}]`),
													m(Input, {
														label: 'Character',
														value: ea.character,
														oninput(value) {
															model.personFilms[eax].character = value
														},
													}),
													m(Input, {
														label: 'Role',
														value: ea.role,
														oninput(value) {
															model.personFilms[eax].role = value
														},
													}),
													m(Input, {
														label: 'Nationality',
														value: ea.nationality,
														oninput(value) {
															model.personFilms[eax].nationality = value
														},
													}),
												]
											}),
										]),
								]
							),
						]
					),
			]
		},
	}
}
