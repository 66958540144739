import { Breadcrumb, Tabs } from 'components'
import { notification } from 'utils/notifications'
import { DateTime } from 'luxon'
import { getSubmissionStatusSummary } from 'api/submissions'
import { getAwardsYearsLookup } from 'api/awardsyears'
import { StatusTab } from './StatusTab'

export const Submissions = ({ attrs: { tab, search, p, awardsYear, type } }) => {
	const root = '/submissions'
	let ready

	if (!tab) {
		tab = 'Submitted'
	}

	let awardsYears, currentAwardsYear
	getAwardsYearsLookup().then((x) => {
		awardsYears = x.reverse()
	})
	if (awardsYear) {
		currentAwardsYear = awardsYear
	}

	let tabs = []
	let activeTab
	const loadData = () => {
		getSubmissionStatusSummary(currentAwardsYear).then((res) => {
			tabs = res.map((t, ix) => {
				return {
					id: ix + 1,
					name: t.shortName,
					active: t.shortName === tab,
					badge: t.total,
				}
			})
			tabs.push({
				id: 0,
				name: 'All',
				active: 'All' === tab,
			})
			activeTab = tabs.find((t) => t.name === tab)
			ready = true
		})
	}
	loadData()
	return {
		view({ attrs: { tab } }) {
			return m('div', [
				m(Breadcrumb, {
					links: [
						{
							title: 'Submissions',
						},
					],
				}),
				m('.flex.flex-wrap.relative.h-screen.pb-24.overflow-x-hidden.content-start', [
					awardsYears &&
						m('.flex.w-full.justify-end', [
							m('div.px-4', [
								m(
									'select.mt-2.block.w-32.rounded-md.border-0.py-1.5.pl-3.pr-10.text-gray-900.ring-1.ring-inset.ring-gray-300.focus:ring-2.focus:ring-indigo-600.sm:text-sm.sm:leading-6',
									{
										onchange(e) {
											currentAwardsYear = parseInt(e.target.value)
											ready = false
											p = 1
											loadData()
										},
									},
									[
										awardsYears.map((ay) => {
											return m(
												'option',
												{
													value: ay.id,
													selected: ay.id == currentAwardsYear ? 'selected' : null,
												},
												ay.name
											)
										}),
									]
								),
							]),
						]),
					m('.p-4.w-full', [
						m('div.shadow.sm:rounded-md.divide-y.divide-gray-200', [
							ready &&
								m('div.bg-gray-50.px-2.py-1', [
									m(Tabs, {
										tabs,
										onTabChange: (tabId) => {
											activeTab = null
											tabs.forEach((t) => {
												if (t.id === tabId) {
													tab = t.name
													activeTab = t
													t.active = true
												} else {
													t.active = false
												}
											})
											p = 1
											m.redraw()
										},
									}),
									m('.relative', [
										[
											activeTab
												? m(StatusTab, {
														key: activeTab.id,
														statusId: activeTab.id,
														tab: activeTab,
														search,
														p,
														currentAwardsYear,
														type,
												  })
												: null,
										],
									]),
								]),
						]),
					]),
				]),
			])
		},
	}
}
