import { request } from './request'

export const getUsers = (params) => {
	return request({
		url: 'account/users',
		method: 'GET',
		params: {
			pagesize: 25,
			...params,
		},
	})
}

export const getUser = (id) => {
	return request({
		url: `account/users/${id}`,
		method: 'GET',
	})
}

export const updateUser = (id, user) => {
	return request({
		url: `account/users/${id}`,
		method: 'PUT',
		body: user,
	})
}

export const addAdminUser = (user) => {
	return request({
		url: 'account/admin-users',
		method: 'POST',
		params: {
			site: `https://${process.env.API_DOMAIN}`,
		},
		body: user,
	})
}

export const importVoters = (file) => {
	const formData = new FormData()
	formData.append('file', file)
	return request(
		{
			method: 'POST',
			url: `account/import-voters`,
			body: formData,
		},
		{
			contentType: false,
		}
	)
}

export const exportUsers = (search, role) => {
	return request({
		url: 'account/export-users',
		params: {
			search,
			role,
		},
		method: 'GET',
		responseType: 'blob',
		extract(val) {
			return val.response
		},
	}).then((res) => {
		const date = new Date()
		var anchor = document.createElement('a')
		anchor.download = `bifa_users_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}.xlsx`
		anchor.href = window.URL.createObjectURL(res)
		anchor.click()
	})
}

export const resetPassword = (email, sendToMe) => {
	return request({
		method: 'POST',
		url: `account/adminrecoverpassword`,
		body: {
			usernameOrEmail: email,
			sendToMe: sendToMe,
			domain: 'members.bifa.film',
		},
	})
}
