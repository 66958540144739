import { Svg } from 'components'

export const ActionButton = () => {
	let open = false
	return {
		view({ attrs: { actions } }) {
			const first = actions.find((a) => {
				return a
			})
			return m('span.relative.z-0.inline-flex.shadow-sm.rounded-md', [
				m(
					'button.relative.inline-flex.items-center.px-4.py-2.rounded-l.border.border-gray-300.bg-white.text-sm.font-medium.text-gray-700.hover:bg-gray-50.focus:z-10.focus:outline-none.focus:ring-1.focus:ring-indigo-500.focus:border-indigo-500',
					{
						type: 'button',
						onclick() {
							first.action()
						},
					},
					[
						first.icon && m(Svg, { classes: ['w-5', 'h-5', 'mr-2', 'text-gray-400', 'flex-shrink-0'] }, first.icon),
						m('span.text-gray-600.leading-none', first.label),
					]
				),
				m('span.-ml-px.relative.block', [
					m(
						"button.relative.inline-flex.items-center.px-2.py-2.rounded-r.border.border-gray-300.bg-white.text-sm.font-medium.text-gray-500.hover:bg-gray-50.focus:z-10.focus:outline-none.focus:ring-1.focus:ring-indigo-500.focus:border-indigo-500[id='option-menu-button'][aria-expanded='true'][aria-haspopup='true']",
						{
							type: 'button',
							onclick() {
								open = !open
							},
						},
						[
							m('span.sr-only', 'Open options'),
							m(
								"svg.h-5.w-5[xmlns='http://www.w3.org/2000/svg'][viewBox='0 0 20 20'][fill='currentColor'][aria-hidden='true']",
								m(
									"path[fill-rule='evenodd'][d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'][clip-rule='evenodd']"
								)
							),
						]
					),
					open &&
						m(
							'.origin-top-right.absolute.right-0.mt-1.-mr-1.w-64.rounded-md.shadow-lg.bg-white.ring-1.ring-gray-300.ring-opacity-5.focus:outline-none.z-50',
							{
								role: 'menu',
								'aria-orientation': 'vertical',
								'aria-labelledby': 'option-menu-button',
								tabindex: '-1',
							},
							m(
								".py-1[role='none']",
								actions.map((action) => {
									if (action) {
										return m(
											'a.text-gray-700.flex.items-center.px-4.py-2.text-sm.hover:bg-gray-100',
											{
												class: action.color || 'text-gray-700',
												href: '#',
												role: 'menuitem',
												onclick(e) {
													e.preventDefault()
													action.action()
													open = !open
												},
												tabindex: '-1',
											},
											[
												action.icon &&
													m(Svg, { classes: ['w-5', 'h-5', 'mr-2', 'text-gray-400', 'flex-shrink-0'] }, action.icon),
												m('span.text-gray-600.leading-none', action.label),
											]
										)
									}
								})
							)
						),
				]),
			])
		},
	}
}
