import { Breadcrumb, Select, Input, EditModelForm, EditFormSection, TinyMce } from 'components'
import { getEmailTemplate, updateEmailTemplate, createEmailTemplate } from 'api/emailTemplates'
import { notification } from 'utils/notifications'

export const EditEmailTemplate = ({ attrs: { id } }) => {
	let ready
	let emailTemplate

	if (id) {
		getEmailTemplate(id).then((data) => {
			emailTemplate = data

			ready = true
		})
	} else {
		emailTemplate = {
			delay: 0,
		}
		ready = true
	}

	const createOrUpdate = (e) => {
		e.preventDefault()
		if (id) {
			updateEmailTemplate(emailTemplate.id, emailTemplate)
				.then(() => {
					notification.success({ title: 'Email template updated' })
					m.route.set('/email-templates')
				})
				.catch((err) => {
					notification.error({ title: err.response })
				})
		} else {
			createEmailTemplate(emailTemplate)
				.then(() => {
					notification.success({ title: 'Email template created' })
					m.route.set('/email-templates')
				})
				.catch((err) => {
					notification.error({ title: err.response })
				})
		}
	}

	const tags = () => {
		return {
			view() {
				return [
					m('ul', [
						m('li', '{{Name}}'),
						m('li', '{{FirstName}}'),
						m('li', '{{LastName}}'),
						m('li', '{{Email}}'),
						m('li', '{{ActionLink}}'),
					]),
				]
			},
		}
	}

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Email Templates',
							href: '/email-templates',
						},
						{
							title: !id ? 'Create' : ready ? `Edit ${emailTemplate.description}` : 'Edit',
						},
					],
				}),

				ready &&
					m(
						EditModelForm,
						{
							onsubmit: createOrUpdate,
						},
						[
							m(
								EditFormSection,
								{
									heading: id ? 'Edit Email Template' : 'Create Email Template',
									description: 'Tags',
									extra: m(tags),
								},
								[
									m('.grid.grid-cols-6.gap-6', [
										ready &&
											m('.col-span-4', [
												m(Input, {
													label: 'Name',
													value: emailTemplate.description,
													type: 'text',
													required: true,
													oninput(value) {
														emailTemplate.description = value
													},
												}),
											]),
										ready &&
											m('.col-span-2.hidden', [
												m(Input, {
													label: 'Delay',
													value: emailTemplate.delay,
													type: 'number',
													required: true,
													oninput(value) {
														emailTemplate.delay = value
													},
													suffix: 'mins',
												}),
											]),
										ready &&
											m('.col-span-6', [
												m(Input, {
													label: 'Default Subject',
													value: emailTemplate.defaultSubject,
													type: 'text',
													required: false,
													oninput(value) {
														emailTemplate.defaultSubject = value
													},
												}),
											]),
										m('.col-span-6.flex.flex-col', [
											m(TinyMce, {
												value: emailTemplate.body,
												label: 'Email Content',
												name: 'email_content',
												onchange: (content) => {
													emailTemplate.body = content
												},
											}),
										]),
									]),
								]
							),
						]
					),
			]
		},
	}
}
