import { Button, Table, Row, Cell, CellBool, Svg, Pagination, LoadingFullScreen } from 'components'
import { getAwardsYearEntries, exportEntries } from 'api/awardsyears'
import chevronDown from 'assets/icons/outline/chevron-double-down.svg'

export const EntriesTab = ({ attrs: { id, awardsYear, search, p, categoryId } }) => {
	const root = `/awardsyears/${id}/films`
	let searchString = search || ''
	let sortBy = 'title'
	let sortOrder = 'asc'
	let available = false
	let list
	let meta
	let page = p || 1
	let perPage = 25
	let categories = awardsYear.votingCategories.map((z) => {
		return { name: z.shortName, id: z.id, selected: categoryId && z.id === parseInt(categoryId) }
	})

	const loadData = () => {
		list = null
		var pageUrl = `${window.location.pathname}?tab=Entries&p=${page}&search=${searchString}&categoryId=${
			categoryId ? categoryId : ''
		}`
		window.history.pushState('', '', pageUrl)
		getAwardsYearEntries(id, {
			search: searchString,
			page,
			pagesize: perPage,
			sortBy,
			sortOrder,
			categoryId,
		}).then((res) => {
			list = res.data
			meta = res.meta
		})
	}

	let exportingEntries
	const onExportEntries = () => {
		exportEntries(id, searchString, categoryId).then((res) => {
			exportingEntries = false
		})
	}

	const cols = [
		{ label: 'Id', classes: ['w-12'] },
		{ label: 'Film' },
		{
			label: 'Entry',
			classes: ['w-64'],
			filter: true,
			filterEmptyOption: true,
			filterOptions: categories,
			filterOnChange(val) {
				categoryId = parseInt(val)
				loadData()
			},
		},
		{ label: 'People' },
		{ label: 'Entry Status', classes: ['w-32'] },
	]

	loadData()

	return {
		view({ attrs: { id } }) {
			return m('.h-auto', [
				m(
					Table,
					{
						cols: cols,
						searchRow: true,
						searchString: searchString,
						onsearchinput: (newSearch) => {
							searchString = newSearch
						},
						onsearch: () => {
							page = 1
							loadData()
						},
						sortOrder,
						sortBy,
						filter: available,
						filterRow: true,
					},
					[
						list
							? list.map((row) => {
									return m(
										Row,
										{
											clickable: false,
											onclick: (e) => {
												if (e.ctrlKey || e.metaKey) {
													window.open(`/films/${row.filmId}/entries/${row.id}`, '_blank')
												} else {
													m.route.set(`/films/${row.filmId}/entries/${row.id}`)
												}
											},
											bg: row.entryStatusName === 'Ineligible' ? 'bg-red-50' : 'bg-white',
										},
										[
											m(Cell, row.id),
											m(Cell, row.title),
											m(Cell, row.votingCategoryShortName),
											m(Cell, row.people),
											m(Cell, row.entryStatusName),
										]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
				m(
					'div',
					meta &&
						m(Pagination, {
							meta: meta,
							onrefresh: () => {
								loadData()
							},
							onpage: (newPage) => {
								page = newPage
								loadData()
							},
							buttons: [
								m('.flex.justify-end.gap-x-4', [
									m(
										Button,
										{
											size: 'sm',
											classes: ['my-2', 'whitespace-nowrap', 'text-white'],
											type: 'button',
											loading: exportingEntries,
											onclick: () => {
												exportingEntries = true
												onExportEntries()
											},
										},
										[
											m(
												Svg,
												{
													classes: ['w-4', 'mx-auto'],
												},
												[chevronDown]
											),
											'Export Entries',
										]
									),
								]),
							],
						})
				),
			])
		},
	}
}
