import { Dialog2 } from 'components/Dialog2'

export const ImportResponseDialog = ({ attrs: { loading, importResponse } }) => {
	loading = false

	return {
		view({ attrs: { loading, importResponse, open, heading } }) {
			return m(
				Dialog2,
				{
					open,
					classes: ['md:max-w-4xl'],
					save: false,
					cancelLabel: 'Close',
					heading: heading || 'Import Details',
					saving: loading,
				},
				[importResponse && m('.flex.flex-col.gap-3', [importResponse])]
			)
		},
	}
}
