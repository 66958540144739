import { Breadcrumb, Select, Input, TextArea, EditModelForm, EditFormSection, Checkbox } from 'components'
import { getRule, updateRule, createRule, getRuleTypes } from 'api/rules'
import { notification } from 'utils/notifications'

export const EditRule = ({ attrs: { id } }) => {
	let ready
	let rule
	let ruleTypes

	if (id) {
		getRule(id).then((data) => {
			rule = data

			ready = true
		})
	} else {
		rule = {
			delay: 0,
		}
		ready = true
	}

	getRuleTypes().then((data) => {
		ruleTypes = data.map((x) => {
			return {
				label: x.name,
				value: x.id,
			}
		})
	})

	const createOrUpdate = (e) => {
		e.preventDefault()
		if (id) {
			updateRule(rule.id, rule)
				.then(() => {
					notification.success({ title: 'Rule updated' })
					m.route.set('/rules')
				})
				.catch((err) => {
					if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
						Sentry.captureException(err)
					}
					notification.error({ title: err.response })
				})
		} else {
			createRule(rule)
				.then(() => {
					notification.success({ title: 'Rule created' })
					m.route.set('/rules')
				})
				.catch((err) => {
					if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
						Sentry.captureException(err)
					}
					notification.error({ title: err.response })
				})
		}
	}

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Rules',
							href: '/rules',
						},
						{
							title: !id ? 'Create' : ready ? `Edit ${rule.name ? rule.name : 'Rule'}` : 'Edit',
						},
					],
				}),

				ready &&
					m(
						EditModelForm,
						{
							onsubmit: createOrUpdate,
						},
						[
							m(
								EditFormSection,
								{
									heading: id ? 'Edit Rule' : 'Create Rule',
									description: '',
								},
								[
									ready &&
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-2', [
												m(Input, {
													label: 'Name',
													value: rule.name,
													type: 'text',
													required: false,
													oninput(value) {
														rule.name = value
													},
												}),
											]),
											m('.col-span-4', [
												ruleTypes &&
													m(Select, {
														label: 'Rule Type',
														value: rule.ruleTypeId,
														required: false,
														choices: ruleTypes,
														onchange(val) {
															rule.ruleTypeId = parseInt(val)
														},
													}),
											]),
											m('.col-span-2', [
												m(Input, {
													label: 'Value',
													value: rule.value,
													type: 'number',
													step: '1',
													required: false,
													oninput(value) {
														rule.value = value
													},
												}),
											]),
											m('.col-span-2', [
												m(Checkbox, {
													label: 'Include Conflicted as Seen?',
													checked: rule.includeConflictedAsSeen,
													onchange() {
														rule.includeConflictedAsSeen = !rule.includeConflictedAsSeen
													},
												}),
											]),
											m('.col-span-4', [
												m(TextArea, {
													label: 'Description',
													value: rule.description,
													name: 'description',
													rows: 3,
													oninput: (content) => {
														rule.description = content
													},
												}),
											]),
										]),
								]
							),
						]
					),
			]
		},
	}
}
