import { Breadcrumb, Table, Cell, CellBool, Row, LoadingFullScreen } from 'components'
import { getSubmissionStatuses } from 'api/submissions'

export const SubmissionStatuses = () => {
	const root = '/submission-statuses'
	let submissionStatuses

	getSubmissionStatuses().then((data) => {
		submissionStatuses = data
	})

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Submission Statuses',
						},
					],
				}),
				m('.p-4', { style: { height: 'calc(100% - 3rem)' } }, [
					m(
						'.h-full.shadow.overflow-hidden.border-b.border-gray-200.sm:rounded-lg',
						submissionStatuses &&
							m(
								Table,
								{
									cols: [
										{
											label: 'Id',
											classes: ['w-24'],
										},
										{
											label: 'Name',
										},
										{
											label: 'Short Name',
										},
										{
											label: 'Votable?',
										},
									],
								},
								[
									submissionStatuses
										? submissionStatuses.map((row) => {
												return m(
													Row,
													{
														clickable: true,
														onclick: (e) => {
															if (e.ctrlKey || e.metaKey) {
																window.open(`${root}/${row.id}`, '_blank')
															} else {
																m.route.set(`${root}/${row.id}`)
															}
														},
														bg: 'bg-white',
													},
													[
														m(Cell, row.id),
														m(Cell, row.name),
														m(Cell, row.shortName),
														m(CellBool, { val: row.isVotable }),
													]
												)
										  })
										: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
								]
							)
					),
				]),
			]
		},
	}
}
