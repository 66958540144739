import { Input, Dialog2, Select, Button } from 'components'
import { searchPersons } from 'api/persons'

export const PersonDialog = ({ attrs: { loading } }) => {
	loading = false

	const person = {
		name: '',
		id: -1,
		pronouns: '',
	}
	let persons

	const search = () => {
		persons = null
		searchPersons(person.name).then((res) => {
			if (res.length === 1) {
				person.id = res[0].id
			} else if (!res.length) {
				person.id = 0
			}
			persons = [
				{ label: '*Create New Person*', value: 0, films: '', pronouns: '' },
				...res.map((x) => {
					return {
						label: `${x.name} [${x.films}]`,
						value: x.id,
						films: x.films,
						name: x.name,
						pronouns: x.pronouns,
					}
				}),
			]
		})
	}
	return {
		view({ attrs: { loading, open, onsubmit } }) {
			return m(
				Dialog2,
				{
					open,
					save() {
						if (person.id > -1) {
							onsubmit(person)
							open(false)
						}
					},
					heading: 'Find or Create Person',
					saving: loading,
				},
				[
					m('.flex.flex-col.gap-3', [
						m('.flex.gap-2', [
							m(Input, {
								type: 'text',
								label: 'Name',
								classes: ['grow'],
								value: person.name,
								oninput: (val) => {
									person.name = val
								},
								onenter: search,
							}),
							m(
								'div.pt-6',
								m(
									Button,
									{
										type: 'button',
										size: 'sm',
										onclick: search,
									},
									'Search'
								)
							),
						]),
						persons &&
							m(Select, {
								label: '',
								value: person.id,
								required: true,
								removeItemButton: false,
								choices: persons,
								onchange(val) {
									person.id = parseInt(val)
									if (person.id > 0) {
										let z = persons.find((x) => {
											return x.value === person.id
										})
										person.name = z.name
										person.pronouns = z.pronouns
									}
								},
							}),
						,
					]),
				]
			)
		},
	}
}
