import { Table, Row, Cell, Svg } from 'components'
import tickIcon from 'assets/icons/outline/check.svg'
import crossIcon from 'assets/icons/outline/x.svg'
import { EditVotingCategoryDialog } from './EditVotingCategoryDialog'
import { getAwardsYearSubgroups, getVotingCategoryTypes, updateAwardsYearVotingCategory } from 'api/awardsyears'

export const VotingCategoriesTab = ({ attrs: { id, awardsYear } }) => {
	const cols = [
		{ label: 'Id' },
		{ label: 'Name' },
		{ label: 'ShortName' },
		{ label: 'Subgroup' },
		{ label: 'Type' },
		{ label: 'Separate Person Entries?', align: 'center' },
	]

	let selectedItem = null
	let savingItem
	const editItemOpen = flyd.stream(false)

	const onSaveVotingCategory = (data) => {
		updateAwardsYearVotingCategory(awardsYear.id, data.id, data).then((res) => {
			editItemOpen(false)
		})
	}

	let subgroups
	getAwardsYearSubgroups(id).then((res) => {
		subgroups = res
	})
	let votingCategoryTypes
	getVotingCategoryTypes().then((res) => {
		votingCategoryTypes = res
	})

	return {
		view({ attrs: { id, awardsYear } }) {
			return [
				m(
					Table,
					{
						cols: cols,
					},
					[
						awardsYear.votingCategories
							? awardsYear.votingCategories.map((row) => {
									return m(
										Row,
										{
											clickable: true,
											onclick: (e) => {
												selectedItem = row
												editItemOpen(true)
											},
											bg: 'bg-white',
										},
										[
											m(Cell, row.id),
											m(Cell, row.name),
											m(Cell, row.shortName),
											m(Cell, row.subgroupName),
											m(Cell, row.votingCategoryTypeName),
											m(
												Cell,
												{
													align: 'center',
												},
												m(
													'span',
													m(
														Svg,
														{
															classes: [
																'w-6',
																'sm:mx-auto',
																'inline',
																'sm:block',
																row.isSeparatePersonEntries ? 'text-green-800' : 'text-gray-400',
															],
														},
														row.isSeparatePersonEntries ? tickIcon : crossIcon
													)
												)
											),
										]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
				editItemOpen() &&
					m(EditVotingCategoryDialog, {
						open: editItemOpen,
						onsubmit: onSaveVotingCategory,
						loading: savingItem,
						votingCategory: selectedItem,
						awardsYearId: awardsYear.id,
						subgroups,
						votingCategoryTypes,
					}),
			]
		},
	}
}
