import { Button, Table, Row, Cell, CellBool, Svg, Pagination, LoadingFullScreen } from 'components'
import { getAwardsYearFilms, exportFilms } from 'api/awardsyears'
import chevronDown from 'assets/icons/outline/chevron-double-down.svg'

export const FilmsTab = ({ attrs: { id, awardsYear, search, p } }) => {
	const root = `/awardsyears/${id}/films`
	let searchString = search || ''
	let sortBy = 'title'
	let sortOrder = 'asc'
	let available = false
	let list
	let meta
	let page = p || 1
	let perPage = 25

	const loadData = () => {
		list = null
		var pageUrl = `${window.location.pathname}?tab=Films&p=${page}&search=${searchString}&available=${
			available ? 'true' : 'false'
		}`
		window.history.pushState('', '', pageUrl)
		getAwardsYearFilms(id, {
			search: searchString,
			page,
			pagesize: perPage,
			sortBy,
			sortOrder,
		}).then((res) => {
			list = res.data
			meta = res.meta
		})
	}

	let exportingFilms
	const onExportFilms = () => {
		exportFilms(id, searchString, available).then((res) => {
			exportingFilms = false
		})
	}

	const cols = [{ label: 'Id' }, { label: 'Title' }, { label: 'Type' }, { label: 'Entries' }]

	loadData()

	return {
		view({ attrs: { id } }) {
			return m('.h-auto', [
				m(
					Table,
					{
						cols: cols,
						searchRow: true,
						searchString: searchString,
						onsearchinput: (newSearch) => {
							searchString = newSearch
						},
						onsearch: () => {
							page = 1
							loadData()
						},
						sortOrder,
						sortBy,
						filter: available,
					},
					[
						list
							? list.map((row) => {
									return m(
										Row,
										{
											clickable: true,
											onclick: (e) => {
												if (e.ctrlKey || e.metaKey) {
													window.open(`/films/${row.id}`, '_blank')
												} else {
													m.route.set(`/films/${row.id}`)
												}
											},
											bg: row.isLive ? 'bg-green-50' : 'bg-white',
										},
										[m(Cell, row.id), m(Cell, row.title), m(Cell, row.entryForm), m(Cell, row.entries)]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
				m(
					'div',
					meta &&
						m(Pagination, {
							meta: meta,
							onrefresh: () => {
								loadData()
							},
							onpage: (newPage) => {
								page = newPage
								loadData()
							},
							buttons: [
								m('.flex.justify-end.gap-x-4', [
									m(
										Button,
										{
											size: 'sm',
											classes: ['my-2', 'whitespace-nowrap', 'text-white'],
											type: 'button',
											loading: exportingFilms,
											onclick: () => {
												exportingFilms = true
												onExportFilms()
											},
										},
										[
											m(
												Svg,
												{
													classes: ['w-4', 'mx-auto'],
												},
												[chevronDown]
											),
											'Export Films',
										]
									),
								]),
							],
						})
				),
			])
		},
	}
}
