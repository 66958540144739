import { Card, Heading, Input, Button, Svg } from 'components'
import { getToken, authIsPending } from 'store/auth'
import { getProfile, profileIsPending, profile } from 'store/profile'
import { schema } from 'validators/login'
import { validate, getError, displayErrors } from 'validators/utils'
import { notification } from 'utils/notifications'

import logo from 'assets/logo.svg'

export const Login = () => {
	const formData = {}
	let errors = []
	let serverErrors = []
	let loggingIn = false
	if (profile()) {
		m.route.set('/')
	}
	const loginUser = (data) => {
		loggingIn = true
		const validation = validate(schema, data)
		if (validation) {
			errors = validation
		} else {
			errors = []
			serverErrors = []
			getToken(data.email.trim(), data.password.trim())
				.then(getProfile)
				.then((res) => {
					if (res.roles[0] === 'user') {
						m.route.set('/booking')
					} else if (res.roles[0] === 'clientadmin') {
						m.route.set('/client/dashboard')
					} else {
						m.route.set('/')
					}
					loggingIn = false
					notification.success({ title: t('app', 'loggedIn') })
				})
				.catch(() => {
					serverErrors.push(t('logIn', 'genericError'))
					loggingIn = false
					m.redraw()
				})
		}
	}
	const loading = () => {
		return authIsPending() && profileIsPending()
	}
	return {
		view() {
			return m('.h-full.flex.flex-col.justify-center.items-center.bg-black.h-screen', [
				m(
					Svg,
					{
						classes: ['w-24'],
					},
					logo
				),
				m(
					Card,
					{
						classes: ['w-4/5', 'max-w-md', 'mt-3'],
					},
					[
						m('.p-6', [
							m(Heading, t('logIn', 'title')),
							m(
								'form',
								{
									onsubmit(e) {
										e.preventDefault()
										loginUser(formData)
									},
								},
								[
									m('div.grid.gap-6', [
										m(Input, {
											label: t('logIn', 'email'),
											value: formData.email,
											type: 'text',
											required: true,
											errorMessage: getError(errors, 'email'),
											oninput(val) {
												formData.email = val
											},
										}),
										m(Input, {
											label: t('logIn', 'password'),
											value: formData.password,
											type: 'password',
											required: true,
											errorMessage: getError(errors, 'password'),
											oninput(val) {
												formData.password = val
											},
										}),
									]),
									displayErrors(serverErrors),
									m('.flex.justify-end.items-center.mt-3', [
										m(
											Button,
											{
												variant: 'positive',
												loading: loading() || loggingIn,
											},
											t('logIn', 'action')
										),
									]),
								]
							),
						]),
						m('.p-2.text-center', [
							m(
								'a.text-xs',
								{
									href: '/forgotten-password',
								},
								t('logIn', 'forgotten')
							),
							m('span.text-xs.block.text-center', {
								classes: ['text-xs', 'block', 'text-center'],
							}),
						]),
					]
				),
			])
		},
	}
}
