import { request } from './request'

export const getFilms = (params) => {
	return request({
		url: 'films',
		params: {
			pagesize: 25,
			...params,
		},
		method: 'GET',
	})
}

export const getFilm = (id) => {
	return request({
		url: `films/${id}`,
		method: 'GET',
	})
}

export const createFilm = (model) => {
	return request({
		url: 'films',
		method: 'POST',
		body: model,
	})
}

export const updateFilm = (id, model) => {
	return request({
		url: `films/${id}`,
		method: 'PUT',
		body: model,
	})
}

export const getEntry = (filmId, entryId) => {
	return request({
		url: `films/${filmId}/entries/${entryId}`,
		method: 'GET',
	})
}

export const updateEntry = (filmId, entryId, model) => {
	return request({
		url: `films/${filmId}/entries/${entryId}`,
		method: 'PUT',
		body: model,
	})
}

export const createEntry = (filmId, model) => {
	return request({
		url: `films/${filmId}/entries`,
		method: 'POST',
		body: model,
	})
}

export const uploadFilmAttachment = (filmId, file) => {
	const formData = new FormData()
	formData.append('file', file)
	return request(
		{
			method: 'POST',
			url: `films/${filmId}/upload-attachment`,
			body: formData,
		},
		{
			contentType: false,
		}
	)
}

export const uploadEntryAttachment = (filmId, entryId, file) => {
	const formData = new FormData()
	formData.append('file', file)
	return request(
		{
			method: 'POST',
			url: `films/${filmId}/entries/${entryId}/upload-attachment`,
			body: formData,
		},
		{
			contentType: false,
		}
	)
}

export const downloadEntryAttachment = (entryAttachmentId, name) => {
	return request({
		url: `voting/films/entry-file?entryAttachmentId=${entryAttachmentId}`,
		method: 'GET',
		responseType: 'blob',
		extract(val) {
			return val.response
		},
	})
		.then((res) => {
			const date = new Date()
			var anchor = document.createElement('a')
			anchor.download = name
			anchor.href = window.URL.createObjectURL(res)
			anchor.click()
		})
		.catch((res) => {
			console.log(res)
		})
}
