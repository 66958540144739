export const EditModelForm = () => {
	return {
		view({ children, attrs: { onsubmit } }) {
			return m(
				'.p-4.overflow-y-auto',
				m(
					'form',
					{
						onsubmit: onsubmit,
					},
					[m('.md:grid.md:grid-cols-3.md:gap-6.lg:grid-cols-4.', [children])]
				)
			)
		},
	}
}
