import { request } from './request'

export const getPersons = (params) => {
	return request({
		url: 'persons',
		params: {
			pagesize: 25,
			...params,
		},
		method: 'GET',
	})
}

export const getPerson = (id) => {
	return request({
		url: `persons/${id}`,
		method: 'GET',
	})
}

export const updatePerson = (id, model) => {
	return request({
		url: `persons/${id}`,
		method: 'PUT',
		body: model,
	})
}
export const createPerson = (model) => {
	return request({
		url: `persons`,
		method: 'POST',
		body: model,
	})
}

export const searchPersons = (name) => {
	return request({
		url: `persons/search`,
		method: 'GET',
		params: {
			name,
		},
	})
}
