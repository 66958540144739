import { request } from './request'

export const getAwardsYears = (params) => {
	return request({
		url: 'awardsyears',
		params: {
			pagesize: 25,
			...params,
		},
		method: 'GET',
	})
}
export const getAwardsYearsLookup = () => {
	return request({
		url: 'awardsyears/lookup',
		method: 'GET',
	})
}

export const getAwardsYear = (id) => {
	return request({
		url: `awardsyears/${id}`,
		method: 'GET',
	})
}
export const getCurrentAwardsYear = () => {
	return request({
		url: `awardsyears/current`,
		method: 'GET',
	})
}
export const getCurrentAwardsYearCategories = () => {
	return request({
		url: `awardsyears/current/categories`,
		method: 'GET',
	})
}

export const createAwardsYear = (model) => {
	return request({
		url: 'awardsyears',
		method: 'POST',
		body: model,
	})
}

export const updateAwardsYear = (id, model) => {
	return request({
		url: `awardsyears/${id}`,
		method: 'PUT',
		body: model,
	})
}

export const getAwardsYearUsers = (id, params) => {
	return request({
		url: `awardsyears/${id}/users`,
		method: 'GET',
		params: {
			pagesize: 25,
			...params,
		},
	})
}

export const getAwardsYearUser = (id, awardsYearUserId) => {
	return request({
		url: `awardsyears/${id}/users/${awardsYearUserId}`,
		method: 'GET',
	})
}
export const updateAwardsYearUser = (id, awardsYearUserId, model) => {
	return request({
		url: `awardsyears/${id}/users/${awardsYearUserId}`,
		method: 'PUT',
		body: model,
	})
}

export const getAwardsYearRounds = (id) => {
	return request({
		url: `awardsyears/${id}/rounds`,
		method: 'GET',
	})
}

export const getAwardsYearRound = (id, roundId) => {
	return request({
		url: `awardsyears/${id}/rounds/${roundId}`,
		method: 'GET',
	})
}
export const updateAwardsYearRound = (id, roundId, model) => {
	return request({
		url: `awardsyears/${id}/rounds/${roundId}`,
		method: 'PUT',
		body: model,
	})
}
export const updateRound = (awardsYearId, roundId, model) => {
	return request({
		url: `awardsyears/${awardsYearId}/rounds/${roundId}`,
		method: 'PUT',
		body: model,
	})
}
export const addRound = (awardsYearId, model) => {
	return request({
		url: `awardsyears/${awardsYearId}/rounds`,
		method: 'POST',
		body: model,
	})
}

export const getVotingCategoryTypes = () => {
	return request({
		url: `awardsyears/voting-category-types`,
		method: 'GET',
	})
}

export const getAwardsYearSubgroups = (id) => {
	return request({
		url: `awardsyears/${id}/subgroups`,
		method: 'GET',
	})
}

export const updateAwardsYearVotingCategory = (awardsYearId, votingCategoryId, model) => {
	return request({
		url: `awardsyears/${awardsYearId}/voting-categories/${votingCategoryId}`,
		method: 'PUT',
		body: model,
	})
}

export const updateAwardsYearSubgroup = (awardsYearId, subgroupId, model) => {
	return request({
		url: `awardsyears/${awardsYearId}/subgroups/${subgroupId}`,
		method: 'PUT',
		body: model,
	})
}

export const exportVoters = (awardsYearId, search, available) => {
	return request({
		url: `awardsyears/${awardsYearId}/export-users`,
		params: {
			search,
			available,
		},
		method: 'GET',
		responseType: 'blob',
		extract(val) {
			return val.response
		},
	}).then((res) => {
		const date = new Date()
		var anchor = document.createElement('a')
		anchor.download = `bifa_voters_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}.xlsx`
		anchor.href = window.URL.createObjectURL(res)
		anchor.click()
	})
}

export const importRoundVoters = (awardsYearId, roundId, file) => {
	const formData = new FormData()
	formData.append('file', file)
	return request(
		{
			method: 'POST',
			url: `awardsyears/${awardsYearId}/rounds/${roundId}/import-round-voters`,
			body: formData,
		},
		{
			contentType: false,
		}
	)
}

export const getAwardsYearScreenerRequests = (id) => {
	return request({
		url: `awardsyears/${id}/screener-requests`,
		method: 'GET',
	})
}

export const exportScreenerRequests = (awardsYearId) => {
	return request({
		url: `awardsyears/${awardsYearId}/screener-requests/download`,
		method: 'POST',
		responseType: 'blob',
		extract(val) {
			return val.response
		},
	}).then((res) => {
		const date = new Date()
		var anchor = document.createElement('a')
		anchor.download = `bifa_screener_requests_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}.xlsx`
		anchor.href = window.URL.createObjectURL(res)
		anchor.click()
	})
}

export const getAwardsYearFilms = (id, params) => {
	return request({
		url: `awardsyears/${id}/films`,
		method: 'GET',
		params: {
			pagesize: 25,
			...params,
		},
	})
}
export const exportFilms = (awardsYearId) => {
	return request({
		url: `awardsyears/${awardsYearId}/films/download`,
		method: 'POST',
		responseType: 'blob',
		extract(val) {
			return val.response
		},
	}).then((res) => {
		const date = new Date()
		var anchor = document.createElement('a')
		anchor.download = `bifa_films_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}.xlsx`
		anchor.href = window.URL.createObjectURL(res)
		anchor.click()
	})
}

export const getAwardsYearEntries = (id, params) => {
	return request({
		url: `awardsyears/${id}/entries`,
		method: 'GET',
		params: {
			pagesize: 25,
			...params,
		},
	})
}

export const exportEntries = (awardsYearId, search, categoryId) => {
	return request({
		url: `awardsyears/${awardsYearId}/entries/download`,
		method: 'POST',
		responseType: 'blob',
		params: {
			search: search,
			categoryId: categoryId,
		},
		extract(val) {
			return val.response
		},
	}).then((res) => {
		const date = new Date()
		var anchor = document.createElement('a')
		anchor.download = `bifa_entries_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}.xlsx`
		anchor.href = window.URL.createObjectURL(res)
		anchor.click()
	})
}

export const downloadViewings = (awardsYearId) => {
	return request({
		url: `awardsyears/${awardsYearId}/viewings/download`,
		method: 'POST',
		responseType: 'blob',
		extract(val) {
			return val.response
		},
	}).then((res) => {
		const date = new Date()
		var anchor = document.createElement('a')
		anchor.download = `bifa_viewings_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}.xlsx`
		anchor.href = window.URL.createObjectURL(res)
		anchor.click()
	})
}

export const exportRoundVoters = (awardsYearId, roundId) => {
	return request({
		url: `awardsyears/${awardsYearId}/rounds/${roundId}/export-round-voters`,
		method: 'POST',
		responseType: 'blob',
		extract(val) {
			return val.response
		},
	}).then((res) => {
		const date = new Date()
		var anchor = document.createElement('a')
		anchor.download = `bifa_round_voters_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}.xlsx`
		anchor.href = window.URL.createObjectURL(res)
		anchor.click()
	})
}

export const exportFilmAssignments = (awardsYearId) => {
	return request({
		url: `awardsyears/${awardsYearId}/export-film-assignments`,
		method: 'POST',
		responseType: 'blob',
		extract(val) {
			return val.response
		},
	}).then((res) => {
		const date = new Date()
		var anchor = document.createElement('a')
		anchor.download = `bifa_film_assignments_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}.xlsx`
		anchor.href = window.URL.createObjectURL(res)
		anchor.click()
	})
}

export const importFilmAssignments = (awardsYearId, file) => {
	const formData = new FormData()
	formData.append('file', file)
	return request(
		{
			method: 'POST',
			url: `awardsyears/${awardsYearId}/import-film-assignments`,
			body: formData,
		},
		{
			contentType: false,
		}
	)
}

export const importRoundEntries = (awardsYearId, roundId, file) => {
	const formData = new FormData()
	formData.append('file', file)
	return request(
		{
			method: 'POST',
			url: `awardsyears/${awardsYearId}/rounds/${roundId}/import-round-entries`,
			body: formData,
		},
		{
			contentType: false,
		}
	)
}

export const exportRoundEntries = (awardsYearId, roundId) => {
	return request({
		url: `awardsyears/${awardsYearId}/rounds/${roundId}/export-round-entries`,
		method: 'POST',
		responseType: 'blob',
		extract(val) {
			return val.response
		},
	}).then((res) => {
		const date = new Date()
		var anchor = document.createElement('a')
		anchor.download = `bifa_round_entries_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}.xlsx`
		anchor.href = window.URL.createObjectURL(res)
		anchor.click()
	})
}

export const exportRoundVotes = (awardsYearId, roundId) => {
	return request({
		url: `awardsyears/${awardsYearId}/rounds/${roundId}/export-round-votes`,
		method: 'POST',
		responseType: 'blob',
		extract(val) {
			return val.response
		},
	}).then((res) => {
		const date = new Date()
		var anchor = document.createElement('a')
		anchor.download = `bifa_round_${roundId}_votes_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}.xlsx`
		anchor.href = window.URL.createObjectURL(res)
		anchor.click()
	})
}
