import { Tabs, Table, Cell, Row, Pagination, LoadingFullScreen, Breadcrumb, Svg, Link } from 'components'
import { EmailMessageDialog } from 'pages/Emails/EmailMessageDialog'
import { getEmailMessages, cancelEmailMessage, getEmailMessage, deleteEmailMessage } from 'api/system'
import { DateTime } from 'luxon'
import { debounce } from 'utils/debounce'
import { timeUntilEmailSent } from 'utils'
import cancelIcon from 'assets/icons/outline/x-circle.svg'

export const Emails = ({ attrs: { tab, p, search } }) => {
	let emails
	let meta
	let activeTab
	let searchString = search || ''
	let page = p || 1
	let statusId

	const debouncedSearch = debounce((val) => {
		searchString = val.length > 2 ? val : null
		loadData()
	}, 300)

	const loadData = () => {
		emails = null
		var pageUrl = `${window.location.pathname}?p=${page}&search=${searchString}&tab=${activeTab ? activeTab.name : ''}`
		window.history.pushState('', '', pageUrl)
		getEmailMessages({ page, search: searchString, statusId: statusId, sortorder: 'desc' }).then((data) => {
			emails = data.data
			meta = data.meta
		})
	}

	const tabs = [
		{ name: 'Sent', statusId: 1 },
		{ name: 'Failed', statusId: 2 },
	].map((t, ix) => {
		return {
			id: ix + 1,
			name: t.name,
			statusId: t.statusId,
		}
	})

	const selectTab = (name) => {
		tabs.forEach((t) => {
			t.active = false
			if (t.name === name) {
				t.active = true
				activeTab = t
			}
		})
		statusId = activeTab.statusId
		loadData()
		var pageUrl = `?tab=${activeTab ? activeTab.name : ''}`
		window.history.pushState('', '', pageUrl)
	}

	selectTab(tab || 'Sent')

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Emails',
						},
					],
				}),
				m('.mt-4.px-4.sm:px-6.lg:px-4.overflow-x-auto', [
					m(Tabs, {
						tabs: tabs,
						onTabChange: (tabId) => {
							tabs.forEach((t) => {
								if (t.id === tabId) {
									selectTab(t.name)
								}
							})
						},
					}),
					m('', { style: { maxHeight: 'calc(100vh-5rem)' } }, [
						m('div.shadow.border-b.border-gray-200.sm:rounded-lg', [
							m(
								Table,
								{
									cols: [
										{
											label: 'To',
											search: true,
											searchPlaceholder: 'Search...',
											searchOnChange: (val) => {
												debouncedSearch(val)
											},
										},
										{
											label: 'Subject',
										},
										{
											label: 'Source',
											classes: ['w-20 justify-center'],
										},
										{
											label: 'Created',
											classes: ['w-40 justify-center'],
										},
										{
											label: 'Delay',
											classes: ['w-16 justify-center'],
										},
										{
											label: 'Sent',
											classes: ['w-40 justify-center'],
										},
										{
											label: '',
											search: false,
											classes: ['w-24 justify-center'],
										},
									],
									filters: true,
								},
								[
									emails
										? emails.map((v, ix) => {
												return m(EmailTableRow, { item: v, key: v.id, loadData, tab })
										  })
										: m(LoadingFullScreen),
								]
							),
							m(
								'div',
								meta &&
									m(Pagination, {
										meta: meta,
										onrefresh: () => {
											loadData()
										},
										onpage: (newPage) => {
											page = newPage
											loadData()
										},
									})
							),
						]),
					]),
				]),
			]
		},
	}
}

const EmailTableRow = ({ attrs: { item, classes = [], loadData, tab } }) => {
	let cancelEmailConfirm = false
	const cancelEmail = (emailMessageId) => {
		cancelEmailConfirm = false
		cancelEmailMessage(emailMessageId).then(() => {
			loadData()
		})
	}
	const deleteEmail = (emailMessageId) => {
		cancelEmailConfirm = false
		deleteEmailMessage(emailMessageId).then(() => {
			loadData()
		})
	}
	const newFormat = { ...DateTime.DATETIME_SHORT, year: '2-digit' }
	const sched = flyd.stream('')

	let currentEmail = {}
	const emailMessageOpen = flyd.stream(false)
	const calcScheduled = () => {
		sched(timeUntilEmailSent(item.createdDate, item.delay * 60))
		if (sched() !== 'Os') {
			setTimeout(calcScheduled, 1000)
		} else {
			loadData()
		}
		m.redraw()
	}
	calcScheduled()
	return {
		view() {
			const dateDiff = DateTime.fromISO(item.arrivalDate).diff(DateTime.now().startOf('day'), 'days')

			const rowColor = dateDiff.as('days') < 0 ? 'bg-red-200' : dateDiff.as('days') <= 5 ? 'bg-orange-200' : 'bg-white'

			return m(
				Row,
				{
					class: ['flex', 'flex-shrink-0', 'hover:bg-gray-300', 'cursor-pointer', rowColor, ...classes].join(' '),
					'data-days': dateDiff.as('days'),
					style: {
						height: '40px',
					},
					onclick: () => {
						currentEmail = {}
						getEmailMessage(item.id).then((d) => {
							currentEmail = d
						})
						emailMessageOpen(true)
					},
				},
				[
					m(Cell, item.to),
					m(Cell, { cellClasses: ['tracking-tight'] }, item.subject),
					m(
						Cell,
						{ width: 'w-20', align: 'center', padding: 'py-2', noEllipsis: 'true' },
						item.source
							? m(
									Link,
									{
										stopPropogation: true,
										href: null,
										classes: [
											'bg-gray-200',
											'hover:bg-gray-100',
											'text-black',
											'rounded',
											'relative',
											'py-1',
											'px-2',
											'text-xs',
										],
									},
									item.source
							  )
							: null
					),
					m(Cell, { width: 'w-40', align: 'center' }, DateTime.fromISO(item.createdDate).toLocaleString(newFormat)),
					m(Cell, { width: 'w-16', align: 'center', padding: 'py-3', noEllipsis: 'true' }, item.delay),
					m(
						Cell,
						{ width: 'w-40', align: 'center', padding: 'px-2 py-3' },
						sched() && item.sentDate ? DateTime.fromISO(item.sentDate).toLocaleString(newFormat) : sched()
					),
					m(
						Cell,
						{ width: 'w-24', align: 'right', padding: 'px-1 py-2', noEllipsis: true },
						item.sentDate
							? ''
							: m(
									'button.text-red-700.hover:text-red-900.text-xs.flex.items-center.ml-2.focus:bg-red-800.focus:text-red-200',
									{
										type: 'button',
										onclick(e) {
											if (cancelEmailConfirm) {
												if (tab !== 'Failed') {
													cancelEmail(item.id)
												} else {
													deleteEmail(item.id)
												}
											} else {
												cancelEmailConfirm = true
												setTimeout(() => {
													cancelEmailConfirm = false
													m.redraw()
												}, 3000)
											}
											e.cancelBubble = true
										},
									},
									[
										m(Svg, { classes: ['w-6', 'h-6'] }, cancelIcon),
										cancelEmailConfirm && m('span.ml-1.px-2', 'Confirm'),
									]
							  )
					),
					emailMessageOpen() &&
						m(EmailMessageDialog, {
							open: emailMessageOpen,
							email: currentEmail,
						}),
				]
			)
		},
	}
}
