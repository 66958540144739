import { Breadcrumb, Card, Table, Cell, Button, Pagination, LoadingFullScreen } from 'components'
import { DateTime } from 'luxon'

import { getProfile } from 'api/profile'
import { getCurrentAwardsYear } from 'api/awardsyears'

export const Dashboard = () => {
	let email
	let profile
	getProfile().then((p) => {
		email = p.email
		profile = p
	})
	let awardsYear

	getCurrentAwardsYear().then((p) => {
		awardsYear = p
	})
	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Dashboard',
						},
					],
				}),
				m('.my-4.px-4.grid.grid-cols-3', [
					awardsYear
						? m(
								Card,
								{
									heading: { title: 'Awards' },
									buttons: [
										m(
											Button,
											{
												href: `/awardsyears/${awardsYear.id}`,
											},
											`View ${awardsYear.year} Awards`
										),
									],
								},
								[m('p', `Current awards year: ${awardsYear.year}`)]
						  )
						: m(LoadingFullScreen),
				]),
			]
		},
	}
}
