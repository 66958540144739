import { Dialog2, LoadingFullScreen, Select, Input, TextArea, Checkbox } from 'components'
import { DateTime } from 'luxon'

export const QuestionDialog = ({ attrs: { loading, formId, question } }) => {
	loading = false

	const formData = {
		formId: formId,
		help: question ? question.help : '',
		id: question ? question.id : 0,
		info: question ? question.info : '',
		isRequired: question ? question.isRequired : false,
		isTextArea: question ? question.isTextArea : false,
		isYesNo: question ? question.isYesNo : false,
		maxValue: question ? question.maxValue : null,
		minValue: question ? question.minValue : null,
		name: question ? question.name : '',
		personDetailsLabel: question ? question.personDetailsLabel : null,
		personDetailsLabel2: question ? question.personDetailsLabel2 : null,
		options: question ? question.options : null,
		questionTypeId: question ? question.questionTypeId : 1,
		questionTypeName: question ? question.questionTypeName : '',
		sortOrder: question ? question.sortOrder : 0,
		visibleQuestionId: question ? question.visibleQuestionId : null,
		visibleQuestionValue: question ? question.visibleQuestionValue : null,
	}

	return {
		view({ attrs: { loading, question, open, questionTypes, questions, onsubmit, ondelete } }) {
			return m(
				Dialog2,
				{
					open,
					saving: loading,
					save() {
						loading = true
						onsubmit(formData)
					},
					remove() {
						loading = true
						ondelete(formData)
					},
					heading: 'Edit Question',
					classes: ['md:max-w-2xl'],
					cancelLabel: 'Close',
				},
				[
					question
						? m('.flex.flex-col.gap-3', [
								m(Input, {
									label: 'Name',
									value: formData.name,
									type: 'text',
									required: true,
									oninput(value) {
										formData.name = value
									},
								}),
								m('.grid.grid-cols-3.gap-x-3', [
									m('.col-span-2', [
										m(Select, {
											label: 'Question Type',
											value: formData.questionTypeId,
											required: true,
											choices: questionTypes.map((questionType, ix) => {
												return { label: questionType.name, value: questionType.id }
											}),
											onchange(val) {
												formData.questionTypeId = parseInt(val)
												formData.questionTypeName = questionTypes.find((x) => x.id === formData.questionTypeId).name
											},
										}),
									]),
									m('div.mt-3', [
										m(Checkbox, {
											label: 'Required?',
											checked: formData.isRequired,
											onchange() {
												formData.isRequired = !formData.isRequired
											},
										}),
										// m(Checkbox, {
										// 	label: 'Yes/No?',
										// 	checked: formData.isYesNo,
										// 	onchange() {
										// 		formData.isYesNo = !formData.isYesNo
										// 	},
										// }),
									]),
								]),
								m(TextArea, {
									label: 'Help',
									value: formData.help,
									name: 'help',
									rows: 2,
									oninput: (content) => {
										formData.help = content
									},
								}),
								m(TextArea, {
									value: formData.info,
									label: 'Info',
									name: 'info',
									rows: formData.questionTypeId === 11 ? 8 : 3,
									oninput: (content) => {
										formData.info = content
									},
								}),
								(formData.questionTypeId === 3 ||
									formData.questionTypeId === 5 ||
									formData.questionTypeId === 14 ||
									formData.questionTypeId === 15 ||
									formData.questionTypeId === 17) &&
									m(TextArea, {
										label: 'Options (each option on a new line)',
										value: formData.options ? JSON.parse(formData.options).join('\n') : '',
										name: 'help',
										rows: 3,
										oninput: (content) => {
											formData.options = JSON.stringify(content.split('\n'))
										},
									}),
								(formData.questionTypeId === 12 || formData.questionTypeId === 13) &&
									m('.grid.grid-cols-2.gap-x-3', [
										m(Input, {
											label: 'Details Label 1',
											value: formData.personDetailsLabel,
											type: 'text',
											oninput(value) {
												formData.personDetailsLabel = value
											},
										}),
										m(Input, {
											label: 'Details Label 2',
											value: formData.personDetailsLabel2,
											type: 'text',
											oninput(value) {
												formData.personDetailsLabel2 = value
											},
										}),
									]),
								formData.questionTypeId !== 11 &&
									m('.grid.grid-cols-3.gap-x-3', [
										// formData.questionTypeId === 1
										// 	? m(Checkbox, {
										// 			label: 'TextArea?',
										// 			checked: formData.isTextArea,
										// 			onchange() {
										// 				formData.isTextArea = !formData.isTextArea
										// 			},
										// 	  })
										// 	: m('div'),
										m(Input, {
											label: 'Min Value',
											value: formData.minValue,
											type: 'number',
											min: 0,
											required: false,
											oninput(value) {
												formData.minValue = value
											},
										}),
										m(Input, {
											label: 'Max Value',
											value: formData.maxValue,
											type: 'number',
											min: 0,
											required: false,
											oninput(value) {
												formData.maxValue = value
											},
										}),
									]),
								m('.grid.grid-cols-3.gap-x-3', [
									m('.col-span-3', [
										m(Select, {
											label: 'Visibility from Question',
											value: formData.visibleQuestionId,
											choices: questions.map((question, ix) => {
												return { label: question.sortOrder + ': ' + question.name, value: question.id }
											}),
											onchange(val) {
												formData.visibleQuestionId = parseInt(val)
											},
										}),
									]),
									m('.col-span-3', [
										m(TextArea, {
											label: 'Visibility Value',
											value: formData.visibleQuestionValue,
											name: 'visibleQuestionValue',
											rows: 2,
											oninput(value) {
												formData.visibleQuestionValue = value
											},
										}),
									]),
								]),
						  ])
						: m(LoadingFullScreen),
				]
			)
		},
	}
}
