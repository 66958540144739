import { Table, Row, Cell, CellBool, LoadingFullScreen, TableButtons } from 'components'
import { QuestionDialog } from './QuestionDialog'
import sortBy from 'lodash.sortby'
import editIcon from 'assets/icons/solid/pencil.svg'
import plusIcon from 'assets/icons/solid/plus.svg'
import chevronUp from 'assets/icons/solid/chevron-double-up.svg'
import chevronDown from 'assets/icons/solid/chevron-double-down.svg'
import { updateFormQuestion, updateFormQuestions, deleteFormQuestion } from 'api/forms'

export const QuestionsTab = ({ attrs: { id, form } }) => {
	const cols = [
		{ label: '#', classes: ['w-12'] },
		{ label: 'Id', classes: ['w-16'] },
		{ label: 'Name', classes: ['w-128'] },
		{ label: 'Type' },
		{ label: 'Required?', align: 'center', classes: ['w-24'] },
		{ label: 'Text Area?', align: 'center', classes: ['w-24'] },
		{ label: 'Visibile Q?', align: 'center', classes: ['w-24'] },
		{ label: 'Visibile Q Value' },
		{ label: 'Actions', align: 'center' },
	]

	let selectedQuestion = null
	let savingQuestion
	const editQuestionOpen = flyd.stream(false)

	const updateQuestion = (data) => {
		updateFormQuestion(id, data).then((res) => {
			editQuestionOpen(false)
			let q = form.questions.findIndex((x) => {
				return x.id === data.id
			})
			form.questions[q] = res
		})
	}
	const deleteQuestion = (data) => {
		deleteFormQuestion(id, data).then((res) => {
			editQuestionOpen(false)
			let q = form.questions.findIndex((x) => {
				return x.id === data.id
			})
			form.questions.splice(q, 1)
		})
	}

	const updateQuestions = () => {
		form.questions = sortBy(form.questions, (q) => q.sortOrder)
		updateFormQuestions(form.id, form.questions).then((res) => {
			form.questions = res.questions
		})
	}

	return {
		view({ attrs: { id, form, questionTypes } }) {
			return m('.relative', [
				m(
					Table,
					{
						cols: cols,
						fixed: true,
					},
					[
						form
							? form.questions.map((row) => {
									return m(
										Row,
										{
											clickable: false,
											bg: row.questionTypeName === 'Section heading' ? 'bg-blue-100' : 'bg-white',
										},
										[
											m(Cell, row.sortOrder),
											m(Cell, row.id),
											m(Cell, row.name),
											m(Cell, row.questionTypeName),
											m(CellBool, {
												val: row.isRequired,
											}),
											m(CellBool, {
												val: row.IsTextArea,
											}),
											m(Cell, { align: 'center' }, row.visibleQuestionId),
											m(Cell, row.visibleQuestionValue),
											m(
												Cell,
												{ overflowShow: true },
												m(
													'div',
													m(TableButtons, {
														actions: [
															{
																label: 'Edit Question',
																action: () => {
																	selectedQuestion = row
																	editQuestionOpen(true)
																},
																icon: editIcon,
															},
															{
																label: 'Move Up',
																action: () => {
																	const curSortOrder = row.sortOrder
																	form.questions.forEach((question) => {
																		if (question.sortOrder === curSortOrder) {
																			question.sortOrder--
																		} else if (question.sortOrder === curSortOrder - 1) {
																			question.sortOrder++
																		}
																	})
																	updateQuestions()
																},
																icon: chevronUp,
															},
															{
																label: 'Move Down',
																action: () => {
																	const curSortOrder = row.sortOrder
																	form.questions.forEach((question) => {
																		if (question.sortOrder === curSortOrder) {
																			question.sortOrder++
																		} else if (question.sortOrder === curSortOrder + 1) {
																			question.sortOrder--
																		}
																	})
																	updateQuestions()
																},
																icon: chevronDown,
															},
															{
																label: 'Insert Question',
																action: () => {
																	const curSortOrder = row.sortOrder
																	form.questions.forEach((question) => {
																		if (question.sortOrder > curSortOrder) {
																			question.sortOrder++
																		}
																	})
																	form.questions.push({ questionTypeId: 1, sortOrder: curSortOrder })
																	updateQuestions()
																},
																icon: plusIcon,
															},
														],
													})
												)
											),
										]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
				editQuestionOpen() &&
					m(QuestionDialog, {
						open: editQuestionOpen,
						onsubmit: updateQuestion,
						ondelete: deleteQuestion,
						loading: savingQuestion,
						question: selectedQuestion,
						questionTypes: questionTypes,
						formId: form.id,
						questions: form.questions,
					}),
			])
		},
	}
}
