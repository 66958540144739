import { Tooltip, Svg } from 'components'
import alertIcon from 'assets/icons/outline/exclamation-circle.svg'
import yesIcon from 'assets/icons/solid/check-circle.svg'
import noIcon from 'assets/icons/outline/x-circle.svg'

export const SubmissionCheckStatusBadge = ({ attrs }) => {
	return {
		view({ attrs: { status, size } }) {
			return m(
				'span.inline-flex.items-center.px-3.py-2/1.rounded-full.font-medium.border',
				{
					class: [
						status === 'Ok'
							? 'border-green-300 bg-green-100 text-green-800'
							: status === 'Problem'
							? 'bg-red-100 text-red-800'
							: 'bg-indigo-100 text-indigo-800',
						`text-${size}`,
					].join(' '),
				},
				status
			)
		},
	}
}

export const SupportingMaterialsStatusBadge = ({ attrs }) => {
	return {
		view({ attrs: { status, size } }) {
			return m(
				'span.inline-flex.items-center.px-3.py-2/1.rounded-full.font-medium.border',
				{
					class: [
						status === 'Received'
							? 'border-green-300 bg-green-100 text-green-800'
							: status === 'Outstanding'
							? 'border-red-300 bg-red-100 text-red-800'
							: 'border-indigo-300 bg-indigo-100 text-indigo-800',
						`text-${size}`,
					].join(' '),
				},
				status
			)
		},
	}
}
export const BookingStatusBanner = ({ attrs }) => {
	return {
		view({ attrs: { status, size } }) {
			return m(
				'.items-center.px-3.py-1.font-medium.text-center',
				{
					class: [
						status === 'New Request'
							? 'bg-purple-200 text-purple-800'
							: status === 'Awaiting Delegate'
							? 'bg-teal-100 text-teal-800'
							: status === 'Awaiting Reservation'
							? 'bg-orange-100 text-orange-800'
							: status === 'Cancelled'
							? 'bg-red-100 text-red-800'
							: 'bg-green-100 text-green-800',
						`text-${size}`,
						size === 'xs' ? 'py-0' : size === 'sm' ? 'py-1' : 'py-2',
					].join(' '),
				},
				status
			)
		},
	}
}

export const BookingAlertBadge = ({ attrs }) => {
	return {
		view({ attrs: { alert } }) {
			return m(
				'span.items-center.py-1.font-medium.inline-flex',
				{},
				alert ? [m(Tooltip, { tipContent: alert, icon: alertIcon, colorClass: 'bg-red-600 text-white' })] : ''
			)
		},
	}
}

export const MeetingStatusBadge = ({ attrs }) => {
	return {
		view({ attrs: { status, size, block, margin } }) {
			return m(
				'span.items-center.px-3.py-1.font-medium',
				{
					class: [
						status === 'New Request'
							? 'bg-purple-200 text-purple-800'
							: status === 'Awaiting Quote'
							? 'bg-teal-100 text-teal-800'
							: status === 'Quoted'
							? 'bg-orange-100 text-orange-800'
							: status === 'Provisional'
							? 'bg-blue-100 text-blue-800'
							: status === 'Cancelled'
							? 'bg-red-100 text-red-800'
							: status === 'Duplicate'
							? 'bg-gray-100 text-gray-800'
							: 'bg-green-100 text-green-800',
						block ? 'block' : 'inline-flex rounded-full',
						`text-${size}`,
						size === 'xs' ? 'py-0' : size === 'sm' ? 'py-1' : 'py-2',
						margin || 'ml-3',
					].join(' '),
				},
				status
			)
		},
	}
}

export const InvoiceStatusBadge = ({ attrs }) => {
	return {
		view({ attrs: { status, size } }) {
			return m(
				'span.inline-flex.items-center.px-3.rounded-full.font-medium',
				{
					class: [
						status === 'Draft' ? 'bg-orange-100 text-orange-800' : 'bg-green-100 text-green-800',
						`text-${size}`,
						size === 'xs' ? 'py-0' : size === 'sm' ? 'py-1' : 'py-2',
					].join(' '),
				},
				status
			)
		},
	}
}

export const TrueFalseBadge = () => {
	return {
		view({ attrs: { trueFalse } }) {
			return m(
				Svg,
				{ classes: ['w-8', 'h-8', trueFalse ? 'text-green-800' : 'text-red-800'] },
				trueFalse ? yesIcon : noIcon
			)
		},
	}
}

export const EmailMessageStatusBadge = ({ attrs }) => {
	return {
		view({ attrs: { status, statusId, size } }) {
			return m(
				'span.ml-3.inline-flex.items-center.px-3.py-0.5.rounded-full.font-medium.text-sm',
				{
					class: [
						statusId === 0
							? 'bg-orange-300 text-orange-800'
							: statusId === 1
							? 'bg-green-300 text-green-800'
							: 'bg-red-300 text-red-800',
					],
				},
				status
			)
		},
	}
}
