import { Breadcrumb } from 'components/Breadcrumb'
import { Input } from 'components/Input'
import { Card } from 'components/Card'
import { Button, Select } from 'components'
import { getUser, updateUser, resetPassword } from 'api/users'
import { notification } from 'utils/notifications'
import { getEntryForms } from 'api/entryFees'
import { getExtension } from 'api/submissions'

export const ViewUser = ({ attrs: { id } }) => {
	let user
	let userLoading
	let pwLoading
	let pwaLoading
	const extension = {}

	getUser(id).then((res) => {
		user = res
	})

	let entryForms
	getEntryForms().then((data) => {
		entryForms = data.map((x) => {
			return {
				label: x.name,
				value: x.id,
			}
		})
	})
	return {
		view() {
			return m('.h-full.pb-6', [
				user &&
					m(Breadcrumb, {
						links: [
							{
								title: 'User',
								href: '/users',
							},
							{
								title: user.firstName + ' ' + user.lastName,
							},
						],
					}),
				user &&
					m('div.bg-gray-50.px-4.py-5.sm:p-6.overflow-scroll.h-full', [
						m('.p-4.w-full', [
							m(
								'form',
								{
									onsubmit(e) {
										e.preventDefault()
										userLoading = true
										updateUser(user.id, user)
											.then((res) => {
												notification.success({ title: 'User saved' })
												userLoading = false
											})
											.catch(() => {
												notification.error({ title: 'Error. User not saved' })
												userLoading = false
											})
									},
								},
								[
									m(
										Card,
										{
											buttons: [
												m(
													Button,
													{
														type: 'button',
														loading: pwLoading,
														onclick: () => {
															pwLoading = true
															resetPassword(user.email, false).then((res) => {
																notification.success({ title: 'Reset email sent' })
																pwLoading = false
															})
														},
													},
													'Send Reset Password'
												),
												m(
													Button,
													{
														type: 'button',
														loading: pwaLoading,
														onclick: () => {
															pwaLoading = true
															resetPassword(user.email, true).then((res) => {
																notification.success({ title: 'Reset email sent' })
																pwaLoading = false
															})
														},
													},
													'Send Reset Password to Me'
												),
												m(
													Button,
													{
														type: 'submit',
														loading: userLoading,
													},
													'Save'
												),
											],
										},
										m('.space-y-6.divide-y.divide-gray-200', [
											m('div.grid.grid-cols-6.gap-6', [
												m('div.col-span-6.md:col-span-4', [
													m(Input, {
														label: t('settings', 'profileEmail'),
														value: user.email,
														type: 'email',
														required: true,
														oninput(val) {
															user.email = val
														},
													}),
												]),
												m('div.col-span-6.md:col-span-4', [
													m(Input, {
														label: t('settings', 'profileFirstName'),
														value: user.firstName,
														type: 'text',
														required: true,
														oninput(val) {
															user.firstName = val
														},
													}),
												]),
												m('div.col-span-6.md:col-span-4', [
													m(Input, {
														label: t('settings', 'profileLastName'),
														value: user.lastName,
														type: 'text',
														required: true,
														oninput(val) {
															user.lastName = val
														},
													}),
												]),
												m('div.col-span-6.md:col-span-4', [
													m(Input, {
														label: t('settings', 'profilePhoneNumber'),
														type: 'tel',
														value: user.phoneNumber,
														oninput(val) {
															user.phoneNumber = val
														},
													}),
												]),
											]),
										])
									),
								]
							),
							m('div', [
								m('.flex.items-end.gap-3.mt-3', [
									m(Input, {
										type: 'date',
										value: extension.date,
										label: 'Extension Date',
										oninput: (value) => {
											extension.date = value + 'T00:00:00Z'
										},
									}),
									entryForms &&
										m(Select, {
											label: 'Entry Form',
											value: extension.formId,
											required: true,
											choices: entryForms,
											onchange(val) {
												extension.formId = parseInt(val)
											},
										}),
									m(
										Button,
										{
											type: 'button',
											size: 'sm',
											onclick() {
												if (extension.date) {
													getExtension({
														userId: id,
														extension: extension.date,
														id: -1,
														formId: extension.formId,
													}).then((url) => {
														extension.url = url
													})
												} else {
													alert('Need a date')
												}
											},
										},
										'Generate Extension Link'
									),
								]),
								extension.url && m('p.text-xs.mt-2', 'https://members.bifa.film' + extension.url),
							]),
						]),
					]),
			])
		},
	}
}
